export const INVOICE_DISTRIBUTION_RECEIVERS = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_INVOICE_DISTRIBUTION_RECEIVERS: 'SET_FILTER_INVOICE_DISTRIBUTION_RECEIVERS',
  RESET_FILTER_INVOICE_DISTRIBUTION_RECEIVERS: 'RESET_FILTER_INVOICE_DISTRIBUTION_RECEIVERS',

  GET_INVOICE_DISTRIBUTION_RECEIVERS_REQUEST: 'GET_INVOICE_DISTRIBUTION_RECEIVERS_REQUEST',
  GET_INVOICE_DISTRIBUTION_RECEIVERS_SUCCESS: 'GET_INVOICE_DISTRIBUTION_RECEIVERS_SUCCESS',
  GET_INVOICE_DISTRIBUTION_RECEIVERS_FAIL: 'GET_INVOICE_DISTRIBUTION_RECEIVERS_FAIL',
};


