/* SAMPLE DATA
  const options = [
      { value: 'chocolate', label: 'Chocolate' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ];
    const groupedOptions = [
      {
        label: 'Bank Active',
        options
      },
      {
        label: 'Others',
        options: [
          { value: 'isPrivate', label: 'Private' },
          { value: 'isCash', label: 'Cash' }
        ]
      }
    ];
*/

import './reactSelect.css';

import Select, { components } from 'react-select';

import React from 'react';

const SelectList = props => {
  return (
    <components.MenuList {...props}>
      {props.children}
    </components.MenuList>
  );
};

const stylesDefault = {
  control: base => ({
    ...base,
    minHeight: 25,
    height: '2.64rem',
    margin: '0',
    backgroundColor: '#ffffff',
    border: '1px solid #9e9e9e',
    borderRadius: '.4rem !important',
    boxShadow: 'none',
    textAlign: 'center',
    '&:hover': {
      outline: '0 !important',
      border: '1px solid #03a9f4 !important'
    },
    '&:focus': {
      outline: '0 !important',
      border: '1px solid #03a9f4 !important',
      boxShadow: 'none !important'
    }
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
    padding: '8px 0'
  }),
  valueContainer: base => ({
    ...base,
    padding: '0',
    height: '2.5rem',
    paddingLeft: '5px'
  }),
  placeholder: base => ({
    ...base,
    top: '49%',
  }),
  singleValue: base => ({
    ...base,
    top: '49%'
  }),
  menu: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
    zIndex: '9999',
  }),
  menuList: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
  })
};

export default ({ components, handleSelect, groupOptions, selectedLabel, styles, isSearchable, filterOption, disabled, formatOptionLabel, placeholder, isMulti, menuPosition, menuIsOpen }) => {
  let selected = {};
  if (groupOptions) {
    selected = groupOptions.filter(option => option.value === selectedLabel);
  }
  return (
    <Select
      options={groupOptions}
      components={components ? components : { SelectList }}
      onChange={handleSelect}
      defaultValue={selected || null}
      value={selected}
      styles={styles ? styles : stylesDefault}
      isSearchable={isSearchable}
      isDisabled={disabled}
      filterOption={filterOption}
      formatOptionLabel={formatOptionLabel}
      placeholder={placeholder}
      isOptionDisabled={option => option.disabled}
      isMulti={isMulti}
      menuPlacement="auto"
      menuPosition={menuPosition}
      // autosize={true}
      menuIsOpen={menuIsOpen}
    />
  );
};
