export const ADMIN_REPORT = {
  SET_FILTER_ADMIN_INVOICES: 'SET_FILTER_ADMIN_INVOICES',
  RESET_FILTER_ADMIN_INVOICES: 'RESET_FILTER_ADMIN_INVOICES',

  SET_FILTER_ADMIN_CREDIT_CHECKED: 'SET_FILTER_ADMIN_CREDIT_CHECKED',
  RESET_FILTER_ADMIN_CREDIT_CHECKED: 'RESET_FILTER_ADMIN_CREDIT_CHECKED',

  SET_FILTER_ADMIN_SENT_NO_KID: 'SET_FILTER_ADMIN_SENT_NO_KID',
  RESET_FILTER_ADMIN_SENT_NO_KID: 'RESET_FILTER_ADMIN_SENT_NO_KID',

  SET_FILTER_ADMIN_KIDS: 'SET_FILTER_ADMIN_KIDS',
  RESET_FILTER_ADMIN_KIDS: 'RESET_FILTER_ADMIN_KIDS',

  SET_FILTER_COMPANY_KID_REGISTERED: 'SET_FILTER_COMPANY_KID_REGISTERED',
  RESET_FILTER_COMPANY_KID_REGISTERED: 'RESET_FILTER_COMPANY_KID_REGISTERED',

  SET_FILTER_ADMIN_INVOICES_STATUS: 'SET_FILTER_ADMIN_INVOICES_STATUS',
  RESET_FILTER_ADMIN_INVOICES_STATUS: 'RESET_FILTER_ADMIN_INVOICES_STATUS',

  SET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS: 'SET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS',
  RESET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS: 'RESET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS',

  SET_FILTER_SEND_POST: 'SET_FILTER_SEND_POST',
  RESET_FILTER_SEND_POST: 'RESET_FILTER_SEND_POST',

  SET_FILTER_INVOICE_SEND_POST: 'SET_FILTER_INVOICE_SEND_POST',
  RESET_FILTER_INVOICE_SEND_POST: 'RESET_FILTER_INVOICE_SEND_POST',

  SET_FILTER_INVOICE_SERVICES_REPORT: 'SET_FILTER_INVOICE_SERVICES_REPORT',
  RESET_FILTER_INVOICE_SERVICES_REPORT: 'RESET_FILTER_INVOICE_SERVICES_REPORT',

  SET_FILTER_COMPANY_INVOICE_SERVICES_REPORT: 'SET_FILTER_COMPANY_INVOICE_SERVICES_REPORT',
  RESET_FILTER_COMPANY_INVOICE_SERVICES_REPORT: 'RESET_FILTER_COMPANY_INVOICE_SERVICES_REPORT',

  SET_FILTER_CREDIT_STATISTICS: 'SET_FILTER_CREDIT_STATISTICS',
  RESET_FILTER_CREDIT_STATISTICS: 'RESET_FILTER_CREDIT_STATISTICS',

  SET_FILTER_CREDIT_STATISTICS_LIST: 'SET_FILTER_CREDIT_STATISTICS_LIST',
  RESET_FILTER_CREDIT_STATISTICS_LIST: 'RESET_FILTER_CREDIT_STATISTICS_LIST',

  GET_ADMIN_INVOICES_REPORT: 'GET_ADMIN_INVOICES_REPORT',
  GET_ADMIN_INVOICES_REPORT_SUCCESS: 'GET_ADMIN_INVOICES_REPORT_SUCCESS',
  GET_ADMIN_INVOICES_REPORT_FAIL: 'GET_ADMIN_INVOICES_REPORT_FAIL',

  GET_ADMIN_CREDIT_CHECKED: 'GET_ADMIN_CREDIT_CHECKED',
  GET_ADMIN_CREDIT_CHECKED_SUCCESS: 'GET_ADMIN_CREDIT_CHECKED_SUCCESS',
  GET_ADMIN_CREDIT_CHECKED_FAIL: 'GET_ADMIN_CREDIT_CHECKED_FAIL',

  GET_ADMIN_SENT_NO_KID: 'GET_ADMIN_SENT_NO_KID',
  GET_ADMIN_SENT_NO_KID_SUCCESS: 'GET_ADMIN_SENT_NO_KID_SUCCESS',
  GET_ADMIN_SENT_NO_KID_FAIL: 'GET_ADMIN_SENT_NO_KID_FAIL',

  GET_ADMIN_KIDS_REPORT: 'GET_ADMIN_KIDS_REPORT',
  GET_ADMIN_KIDS_REPORT_SUCCESS: 'GET_ADMIN_KIDS_REPORT_SUCCESS',
  GET_ADMIN_KIDS_REPORT_FAIL: 'GET_ADMIN_KIDS_REPORT_FAIL',

  GET_COMPANY_KID_REGISTERED: 'GET_COMPANY_KID_REGISTERED',
  GET_COMPANY_KID_REGISTERED_SUCCESS: 'GET_COMPANY_KID_REGISTERED_SUCCESS',
  GET_COMPANY_KID_REGISTERED_FAIL: 'GET_COMPANY_KID_REGISTERED_FAIL',

  GET_ADMIN_INVOICES_STATUS: 'GET_ADMIN_INVOICES_STATUS',
  GET_ADMIN_INVOICES_STATUS_SUCCESS: 'GET_ADMIN_INVOICES_STATUS_SUCCESS',
  GET_ADMIN_INVOICES_STATUS_FAIL: 'GET_ADMIN_INVOICES_STATUS_FAIL',

  GET_ADMIN_COMPANIES_INVOICES_STATUS: 'GET_ADMIN_COMPANIES_INVOICES_STATUS',
  GET_ADMIN_COMPANIES_INVOICES_STATUS_SUCCESS: 'GET_ADMIN_COMPANIES_INVOICES_STATUS_SUCCESS',
  GET_ADMIN_COMPANIES_INVOICES_STATUS_FAIL: 'GET_ADMIN_COMPANIES_INVOICES_STATUS_FAIL',

  GET_SEND_POST: 'GET_SEND_POST',
  GET_SEND_POST_SUCCESS: 'GET_SEND_POST_SUCCESS',
  GET_SEND_POST_FAIL: 'GET_SEND_POST_FAIL',

  GET_INVOICE_SEND_POST: 'GET_INVOICE_SEND_POST',
  GET_INVOICE_SEND_POST_SUCCESS: 'GET_INVOICE_SEND_POST_SUCCESS',
  GET_INVOICE_SEND_POST_FAIL: 'GET_INVOICE_SEND_POST_FAIL',

  GET_INVOICE_SERVICES_REPORT: 'GET_INVOICE_SERVICES_REPORT',
  GET_INVOICE_SERVICES_REPORT_SUCCESS: 'GET_INVOICE_SERVICES_REPORT_SUCCESS',
  GET_INVOICE_SERVICES_REPORT_FAIL: 'GET_INVOICE_SERVICES_REPORT_FAIL',

  GET_COMPANY_INVOICE_SERVICES_REPORT: 'GET_COMPANY_INVOICE_SERVICES_REPORT',
  GET_COMPANY_INVOICE_SERVICES_REPORT_SUCCESS: 'GET_COMPANY_INVOICE_SERVICES_REPORT_SUCCESS',
  GET_COMPANY_INVOICE_SERVICES_REPORT_FAIL: 'GET_COMPANY_INVOICE_SERVICES_REPORT_FAIL',

  GET_CREDIT_STATISTICS: 'GET_CREDIT_STATISTICS',
  GET_CREDIT_STATISTICS_SUCCESS: 'GET_CREDIT_STATISTICS_SUCCESS',
  GET_CREDIT_STATISTICS_FAIL: 'GET_CREDIT_STATISTICS_FAIL',

  GET_CREDIT_STATISTICS_LIST: 'GET_CREDIT_STATISTICS_LIST',
  GET_CREDIT_STATISTICS_LIST_SUCCESS: 'GET_CREDIT_STATISTICS_LIST_SUCCESS',
  GET_CREDIT_STATISTICS_LIST_FAIL: 'GET_CREDIT_STATISTICS_LIST_FAIL',
};


