import * as types from '../actionTypes';

export const getAccountCodes = payload => {
  return {
    type: types.ACCOUNTANTS.GET_ACCOUNT_CODES,
    payload
  };
};
export const getAccountCodesSuccess = payload => ({
  type: types.ACCOUNTANTS.GET_ACCOUNT_CODES_SUCCESS,
  payload
});
export const getAccountCodesFail = payload => ({
  type: types.ACCOUNTANTS.GET_ACCOUNT_CODES_FAIL,
  payload
});