import * as types from '../actionTypes';

export const setFilterCustomerContacts = payload => ({
  type: types.CUSTOMER_CONTACT.SET_FILTER_CUSTOMER_CONTACTS,
  payload
});

export const resetFilterCustomerContacts = payload => ({
  type: types.CUSTOMER_CONTACT.RESET_FILTER_CUSTOMER_CONTACTS,
  payload
});

// LIST
export const getCustomerContacts = payload => {
  return {
    type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACTS,
    payload
  };
};
export const getCustomerContactsSuccess = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACTS_SUCCESS,
  payload
});
export const getCustomerContactsFail = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACTS_FAIL,
  payload
});

// OPTIONS
export const getCustomerContactOptions = payload => {
  return {
    type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_OPTIONS,
    payload
  };
};
export const getCustomerContactOptionsSuccess = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_OPTIONS_SUCCESS,
  payload
});
export const getCustomerContactOptionsFail = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_OPTIONS_FAIL,
  payload
});

// GET ITEM
export const getCustomerContact = payload => {
  return {
    type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT,
    payload
  };
};
export const getCustomerContactSuccess = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_SUCCESS,
  payload
});
export const getCustomerContactFail = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_FAIL,
  payload
});

// GET CONTACT PERSON
export const getContactPersonOptions = payload => {
  return {
    type: types.CUSTOMER_CONTACT.GET_CONTACT_PERSON_OPTIONS,
    payload
  };
};
export const getContactPersonOptionsSuccess = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CONTACT_PERSON_OPTIONS_SUCCESS,
  payload
});
export const getContactPersonOptionsFail = payload => ({
  type: types.CUSTOMER_CONTACT.GET_CONTACT_PERSON_OPTIONS_FAIL,
  payload
});