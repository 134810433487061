import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesUsersServices from '../../../services/companies-users.services';
import orytonServices from '../../../services/oryton.services';

export default function* getOrytonUsersSaga() {
  yield takeEvery(types.COMPANIES_USERS.GET_ORYTON_USERS_REQUEST, getOrytonUsers);
}

export function* getOrytonUsers(action) {
  try {
    const response = yield call(orytonServices.listUsers, action.payload);
    if (response.isSuccess) {
      yield put(actions.getOrytonUsersSuccess(response.data));
    } else {
      yield put(actions.getOrytonUsersFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getOrytonUsersFail(error));
  }
}
