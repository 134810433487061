import * as types from '../actionTypes';

const resetFilter = {
  companies_id: 0,
  keyword: '',
  organization_number: '',
  customer_name: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  customerOrders: {
    total: 0,
    rows: []
  },  
};

const customerOrders = (state = initialState, action) => {
  switch (action.type) {
    case types.CUSTOMER_ORDERS.SET_FILTER_CUSTOMER_ORDERS:
      return {
        ...state,
        filter: action.payload
      };

    case types.CUSTOMER_ORDERS.RESET_FILTER_CUSTOMER_ORDERS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.CUSTOMER_ORDERS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
  
    // LIST ORDERS
    case types.CUSTOMER_ORDERS.GET_CUSTOMER_ORDERS:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER_ORDERS.GET_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerOrders: action.payload
      };
    case types.CUSTOMER_ORDERS.GET_CUSTOMER_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
      };
    
    //DEFAULT
    default:
      return state;
  }
};
export default customerOrders;
