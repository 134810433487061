import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import InvoiceNoteServices from '../../../services/invoiceNote.services';

export default function* fetchInvoiceNoteSaga() {
  yield takeEvery(types.INVOICE_NOTE.FETCH_INVOICE_NOTE_DATA_REQUEST, fetchInvoiceNote);
}

export function* fetchInvoiceNote(action) {
  try {
    const response = yield call(InvoiceNoteServices.list, action.payload);
    if (response.success) {
      yield put(actions.fetchInvoiceNoteSuccess(response.data));
    } else {
      yield put(actions.fetchInvoiceNoteFail(response?.message));
    }
  } catch (error) {
    yield put(actions.fetchInvoiceNoteFail(error));
  }
}
