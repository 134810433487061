export const INVOICE_DRAFTS = {
  SET_FILTER_INVOICES_DRAFT: 'SET_FILTER_INVOICES_DRAFT',
  RESET_FILTER_INVOICES_DRAFT: 'RESET_FILTER_INVOICES_DRAFT',

  SET_LOADING: 'SET_LOADING',

  GET_INVOICE_DRAFTS_REQUEST: 'GET_INVOICE_DRAFTS_REQUEST',
  GET_INVOICE_DRAFTS_SUCCESS: 'GET_INVOICE_DRAFTS_SUCCESS',
  GET_INVOICE_DRAFTS_FAIL: 'GET_INVOICE_DRAFTS_FAIL',

  DELETE_INVOICES_DRAFT_REQUEST: 'DELETE_INVOICES_DRAFT_REQUEST',
  DELETE_INVOICES_DRAFT_REQUEST_SUCCESS: 'DELETE_INVOICES_DRAFT_REQUEST_SUCCESS',
  DELETE_INVOICES_DRAFT_REQUEST_FAIL: 'DELETE_INVOICES_DRAFT_REQUEST_FAIL',
};


