import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import projectsServices from '../../../services/projects.services';

export default function* getLastProjectNoSaga() {
  yield takeEvery(types.PROJECTS.GET_LAST_PROJECTS_NO, getLastProjectNo);
}

export function* getLastProjectNo(action) {
  try {
    const response = yield call(projectsServices.getLastProjectNo, action.payload);
    if (response.success) {
      yield put(actions.getLastProjectNoSuccess(response.data));
    } else {
      yield put(actions.getLastProjectNoFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getLastProjectNoFail(error));
  }
}
