import 'react-datepicker/dist/react-datepicker.css';

import DatePicker, { registerLocale } from 'react-datepicker';
import { enUS, nb, vi } from 'date-fns/locale';

import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment-timezone';

registerLocale('nb', nb);
registerLocale('enUS', enUS);
registerLocale('vi', vi);
export default class renderDatePicker extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
      value: PropTypes.any,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
    inputValueFormat: PropTypes.string,
    selected: PropTypes.any
  };

  static defaultProps = {
    inputValueFormat: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: this.props.selected ? this.props.selected : null,
    };
    this._locale = localStorage.getItem('lang') === 'no' ? 'nb' : (localStorage.getItem('lang') === 'vn' ? 'vi' : 'enUS')
    this.datePickerRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({
        selectedDate: this.props.selected
      });
    }
  }

  handleChange = (date) => {
    this.setState({
      selectedDate: date,
    });
    this.props.input.onChange(date);
  }

  handleChangeRaw = e => {
    if (e.target.value) {
      e.target.value = e.target.value.replace(/[^0-9-./]/g, '').replace(/[^0-9.]/g, '.');
      if (e.target.value.length === 4) {
        if (e.target.value.indexOf('.') === -1) {
          e.target.value = e.target.value.substring(0, 2) + '.' + e.target.value.substring(2, 4);
        }
      }

      if (e.target.value.length > 10) {
        e.target.value = e.target.value.slice(0, 10);
      }
    }
  }

  handleKeyDown = (e) => {
    const { minDate, maxDate } = this.props;

    if (e.key === 'Tab') {
      let value = e.target.value;
      if ((value?.length === 4) || (value?.length === 5 && value.indexOf('.') === 2)) {
        value = value.replace(/[^0-9]/g, '');
        const new_date = moment().year() + '-' + value.substring(2, 4) + '-' + value.substring(0, 2);
        const min_date = minDate ? moment(minDate).format('YYYY-MM-DD') : null;
        const max_date = maxDate ? moment(maxDate).format('YYYY-MM-DD') : null;

        if (min_date && moment(new_date).isSameOrAfter(min_date)) {
          e.target.value = moment(new_date).format('DD.MM.YYYY');
          this.handleChange(new Date(new_date))
          this.datePickerRef.current.setOpen(false);
        }

        if (max_date && moment(new_date).isSameOrBefore(max_date)) {
          e.target.value = moment(new_date).format('DD.MM.YYYY');
          this.handleChange(new Date(new_date))
          this.datePickerRef.current.setOpen(false);
        }

        if (!min_date && !max_date) {
          e.target.value = moment(new_date).format('DD.MM.YYYY');
          this.handleChange(new Date(new_date))
          this.datePickerRef.current.setOpen(false);
        }
      }
    }
  }

  render() {
    const {
      meta: { touched, error },
      input,
      placeholder,
      ...rest
    } = this.props;

    return (
      <div>
        <DatePicker
          ref={this.datePickerRef}
          locale={this._locale}
          {...rest}
          name={input.name}
          placeholderText={placeholder}
          selected={this.state.selectedDate}
          onChange={this.handleChange}
          onChangeRaw={this.handleChangeRaw}
          onKeyDown={this.handleKeyDown}
        />
        {touched && error && <span className="red-text">{error}</span>}
      </div>
    );
  }
}
