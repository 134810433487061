import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ocrTransactionsServices from '../../../services/ocr-transactions.services';

export default function* getOcrTransactionSaga() {
  yield takeEvery(types.OCR_TRANSACTIONS.GET_OCR_TRANSACTION, getOcrTransaction);
}

export function* getOcrTransaction(action) {
  try {
    const response = yield call(ocrTransactionsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getOcrTransactionSuccess(response.data));
    } else {
      yield put(actions.getOcrTransactionFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getOcrTransactionFail(error));
  }
}
