import * as types from '../actionTypes';

const resetFilter = {
  keyword: '',
  date_option: 2,
  date_from: '',
  date_to: '',
  status: '',  
  sortBy: 'transaction_date',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  ocrTransactions: {
    total: 0,
    rows: []
  },
  ocrTransactionsNotMatch: {
    total: 0,
    rows: []
  },
  ocrTransaction: {}
};

const ocrTransactions = (state = initialState, action) => {
  switch (action.type) {
    case types.OCR_TRANSACTIONS.SET_FILTER_OCR_TRANSACTIONS:
      return {
        ...state,
        filter: action.payload
      };

    case types.OCR_TRANSACTIONS.RESET_FILTER_OCR_TRANSACTIONS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS:
      return {
        ...state,
        loading: true
      };
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        ocrTransactions: action.payload
      };
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false
      };

    // LIST NOT MATCH
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_NOT_MATCH:
      return {
        ...state,
        loading: true
      };
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_NOT_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        ocrTransactionsNotMatch: action.payload
      };
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_NOT_MATCH_FAIL:
      return {
        ...state,
        loading: false
      };

    // GET
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTION:
      return {
        ...state,
        loading: true
      };
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        ocrTransaction: action.payload
      };
    case types.OCR_TRANSACTIONS.GET_OCR_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default ocrTransactions;
