import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ReportServices from '../../../services/report.services'

export default function* getProductSalesReportSaga() {
  yield takeEvery(types.REPORT.GET_PRODUCT_SALES_REPORT_REQUEST, getProductSalesReport);
}

export function* getProductSalesReport(action) {
  try {
    const response = yield call(ReportServices.productSales, action.payload);
    if (response.success) {
      yield put(actions.getProductSalesReportSuccess(response.data));
    } else {
      yield put(actions.getProductSalesReportFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getProductSalesReportFail(error));
  }
}
