import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  invoiceReport: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.invoiceReport}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  creditChecked: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.creditChecked}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  sentNoKid: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.sentNoKid}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  kidsReport: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.kidsReport}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  companyKidRegistered: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.companyKidRegistered}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  companiesInvoicesStatus: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.companiesInvoicesStatus}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  companiesInvoicesStatusList: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.companiesInvoicesStatusList}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  sendPost: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.sendPost}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  invoiceSendPosts: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.invoiceSendPosts}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  invoiceServicesReport: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.invoiceServiceReport}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  companyInvoiceServicesReport: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.companyInvoiceServicesReport}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  creditStatistics: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.creditStatistics}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  creditStatisticsList: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.adminReport.creditStatisticsList.replace(':companies_id', params.companies_id)}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
