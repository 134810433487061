import { fork } from 'redux-saga/effects';
import getAccountCodesSaga from './accountants/get-account-codes.sagas';
import getAdminCompaniesInvoicesStatusSaga from './admin-report/getAdminCompaniesInvoicesStatus.sagas';
import getAdminCreditCheckedSaga from './admin-report/getAdminCreditChecked.sagas';
import getAdminInvoicesReportSaga from './admin-report/getAdminInvoicesReport.sagas';
import getAdminInvoicesStatusSaga from './admin-report/getAdminInvoicesStatus.sagas';
import getAdminKidsReportSaga from './admin-report/getAdminKidsReport.sagas';
import getAdminSentNoKidsSaga from './admin-report/getAdminSentNoKids.sagas';
import getCompanyInvoiceServicesReportSaga from './admin-report/getCompanyInvoiceServicesReport.sagas';
import getCompanyKidRegisteredSaga from './admin-report/getCompanyKidRegistered.sagas';
import getCreditStatisticsSaga from './admin-report/getCreditStatistics.sagas';
import getCreditStatisticsListSaga from './admin-report/getCreditStatisticsList.sagas';
import getInvoiceSendPostsReportSaga from './admin-report/getInvoiceSendPostReport.sagas';
import getInvoiceServicesReportSaga from './admin-report/getInvoiceServicesReport.sagas';
import getSendPostReportSaga from './admin-report/getSendPostReport.sagas';
import avtalegiroStatisticsSaga from './avtalegiro/avtalegiro-statistics.sagas';
import checkInvoiceSentSaga from './avtalegiro/check-invoice-sent.sagas';
import getBankAccountsSaga from './bankAccount/bankAccount.sagas';
import getBankAccountCompanySaga from './bankAccount/bankAccountCompany.sagas';
import getCompaniesInvoiceServicesExceptSaga from './companies-invoice-services-except/getCompaniesInvoiceServicesExcept.sagas';
import getCompaniesPaymentPackageSaga from './companies-payment-packages/get-companies-payment-package.sagas';
import getPaymentCreditCheckSaga from './companies-payment-packages/get-payment-credit-check.sagas';
import getCompaniesServicesSaga from './companies-services/get-companies-services.sagas';
import getKidOcrSaga from './companies-services/get-kid-ocr.sagas';
import getKidOcrsSaga from './companies-services/get-kid-ocrs.sagas';
import getKraviaServicesSaga from './companies-services/get-kravia-services.sagas';
import getCompaniesServiceSaga from './companies-services/getCompaniesService.sagas';
import getPaymentsSaga from './companies-services/getPayments.sagas';
import getCompaniesSuspendInvoicesSagas from './companies-suspend/get-companies-suspend-invoices.sagas';
import getCompaniesUsersSaga from './companies-users/getCompaniesUsers.sagas';
import getOrytonUsersSaga from './companies-users/getOrytonUsers.sagas';
import getCounterSaga from './company/geCounter.sagas';
import getOwnerSaga from './company/get-owner.sagas';
import getCompaniesSaga from './company/getCompanies.sagas';
import creditCheckListRequestSaga from './credit-checking/credit-check-list.sagas';
import creditCheckingRequestSaga from './credit-checking/credit-checking.sagas';
import getContactPersonOptionsSaga from './customer-contact/get-contact-person-options.sagas';
import getCustomerContactOptionsSaga from './customer-contact/get-customer-contact-options.sagas';
import getCustomerContactSaga from './customer-contact/get-customer-contact.sagas';
import getCustomerContactsSaga from './customer-contact/get-customer-contacts.sagas';
import getCustomerDiscountsSaga from './customer-discounts/customer-discounts.sagas';
import getCustomerInvoicesSaga from './customer-invoices/customer-invoices.sagas';
import getCustomerNotesSaga from './customer-notes/customer-notes.sagas';
import getCustomerOrdersSaga from './customer-orders/customer-orders.sagas';
import getCustomerOverviewInvoicesSaga from './customer-overview/get-customer-overview-invoices.sagas';
import fetchListCustomerSaga from './customer/customer.sagas';
import addNewCustomerSaga from './customer/customerAdd.sagas';
import fetchListCustomerAutocomplete from './customer/customerAutocomplete.sagas';
import getCustomerDetailSaga from './customer/customerDetail.sagas';
import updateCustomerSaga from './customer/customerUpdate.sagas';
import getDataBrregSaga from './customer/dataBrreg.sagas';
import getImportLogs from './import-log/import-log.sagas';
import getInvoiceDistributionReceiversSaga from './invoice-distribution-receivers/get-invoice-distribution-receivers.sagas';
import getDistributionMethodSaga from './invoice-distribution-run/get-distribution-method.sagas';
import getInvoiceDistributionRunSaga from './invoice-distribution-run/get-invoice-distribution-runs.sagas';
import getDistributionSendsSaga from './invoice-distribution/get-distribution-sends.sagas';
import getInvoiceDistributionsSaga from './invoice-distribution/get-invoice-distributions.sagas';
import getInvoiceDraftsSagas from './invoice-drafts/getInvoiceDrafts.sagas';
import getInvoiceTypeSettingSaga from './invoice-type-settings/getInvoiceTypeSetting.sagas';
import fetchInvoiceBroadcastsSaga from './invoiceBroadcasts/invoiceBroadcasts.sagas';
import fetchInvoiceHistorySaga from './invoiceHistory/invoiceHistory.sagas';
import fetchInvoiceNoteSaga from './invoiceNote/invoiceNote.sagas';
import getInvoiceSettingSaga from './invoiceSettings/getInvoiceSetting.sagas';
import createInvoiceRequestSagas from './invoices/createInvoice.sagas';
import getInvoiceOptionsSaga from './invoices/invoice-options.sagas';
import getInvoiceDetailSaga from './invoices/invoiceDetail.sagas';
import getInvoicePreviewSagas from './invoices/invoicePreview.sagas';
import fetchListInvoicesSaga from './invoices/invoicesList.sagas';
import sendInvoiceSagas from './invoices/sendInvoice.sagas';
import getKraviaInvoiceSaga from './kravia-invoices/get-kravia-invoice.sagas';
import getKraviaInvoicesSaga from './kravia-invoices/get-kravia-invoices.sagas';
import getKraviaTransactionSaga from './kravia-transactions/get-kravia-transaction.sagas';
import getKraviaTransactionsNotMatchSaga from './kravia-transactions/get-kravia-transactions-not-match.sagas';
import getKraviaTransactionsSaga from './kravia-transactions/get-kravia-transactions.sagas';
import getOcrTransactionSaga from './ocr-transactions/get-ocr-transaction.sagas';
import getOcrTransactionsNotMatchSaga from './ocr-transactions/get-ocr-transactions-not-match.sagas';
import getOcrTransactionsSaga from './ocr-transactions/get-ocr-transactions.sagas';
import getOffersSaga from './offers/getOffers.sagas';
import getOptionsSaga from './options/options.sagas';
import getOrdersSaga from './orders/getOrders.sagas';
import getLastProductNoSaga from './products/last-product-no.sagas';
import fetchListProductDiscountsSaga from './products/productDiscounts.sagas';
import fetchListProductSalesSaga from './products/productSalesList.sagas';
import addNewProductsSaga from './products/productsAdd.sagas';
import deleteProductsSaga from './products/productsDelete.sagas';
import getProductsDetailSaga from './products/productsDetail.sagas';
import fetchListProductsSaga from './products/productsList.sagas';
import updateProductsSaga from './products/productsUpdate.sagas';
import getLastProjectNoSaga from './projects/get-last-project-no.sagas';
import getListProjectsSaga from './projects/getListProjects.sagas';
import getReminderSettingSaga from './reminder-settings/getReminderSetting.sagas';
import fetchReminderInvoiceSaga from './reminderInvoice/reminderInvoice.sagas';
import getChartDataSaga from './repeating-invoices/getChartData.sagas';
import getRepeatingInvoiceSaga from './repeating-invoices/repeating-invoice.sagas';
import getRepeatingInvoicesSaga from './repeating-invoices/repeating-invoices.sagas';
import getAccountReceivableReportSaga from './report/getAccountReceivableReport.sagas';
import getCustomerInvoiceReportSaga from './report/getCustomerInvoiceReport.sagas';
import getDiscountReportSaga from './report/getDiscountReport.sagas';
import getInvoiceReportSaga from './report/getInvoiceReport.sagas';
import getProductSalesReportSaga from './report/getProductSalesReport.sagas';
import getSalesMansSaga from './sales-man/sales-mans.sagas';
import getSalesmanSettingsSaga from './salesman-settings/get-salesman-setting.sagas';
import getSubscriptionSettingsSaga from './subscription-settings/get-subscription-setting.sagas';
import getUserInvoiceServicesSaga from './user-invoice-services/getUserInvoiceServices.sagas';
import getUserPermissionSaga from './users-permission/get-user-permission.sagas';
import getVatCodeSettingsSaga from './vat-code-settings/get-vat-code-setting.sagas';
import getVatSettingsSaga from './vat-settings/get-vat-setting.sagas';
import avtalegiroStatisticsListSaga from './avtalegiro/avtalegiro-statistics-list.sagas';
import getContractsSaga from './contracts/get-contracts.sagas'

//v2
import getCreditChecksSaga from './v2/credit-check/get-credit-checks.sagas';
import creditCheckSearchSaga from './v2/credit-check/credit-check-search.sagas';

function* rootSagas() {
  // companies
  yield fork(getCompaniesSaga);
  yield fork(getCounterSaga);
  // options
  yield fork(getOptionsSaga);
  // autocomplete
  yield fork(fetchListCustomerAutocomplete);
  // data brreg
  yield fork(getDataBrregSaga);
  // customer
  yield fork(fetchListCustomerSaga);
  yield fork(getCustomerDetailSaga);
  yield fork(addNewCustomerSaga);
  yield fork(updateCustomerSaga);
  // product
  yield fork(fetchListProductsSaga);
  yield fork(getProductsDetailSaga);
  yield fork(addNewProductsSaga);
  yield fork(updateProductsSaga);
  yield fork(deleteProductsSaga);
  yield fork(fetchListProductSalesSaga);
  yield fork(getLastProductNoSaga);
  yield fork(fetchListProductDiscountsSaga);
  // invoices
  yield fork(getInvoicePreviewSagas);
  yield fork(createInvoiceRequestSagas);
  yield fork(fetchListInvoicesSaga);
  yield fork(getInvoiceDetailSaga);
  yield fork(sendInvoiceSagas);
  yield fork(getChartDataSaga);
  yield fork(getInvoiceOptionsSaga);
  // invoice note
  yield fork(fetchInvoiceNoteSaga);
  // invoice history
  yield fork(fetchInvoiceHistorySaga);
  yield fork(fetchInvoiceBroadcastsSaga);
  // bank account
  yield fork(getBankAccountsSaga);
  yield fork(getBankAccountCompanySaga);
  // reminder Invoice
  yield fork(fetchReminderInvoiceSaga);
  // invoice settings
  yield fork(getInvoiceSettingSaga);
  // report
  yield fork(getInvoiceReportSaga);
  yield fork(getProductSalesReportSaga);
  yield fork(getCustomerInvoiceReportSaga);
  yield fork(getDiscountReportSaga);
  yield fork(getAccountReceivableReportSaga);
  // companies services
  yield fork(getCompaniesServiceSaga);
  yield fork(getPaymentsSaga);
  yield fork(getKidOcrSaga);
  yield fork(getKidOcrsSaga);
  yield fork(getKraviaServicesSaga);
  // repeating-invoices
  yield fork(getRepeatingInvoicesSaga);
  yield fork(getRepeatingInvoiceSaga);
  // credit checking
  yield fork(creditCheckingRequestSaga);
  yield fork(creditCheckListRequestSaga);
  // invoice drafts
  yield fork(getInvoiceDraftsSagas);
  // invoice type settings
  yield fork(getInvoiceTypeSettingSaga);
  // projects
  yield fork(getListProjectsSaga);
  yield fork(getLastProjectNoSaga);
  // companies usersOptions
  yield fork(getCompaniesUsersSaga);
  yield fork(getOrytonUsersSaga);
  // users permissions
  yield fork(getUserPermissionSaga);
  // customer contact
  yield fork(getCustomerContactsSaga);
  yield fork(getCustomerContactSaga);
  yield fork(getCustomerContactOptionsSaga);
  yield fork(getContactPersonOptionsSaga);
  // reminder settings
  yield fork(getReminderSettingSaga);
  // invoice distribution
  yield fork(getInvoiceDistributionsSaga);
  // invoice distribution run
  yield fork(getInvoiceDistributionRunSaga);
  yield fork(getDistributionMethodSaga);
  yield fork(getDistributionSendsSaga);
  // invoice distribution receiver
  yield fork(getInvoiceDistributionReceiversSaga);
  //salesman
  yield fork(getSalesMansSaga);
  // companies payment packages
  yield fork(getCompaniesPaymentPackageSaga);
  yield fork(getPaymentCreditCheckSaga);
  // ocr transactions
  yield fork(getOcrTransactionsSaga);
  yield fork(getOcrTransactionsNotMatchSaga);
  yield fork(getOcrTransactionSaga);
  //account codes
  yield fork(getAccountCodesSaga);
  // kravia transactions
  yield fork(getKraviaTransactionsSaga);
  yield fork(getKraviaTransactionSaga);
  yield fork(getKraviaTransactionsNotMatchSaga)
  // kravia invoices
  yield fork(getKraviaInvoicesSaga);
  yield fork(getKraviaInvoiceSaga);
  // owner
  yield fork(getOwnerSaga);
  // salesman settings
  yield fork(getSalesmanSettingsSaga);

  //v2
  //companies-services
  yield fork(getCompaniesServicesSaga);

  //customers  
  yield fork(getCustomerOrdersSaga);
  yield fork(getCustomerInvoicesSaga);
  yield fork(getCustomerDiscountsSaga);
  yield fork(getCustomerNotesSaga);

  //vat-settings
  yield fork(getVatSettingsSaga);

  //vat-code-settings
  yield fork(getVatCodeSettingsSaga);

  //orders
  yield fork(getOrdersSaga);

  //offers
  yield fork(getOffersSaga);

  //imprort log
  yield fork(getImportLogs);

  //admin reports
  yield fork(getAdminInvoicesReportSaga);
  yield fork(getAdminCreditCheckedSaga);
  yield fork(getAdminSentNoKidsSaga);
  yield fork(getAdminKidsReportSaga);
  yield fork(getCompanyKidRegisteredSaga);
  yield fork(getAdminCompaniesInvoicesStatusSaga);
  yield fork(getSendPostReportSaga);
  yield fork(getInvoiceSendPostsReportSaga);
  yield fork(getInvoiceServicesReportSaga);
  yield fork(getCompanyInvoiceServicesReportSaga);
  yield fork(getCreditStatisticsSaga);
  yield fork(getCreditStatisticsListSaga);

  //subscription settings
  yield fork(getSubscriptionSettingsSaga);

  // companies suspend invoices
  yield fork(getCompaniesSuspendInvoicesSagas);
  yield fork(getAdminInvoicesStatusSaga);

  //user invoice services
  yield fork(getUserInvoiceServicesSaga);

  //customer overview invoices
  yield fork(getCustomerOverviewInvoicesSaga);

  //companies invoice services except
  yield fork(getCompaniesInvoiceServicesExceptSaga);

  //avtalegiro
  yield fork(checkInvoiceSentSaga);
  yield fork(avtalegiroStatisticsSaga);
  yield fork(avtalegiroStatisticsListSaga);

  //contracts
  yield fork(getContractsSaga);

  //v2
  yield fork(getCreditChecksSaga);
  yield fork(creditCheckSearchSaga);
}

export default rootSagas;
