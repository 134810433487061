import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceServices from '../../../services/invoices.services';

export default function* sendInvoiceSagas() {
  yield takeEvery(types.INVOICES.SEND_INVOICE_REQUEST, sendInvoice);
}

export function* sendInvoice(action) {
  try {
    const response = yield call(invoiceServices.sendInvoice, action.payload);
    if (response) {
      yield put(actions.sendInvoiceRequestSuccess(response));
    } else {
      yield put(actions.sendInvoiceRequestFail(response?.message));
    }
  } catch (error) {
    yield put(actions.sendInvoiceRequestFail(error));
  }
}