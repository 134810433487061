import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesSuspendServices from '../../../services/companies-suspend.services';

export default function* getCompaniesSuspendInvoicesSagas() {
  yield takeEvery(types.COMPANIES_SUSPEND.GET_COMPANIES_SUSPEND_INVOICES, getCompaniesSuspendInvoices);
}

export function* getCompaniesSuspendInvoices(action) {
  try {
    const response = yield call(companiesSuspendServices.invoices, action.payload);
    if (response.success) {
      yield put(actions.getCompaniesSuspendInvoicesSuccess(response.data));
    } else {
      yield put(actions.getCompaniesSuspendInvoicesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCompaniesSuspendInvoicesFail(error));
  }
}
