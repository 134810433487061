export const BANK_ACCOUNT = {
  SET_FILTER_BANK_ACCOUNT: 'SET_FILTER_BANK_ACCOUNT',

  FETCH_BANK_ACCOUNT_DATA_REQUEST: 'FETCH_BANK_ACCOUNT_DATA_REQUEST',
  FETCH_BANK_ACCOUNT_DATA_SUCCESS: 'FETCH_BANK_ACCOUNT_DATA_SUCCESS',
  FETCH_BANK_ACCOUNT_DATA_FAIL: 'FETCH_BANK_ACCOUNT_DATA_FAIL',

  GET_BANK_ACCOUNT_COMPANY_REQUEST: 'GET_BANK_ACCOUNT_COMPANY_REQUEST',
  GET_BANK_ACCOUNT_COMPANY_SUCCESS: 'GET_BANK_ACCOUNT_COMPANY_SUCCESS',
  GET_BANK_ACCOUNT_COMPANY_FAIL: 'GET_BANK_ACCOUNT_COMPANY_FAIL',
};
