import * as types from '../actionTypes';

const resetFilter = {
  keyword: '',
  date_option: 2,
  date_from: '',
  date_to: '',
  status: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  kraviaInvoices: {
    total: 0,
    rows: []
  },
  kraviaInvoicesNotMatch: {
    total: 0,
    rows: []
  },
  kraviaInvoice: {}
};

const kraviaInvoices = (state = initialState, action) => {
  switch (action.type) {
    case types.KRAVIA_INVOICES.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case types.KRAVIA_INVOICES.SET_FILTER_KRAVIA_INVOICES:
      return {
        ...state,
        filter: action.payload
      };

    case types.KRAVIA_INVOICES.RESET_FILTER_KRAVIA_INVOICES:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.KRAVIA_INVOICES.GET_KRAVIA_INVOICES:
      return {
        ...state,
        loading: true
      };
    case types.KRAVIA_INVOICES.GET_KRAVIA_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        kraviaInvoices: action.payload
      };
    case types.KRAVIA_INVOICES.GET_KRAVIA_INVOICES_FAIL:
      return {
        ...state,
        loading: false
      };

    // GET
    case types.KRAVIA_INVOICES.GET_KRAVIA_INVOICE:
      return {
        ...state,
        loading: true
      };
    case types.KRAVIA_INVOICES.GET_KRAVIA_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        kraviaInvoice: action.payload
      };
    case types.KRAVIA_INVOICES.GET_KRAVIA_INVOICE_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default kraviaInvoices;
