import * as types from '../actionTypes';

const initialState = {
  loading: false,
  userPermission: {}
};

const usersPermission = (state = initialState, action) => {
  switch (action.type) {
    case types.USERS_PERMISSION.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // LIST
    case types.USERS_PERMISSION.GET_USER_PERMISSION:
      return {
        ...state,
        loading: true
      };
    case types.USERS_PERMISSION.GET_USER_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        userPermission: action.payload
      };
    case types.USERS_PERMISSION.GET_USER_PERMISSION_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default usersPermission;
