import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import productsServices from '../../../services/products.services';

export default function* fetchListProductSalesSaga() {
  yield takeEvery(types.PRODUCTS.FETCH_PRODUCT_SALES_LIST_REQUEST, fetchListProductSales);
}

export function* fetchListProductSales(action) {
  try {
    const response = yield call(productsServices.listSales, action.payload);
    if (response.success) {
      yield put(actions.fetchListProductSalesSuccess(response.data));
    } else {
      yield put(actions.fetchListProductSalesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.fetchListProductSalesFail(error));
  }
}
