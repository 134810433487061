import * as types from '../actionTypes';

// GET
export const getVatSettings = payload => {
  return {
    type: types.VAT_SETTINGS.GET_VAT_SETTINGS_REQUEST,
    payload
  };
};
export const getVatSettingsSuccess = payload => ({
  type: types.VAT_SETTINGS.GET_VAT_SETTINGS_SUCCESS,
  payload
});
export const getVatSettingsFail = payload => ({
  type: types.VAT_SETTINGS.GET_VAT_SETTINGS_FAIL,
  payload
});
