export default {
  development: {
    server: 'http://localhost:5002',
    web: 'http://localhost:5001',
    ORYTON_WEB: 'https://oryton.vn', // http://localhost:4101
    BILAGSKY_WEB: 'https://dev.bilagsky.no', // http://localhost:4101
    DRIVE_WEB: '#', // http://localhost:4101

    SALARY_WEB: 'https://dev-salary.oryton.no',

    ACCOUNTING_WEB: 'https://dev-accounting.oryton.no',
    accountantEndPoint: 'https://dev-accounting.oryton.no',

    ACCOUNT_WEB: 'https://account.oryton.vn', // http://localhost:4101
    ACCOUNT_API: 'https://api-account.oryton.vn', // http://localhost:4102

    orytonEndPoint: 'https://api.oryton.vn',
    bilagskyEndPoint: 'https://dev.bilagsky.no',
    mailServerEndPoint: ' https://dev-mailserver.bilagsky.no',

    contactsDbEndPoint: 'https://api.dev-contacts.oryton.no',

    bucketXml: 'xml.localhost:5002',
    timezone: 'Asia/Singapore',
    encryptDataKey: 'IData#8679@',
    creditKey: 'oryton#2024@',
  },
  // PRODUCTION
  production: {
    server: 'https://invoice.oryton.no',
    web: 'https://invoice.oryton.no',
    ORYTON_WEB: 'https://oryton.no',
    BILAGSKY_WEB: 'https://bilagsky.no', // http://localhost:4101
    DRIVE_WEB: '#', // http://localhost:4101

    SALARY_WEB: 'https://salary.oryton.no',

    ACCOUNTING_WEB: 'https://accounting.oryton.no',
    accountantEndPoint: 'https://accounting.oryton.no',

    ACCOUNT_WEB: 'https://account.oryton.no',
    ACCOUNT_API: 'https://api-account.oryton.no',

    orytonEndPoint: 'https://api.oryton.no',
    bilagskyEndPoint: 'https://bilagsky.no',
    mailServerEndPoint: ' https://mailserver.bilagsky.no',

    contactsDbEndPoint: 'https://api.contacts.oryton.no',

    bucketXml: 'xml.invoice.oryton.no',
    timezone: 'Europe/Amsterdam',//'Europe/Amsterdam'
    encryptDataKey: 'IData#666888@',
    creditKey: 'oryton#C989@',
  }
}