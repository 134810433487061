import * as types from '../actionTypes';

export const setFilterContracts = payload => ({
  type: types.CONTRACTS.SET_FILTER_CONTRACTS,
  payload
});
export const resetFilterContracts = payload => ({
  type: types.CONTRACTS.RESET_FILTER_CONTRACTS,
  payload
});

// LIST
export const getContracts = payload => {
  return {
    type: types.CONTRACTS.GET_CONTRACTS,
    payload
  };
};
export const getContractsSuccess = payload => ({
  type: types.CONTRACTS.GET_CONTRACTS_SUCCESS,
  payload
});
export const getContractsFail = payload => ({
  type: types.CONTRACTS.GET_CONTRACTS_FAIL,
  payload
});