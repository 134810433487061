import * as actionTypes from '../../actionTypes';
import * as actions from '../../actions';

import { call, put, takeEvery } from 'redux-saga/effects';

import companyServices from '../../../services/company.services';

export default function* getCompaniesSaga() {
  yield takeEvery(actionTypes.COMPANY.GET_COMPANIES_REQUEST, getCompanies);
}

export function* getCompanies(action) {
  try {
    const response = yield call(companyServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCompaniesSuccess(response.data));
    }
  } catch (error) {
    yield put(actions.getCompaniesFail(error));
  }
}
