import * as types from '../actionTypes';

export const setFilterInvoiceDistribution = payload => ({
  type: types.INVOICE_DISTRIBUTION.SET_FILTER_INVOICE_DISTRIBUTION,
  payload
});

// LIST
export const getInvoiceDistributions = payload => {
  return {
    type: types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTIONS_REQUEST,
    payload
  };
};
export const getInvoiceDistributionsSuccess = payload => ({
  type: types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTIONS_SUCCESS,
  payload
});
export const getInvoiceDistributionsFail = payload => ({
  type: types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTIONS_FAIL,
  payload
});

export const setFilterDistributionSends = payload => ({
  type: types.INVOICE_DISTRIBUTION.SET_FILTER_DISTRIBUTION_SENDS,
  payload
});

export const resetFilterDistributionSends = payload => ({
  type: types.INVOICE_DISTRIBUTION.RESET_FILTER_DISTRIBUTION_SENDS,
  payload
});

// Distribution Sends
export const getDistributionSends = payload => {
  return {
    type: types.INVOICE_DISTRIBUTION.GET_DISTRIBUTION_SENDS_REQUEST,
    payload
  };
};
export const getDistributionSendsSuccess = payload => ({
  type: types.INVOICE_DISTRIBUTION.GET_DISTRIBUTION_SENDS_SUCCESS,
  payload
});
export const getDistributionSendsFail = payload => ({
  type: types.INVOICE_DISTRIBUTION.GET_DISTRIBUTION_SENDS_FAIL,
  payload
});