import * as types from '../actionTypes';

export const setFilterInvoiceNote = payload => ({
  type: types.INVOICE_NOTE.SET_FILTER_INVOICE_NOTE,
  payload
});

// LIST
export const fetchInvoiceNote = payload => {
  return {
    type: types.INVOICE_NOTE.FETCH_INVOICE_NOTE_DATA_REQUEST,
    payload
  };
};
export const fetchInvoiceNoteSuccess = payload => ({
  type: types.INVOICE_NOTE.FETCH_INVOICE_NOTE_DATA_SUCCESS,
  payload
});
export const fetchInvoiceNoteFail = payload => ({
  type: types.INVOICE_NOTE.FETCH_INVOICE_NOTE_DATA_FAIL,
  payload
});

// SAVE
export const saveInvoiceNote = payload => {
  return {
    type: types.INVOICE_NOTE.SAVE_INVOICE_NOTE_REQUEST,
    payload
  };
};
export const saveInvoiceNoteSuccess = payload => ({
  type: types.INVOICE_NOTE.SAVE_INVOICE_NOTE_REQUEST_SUCCESS,
  payload
});
export const saveInvoiceNoteFail = payload => ({
  type: types.INVOICE_NOTE.SAVE_INVOICE_NOTE_REQUEST_FAIL,
  payload
});