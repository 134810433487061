export const invoiceTypesNames = {
  'invoice': 'invoice',
  'repeating_invoice': 'repeating',
  'cash_invoice': 'cashInvoice',
  'offer_invoice': 'offer',
  'order_invoice': 'order',
  'credit_note_invoice': 'creditNote',
}

export const invoiceTypes = {
  Invoice: 'invoice',
  RepeatingInvoice: 'repeating_invoice',
  CashInvoice: 'cash_invoice',
  OfferInvoice: 'offer_invoice',
  OrderInvoice: 'order_invoice',
  CreditNoteInvoice: 'credit_note_invoice',
};
