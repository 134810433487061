import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ReportServices from '../../../services/report.services';

export default function* getAccountReceivableReportSaga() {
  yield takeEvery(types.REPORT.GET_ACCOUNT_RECEIVABLE_REPORT_REQUEST, getAccountReceivableReport);
}

export function* getAccountReceivableReport(action) {
  try {
    const response = yield call(ReportServices.accountReceivableReport, action.payload);
    if (response.success) {
      yield put(actions.getAccountReceivableReportSuccess(response.data));
    } else {
      yield put(actions.getAccountReceivableReportFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getAccountReceivableReportFail(error));
  }
}
