import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  invoiceSetting: null,
};

const invoiceSettings = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case types.INVOICE_SETTINGS.GET_INVOICE_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_SETTINGS.GET_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceSetting: action.payload
      };
    case types.INVOICE_SETTINGS.GET_INVOICE_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceSettings;
