import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import repeatingInvoicesServices from '../../../services/repeating-invoices.services';

export default function* getChartDataSaga() {
  yield takeEvery(types.REPEATING_INVOICE.GET_CHART_DATA_REQUEST, getChartData);
}

export function* getChartData(action) {
  try {
    const response = yield call(repeatingInvoicesServices.getChart, action.payload);
    if (response.success) {
      yield put(actions.getChartDataSuccess(response.data));
    } else {
      yield put(actions.getChartDataFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getChartDataFail(error));
  }
}
