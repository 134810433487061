import * as types from '../actionTypes';

// GET
export const getSalesmanSettings = payload => {
  return {
    type: types.SALESMAN_SETTINGS.GET_SALESMAN_SETTINGS_REQUEST,
    payload
  };
};
export const getSalesmanSettingsSuccess = payload => ({
  type: types.SALESMAN_SETTINGS.GET_SALESMAN_SETTINGS_SUCCESS,
  payload
});
export const getSalesmanSettingsFail = payload => ({
  type: types.SALESMAN_SETTINGS.GET_SALESMAN_SETTINGS_FAIL,
  payload
});
