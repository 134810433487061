export const CUSTOMER_CONTACT = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_CUSTOMER_CONTACTS: 'SET_FILTER_CUSTOMER_CONTACTS',
  RESET_FILTER_CUSTOMER_CONTACTS: 'RESET_FILTER_CUSTOMER_CONTACTS',

  GET_CUSTOMER_CONTACTS: 'GET_CUSTOMER_CONTACTS',
  GET_CUSTOMER_CONTACTS_SUCCESS: 'GET_CUSTOMER_CONTACTS_SUCCESS',
  GET_CUSTOMER_CONTACTS_FAIL: 'GET_CUSTOMER_CONTACTS_FAIL',

  GET_CUSTOMER_CONTACT_OPTIONS: 'GET_CUSTOMER_CONTACT_OPTIONS',
  GET_CUSTOMER_CONTACT_OPTIONS_SUCCESS: 'GET_CUSTOMER_CONTACT_OPTIONS_SUCCESS',
  GET_CUSTOMER_CONTACT_OPTIONS_FAIL: 'GET_CUSTOMER_CONTACT_OPTIONS_FAIL',

  GET_CUSTOMER_CONTACT: 'GET_CUSTOMER_CONTACT',
  GET_CUSTOMER_CONTACT_SUCCESS: 'GET_CUSTOMER_CONTACT_SUCCESS',
  GET_CUSTOMER_CONTACT_FAIL: 'GET_CUSTOMER_CONTACT_FAIL',

  GET_CONTACT_PERSON_OPTIONS: 'GET_CONTACT_PERSON_OPTIONS',
  GET_CONTACT_PERSON_OPTIONS_SUCCESS: 'GET_CONTACT_PERSON_OPTIONS_SUCCESS',
  GET_CONTACT_PERSON_OPTIONS_FAIL: 'GET_CONTACT_PERSON_OPTIONS_FAIL',
};


