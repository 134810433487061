import * as types from '../actionTypes';

export const setFilterCustomerDiscounts = payload => ({
  type: types.CUSTOMER_DISCOUNTS.SET_FILTER_CUSTOMER_DISCOUNTS,
  payload
});

export const resetFilterCustomerDiscounts = payload => ({
  type: types.CUSTOMER_DISCOUNTS.RESET_FILTER_CUSTOMER_DISCOUNTS,
  payload
});

// LIST DISCOUNTS
export const getCustomerDiscounts = payload => {
  return {
    type: types.CUSTOMER_DISCOUNTS.GET_CUSTOMER_DISCOUNTS,
    payload
  };
};
export const getCustomerDiscountsSuccess = payload => ({
  type: types.CUSTOMER_DISCOUNTS.GET_CUSTOMER_DISCOUNTS_SUCCESS,
  payload
});
export const getCustomerDiscountsFail = payload => ({
  type: types.CUSTOMER_DISCOUNTS.GET_CUSTOMER_DISCOUNTS_FAIL,
  payload
});