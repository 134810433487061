import * as types from '../actionTypes';

import moment from 'moment-timezone';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const resetFilterChart = {
  statuses: [11, 12],
  year: moment().year()
};

const filterChart = { ...resetFilterChart };

const initialState = {
  loading: false,
  filter,
  repeatingInvoices: {
    total: 0,
    rows: []
  },
  repeatingInvoice: null,
  filterChart,
  chartData: []
};

const repeatingInvoices = (state = initialState, action) => {
  switch (action.type) {
    case types.REPEATING_INVOICE.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case types.REPEATING_INVOICE.SET_FILTER_REPEATING_INVOICE:
      return {
        ...state,
        filter: action.payload
      };
    case types.REPEATING_INVOICE.RESET_FILTER_REPEATING_INVOICE:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.REPEATING_INVOICE.FETCH_REPEATING_INVOICE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPEATING_INVOICE.FETCH_REPEATING_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        repeatingInvoices: action.payload
      };
    case types.REPEATING_INVOICE.FETCH_REPEATING_INVOICE_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET
    case types.REPEATING_INVOICE.GET_REPEATING_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPEATING_INVOICE.GET_REPEATING_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        repeatingInvoice: action.payload
      };
    case types.REPEATING_INVOICE.GET_REPEATING_INVOICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };

    // ADD
    case types.REPEATING_INVOICE.SAVE_REPEATING_INVOICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REPEATING_INVOICE.SAVE_REPEATING_INVOICE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.REPEATING_INVOICE.SAVE_REPEATING_INVOICE_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };


    // RESET FILTER CHART
    case types.REPEATING_INVOICE.SET_FILTER_CHART:
      return {
        ...state,
        filterChart: action.payload
      };
    case types.REPEATING_INVOICE.RESET_FILTER_CHART:
      return {
        ...state,
        filterChart: { ...resetFilterChart }
      };

    // GET CHART DATA
    case types.REPEATING_INVOICE.GET_CHART_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.REPEATING_INVOICE.GET_CHART_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        chartData: action.payload
      };
    case types.REPEATING_INVOICE.GET_CHART_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default repeatingInvoices;
