import * as types from '../actionTypes';

// LIST
export const getCompaniesPaymentPackages = payload => {
  return {
    type: types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGES,
    payload
  };
};
export const getCompaniesPaymentPackagesSuccess = payload => ({
  type: types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGES_SUCCESS,
  payload
});
export const getCompaniesPaymentPackagesFail = payload => ({
  type: types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGES_FAIL,
  payload
});

// GET ITEM
export const getCompaniesPaymentPackage = payload => {
  return {
    type: types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGE,
    payload
  };
};
export const getCompaniesPaymentPackageSuccess = payload => ({
  type: types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGE_SUCCESS,
  payload
});
export const getCompaniesPaymentPackageFail = payload => ({
  type: types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGE_FAIL,
  payload
});

export const getPaymentCreditCheck = payload => {
  return {
    type: types.COMPANIES_PAYMENT_PACKAGES.GET_PAYMENT_CREDIT_CHECK,
    payload
  };
};
export const getPaymentCreditCheckSuccess = payload => ({
  type: types.COMPANIES_PAYMENT_PACKAGES.GET_PAYMENT_CREDIT_CHECK_SUCCESS,
  payload
});
export const getPaymentCreditCheckFail = payload => ({
  type: types.COMPANIES_PAYMENT_PACKAGES.GET_PAYMENT_CREDIT_CHECK_FAIL,
  payload
});
