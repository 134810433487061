import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import subscriptionSettingsServices from '../../../services/subscription-settings.services';

export default function* getSubscriptionSettingsSaga() {
  yield takeEvery(types.SUBSCRIPTION_SETTINGS.GET_SUBSCRIPTION_SETTINGS_REQUEST, getSubscriptionSettings);
}

export function* getSubscriptionSettings(action) {
  try {
    const response = yield call(subscriptionSettingsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getSubscriptionSettingsSuccess(response.data));
    } else {
      yield put(actions.getSubscriptionSettingsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getSubscriptionSettingsFail(error));
  }
}
