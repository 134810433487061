import * as types from '../actionTypes';

const resetFilterAvtalegiroStatistics = {
  keyword: '',
  sortBy: 'companies_id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
}

const resetFilterAvtalegiroStatisticsList = {
  keyword: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
}

const filterAvtalegiroStatistics = { ...resetFilterAvtalegiroStatistics }
const filterAvtalegiroStatisticsList = { ...resetFilterAvtalegiroStatisticsList }

const initialState = {
  invoiceSent: {},
  filterAvtalegiroStatistics,
  filterAvtalegiroStatisticsList,
  avtalegiroStatistics: {
    total: 0,
    rows: [],
  },
  avtalegiroStatisticsList: {
    total: 0,
    rows: [],
  },
};

const avtalegiro = (state = initialState, action) => {
  switch (action.type) {
    //FILTER
    case types.AVTALEGIRO.SET_FILTER_AVTALEGIRO_STATISTICS:
      return {
        ...state,
        filterAvtalegiroStatistics: action.payload
      };
    case types.AVTALEGIRO.RESET_FILTER_AVTALEGIRO_STATISTICS:
      return {
        ...state,
        filterAvtalegiroStatistics: { ...resetFilterAvtalegiroStatistics }
      };

    //FILTER LIST
    case types.AVTALEGIRO.SET_FILTER_AVTALEGIRO_STATISTICS_LIST:
      return {
        ...state,
        filterAvtalegiroStatisticsList: action.payload
      };
    case types.AVTALEGIRO.RESET_FILTER_AVTALEGIRO_STATISTICS_LIST:
      return {
        ...state,
        filterAvtalegiroStatisticsList: { ...resetFilterAvtalegiroStatisticsList }
      };

    // LIST
    case types.AVTALEGIRO.CHECK_INVOICE_SENT:
      return {
        ...state
      };
    case types.AVTALEGIRO.CHECK_INVOICE_SENT_SUCCESS:
      return {
        ...state,
        invoiceSent: action.payload,
      };
    case types.AVTALEGIRO.CHECK_INVOICE_SENT_FAIL:
      return {
        ...state
      };


    // AVTALEGIRO STATISTICS
    case types.AVTALEGIRO.AVTALEGIRO_STATISTICS:
      return {
        ...state
      };
    case types.AVTALEGIRO.AVTALEGIRO_STATISTICS_SUCCESS:
      return {
        ...state,
        avtalegiroStatistics: action.payload,
      };
    case types.AVTALEGIRO.AVTALEGIRO_STATISTICS_FAIL:
      return {
        ...state
      };

    // AVTALEGIRO STATISTICS LIST
    case types.AVTALEGIRO.AVTALEGIRO_STATISTICS_LIST:
      return {
        ...state
      };
    case types.AVTALEGIRO.AVTALEGIRO_STATISTICS_LIST_SUCCESS:
      return {
        ...state,
        avtalegiroStatisticsList: action.payload,
      };
    case types.AVTALEGIRO.AVTALEGIRO_STATISTICS_LIST_FAIL:
      return {
        ...state
      };

    //default  
    default:
      return state;
  }
};
export default avtalegiro;
