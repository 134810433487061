import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.vatCodeSettings.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async params => {
    try {
      const { companies_id, ...query } = params;
      let url = api.vatCodeSettings.item.replace(':companies_id', params.companies_id);

      if (query) {
        const queryParams = httpHelper.serialize(query);
        url = `${api.vatCodeSettings.item.replace(':companies_id', params.companies_id)}?${queryParams}`;
      }

      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async params => {
    try {
      const url = api.vatCodeSettings.save;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
