import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  list: async params => {
    try {
      const { companies_id, customer_id, ...newParams } = params;
      const queryParams = httpHelper.serialize(newParams);
      const url = `${api.customerContact.list.replace(':companies_id', params.companies_id).replace(':customer_id', params.customer_id)}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async params => {
    try {
      const url = api.customerContact.get.replace(':companies_id', params.companies_id).replace(':customer_id', params.customer_id).replace(':id', params.id);      
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  options: async params => {
    try {
      const url = api.customerContact.options.replace(':companies_id', params.companies_id).replace(':customer_id', params.customer_id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  contactOptions: async params => {
    try {
      const url = api.customerContact.contactOptions.replace(':companies_id', params.companies_id).replace(':customer_id', params.customer_id);
      const { companies_id, customer_id, ...newParams } = params;
      const queryParams = httpHelper.serialize(newParams);
      const data = await BaseServices.getDataToken(`${url}?${queryParams}`, params.companies_id);    
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async params => {
    try {
      const url = api.customerContact.delete.replace(':companies_id', params.companies_id).replace(':customer_id', params.customer_id).replace(':id', params.id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async params => {
    try {
      const url = api.customerContact.save.replace(':companies_id', params.companies_id).replace(':customer_id', params.customer_id);
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
