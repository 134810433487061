import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import kraviaTransactionsServices from '../../../services/kravia-transactions.services';

export default function* getKraviaTransactionsNotMatchSaga() {
  yield takeEvery(types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_NOT_MATCH, getKraviaTransactionsNotMatch);
}

export function* getKraviaTransactionsNotMatch(action) {
  try {
    const response = yield call(kraviaTransactionsServices.listNotMatch, action.payload);
    if (response.success) {
      yield put(actions.getKraviaTransactionsNotMatchSuccess(response.data));
    } else {
      yield put(actions.getKraviaTransactionsNotMatchFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getKraviaTransactionsNotMatchFail(error));
  }
}
