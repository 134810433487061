import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getAdminInvoicesStatusSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_ADMIN_INVOICES_STATUS, getAdminInvoicesStatus);
}

export function* getAdminInvoicesStatus(action) {
  try {
    const response = yield call(adminReportServices.companiesInvoicesStatus, action.payload);
    if (response.success) {
      yield put(actions.getAdminInvoicesStatusSuccess(response.data));
    } else {
      yield put(actions.getAdminInvoicesStatusFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getAdminInvoicesStatusFail(error));
  }
}
