import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { invoiceStatus } from '../../../constants/invoice-status.constants';
import Select from '../../UI/reactSelect/ReactSelect';

const StatusFilter = props => {
  const { t, status, changeStatus } = props;

  const optionsStatus = [
    { value: '', label: t('all') },
    { value: invoiceStatus.Unpaid, label: t('unpaid') },
    { value: invoiceStatus.Partially_paid, label: t('partially_paid') },
    { value: invoiceStatus.Credited, label: t('credited') },
    { value: invoiceStatus.End_with_loss, label: t('lost') },
    { value: invoiceStatus.Paid, label: t('paid') },
    { value: invoiceStatus.Over_paid, label: t('over_paid') },
    { value: invoiceStatus.Inkasso, label: t('inkasso') },
    { value: invoiceStatus.Reminder, label: t('reminder') },
  ];
  const styleSelect = {
    control: base => ({
      ...base,
      minHeight: 25,
      height: '2.655rem',
      margin: '0',
      backgroundColor: '#ffffff',
      border: '1px solid #9e9e9e',
      borderRadius: '.4rem !important',
      boxShadow: 'none',
      textAlign: 'center',
      '&:hover': {
        outline: '0 !important',
        border: '1px solid #03a9f4 !important'
      },
      '&:focus': {
        outline: '0 !important',
        border: '1px solid #03a9f4 !important',
        boxShadow: 'none !important'
      }
    }),
    dropdownIndicator: base => ({
      ...base,
      // padding: '8px 8px 8px 0',
    }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none',
    }),
    valueContainer: base => ({
      ...base,
      padding: '0',
      height: '2.655rem',
      paddingLeft: '5px'
    })
  };

  return (
    <div className='col col-filter-status'>
      <div className="input-field">
        <label className="label-filter-status">{t('status')}</label>
        <Select
          name="status"
          selectedLabel={status}
          groupOptions={optionsStatus}
          handleSelect={changeStatus('status')}
          styles={styleSelect}
        />
      </div>
    </div>
  );
};

export default connect()(withNamespaces('invoices')(StatusFilter));
