export const INVOICE_HISTORY = {
  SET_FILTER_INVOICE_HISTORY: 'SET_FILTER_INVOICE_HISTORY',

  FETCH_INVOICE_HISTORY_DATA_REQUEST: 'FETCH_INVOICE_HISTORY_DATA_REQUEST',
  FETCH_INVOICE_HISTORY_DATA_SUCCESS: 'FETCH_INVOICE_HISTORY_DATA_SUCCESS',
  FETCH_INVOICE_HISTORY_DATA_FAIL: 'FETCH_INVOICE_HISTORY_DATA_FAIL',

  SAVE_INVOICE_HISTORY_REQUEST: 'SAVE_INVOICE_HISTORY_REQUEST',
  SAVE_INVOICE_HISTORY_REQUEST_SUCCESS: 'SAVE_INVOICE_HISTORY_REQUEST_SUCCESS',
  SAVE_INVOICE_HISTORY_REQUEST_FAIL: 'SAVE_INVOICE_HISTORY_REQUEST_FAIL',

  DELETE_INVOICE_HISTORY_REQUEST: 'DELETE_INVOICE_HISTORY_REQUEST',
  DELETE_INVOICE_HISTORY_REQUEST_SUCCESS: 'DELETE_INVOICE_HISTORY_REQUEST_SUCCESS',
  DELETE_INVOICE_HISTORY_REQUEST_FAIL: 'DELETE_INVOICE_HISTORY_REQUEST_FAIL',
};
