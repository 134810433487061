import * as types from '../actionTypes';

const initialState = {
  loading: false,
  accountCodes: [],
};

const accountants = (state = initialState, action) => {
  switch (action.type) {
    case types.ACCOUNTANTS.GET_ACCOUNT_CODES:
      return {
        ...state,
        loading: true
      };
    case types.ACCOUNTANTS.GET_ACCOUNT_CODES_SUCCESS:
      return {
        ...state,
        loading: false,
        accountCodes: action.payload
      };
    case types.ACCOUNTANTS.GET_ACCOUNT_CODES_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default accountants;
