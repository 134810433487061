import { customerColumns } from '../../constants/import-customer.constants';
import { productColumns } from './../../constants/import-product.constants';

import * as types from '../actionTypes';

const resetCustomerFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetProductFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filterCustomer = { ...resetCustomerFilter };
const filterProduct = { ...resetProductFilter };

const initialState = {
  loading: false,
  filterCustomer,
  filterProduct,
  customerImport: {
    companies_id: 0,
    columnDefine: customerColumns,
    columnByTable: [null, null, null, null, null, null],
    dataRows: [],
    columnExels: [],
  },
  productImport: {
    companies_id: 0,
    columnDefine: productColumns,
    columnByTable: [null, null, null, null, null, null],
    dataRows: [],
    columnExels: [],
  },
};

const imports = (state = initialState, action) => {
  switch (action.type) {
    case types.IMPORTS.SET_FILTER_CUSTOMER:
      return {
        ...state,
        filterCustomer: action.payload
      };

    case types.IMPORTS.SET_FILTER_PRODUCT:
      return {
        ...state,
        filterProduct: action.payload
      };

    // SET_CUSTOMER_DATA_IMPORT
    case types.IMPORTS.SET_CUSTOMER_DATA_IMPORT:
      return {
        ...state,
        customerImport: action.payload,
      };

    // SET_PRODUCT_DATA_IMPORT
    case types.IMPORTS.SET_PRODUCT_DATA_IMPORT:
      return {
        ...state,
        productImport: action.payload,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default imports;
