export const AVTALEGIRO = {
  SET_FILTER_AVTALEGIRO_STATISTICS: 'SET_FILTER_AVTALEGIRO_STATISTICS',
  RESET_FILTER_AVTALEGIRO_STATISTICS: 'RESET_FILTER_AVTALEGIRO_STATISTICS',

  SET_FILTER_AVTALEGIRO_STATISTICS_LIST: 'SET_FILTER_AVTALEGIRO_STATISTICS_LIST',
  RESET_FILTER_AVTALEGIRO_STATISTICS_LIST: 'RESET_FILTER_AVTALEGIRO_STATISTICS_LIST',

  CHECK_INVOICE_SENT: 'CHECK_INVOICE_SENT',
  CHECK_INVOICE_SENT_SUCCESS: 'CHECK_INVOICE_SENT_SUCCESS',
  CHECK_INVOICE_SENT_FAIL: 'CHECK_INVOICE_SENT_FAIL',

  AVTALEGIRO_STATISTICS: 'AVTALEGIRO_STATISTICS',
  AVTALEGIRO_STATISTICS_SUCCESS: 'AVTALEGIRO_STATISTICS_SUCCESS',
  AVTALEGIRO_STATISTICS_FAIL: 'AVTALEGIRO_STATISTICS_FAIL',

  AVTALEGIRO_STATISTICS_LIST: 'AVTALEGIRO_STATISTICS_LIST',
  AVTALEGIRO_STATISTICS_LIST_SUCCESS: 'AVTALEGIRO_STATISTICS_LIST_SUCCESS',
  AVTALEGIRO_STATISTICS_LIST_FAIL: 'AVTALEGIRO_STATISTICS_LIST_FAIL',
};
