import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getCreditStatisticsListSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_CREDIT_STATISTICS_LIST, getCreditStatisticsList);
}

export function* getCreditStatisticsList(action) {
  try {
    const response = yield call(adminReportServices.creditStatisticsList, action.payload);
    if (response.success) {
      yield put(actions.getCreditStatisticsListSuccess(response.data));
    } else {
      yield put(actions.getCreditStatisticsListFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCreditStatisticsListFail(error));
  }
}
