import * as types from '../actionTypes';

export const setFilterUserInvoiceServices = payload => ({
  type: types.USER_INVOICE_SERVICES.SET_FILTER_USER_INVOICE_SERVICES,
  payload
});

export const resetFilterUserInvoiceServices = payload => ({
  type: types.USER_INVOICE_SERVICES.RESET_FILTER_USER_INVOICE_SERVICES,
  payload
});


export const setUserSelected = payload => ({
  type: types.USER_INVOICE_SERVICES.SET_USER_SELECTED,
  payload
});

// LIST
export const getUserInvoiceServices = payload => {
  return {
    type: types.USER_INVOICE_SERVICES.GET_USER_INVOICE_SERVICES,
    payload
  };
};
export const getUserInvoiceServicesSuccess = payload => ({
  type: types.USER_INVOICE_SERVICES.GET_USER_INVOICE_SERVICES_SUCCESS,
  payload
});
export const getUserInvoiceServicesFail = payload => ({
  type: types.USER_INVOICE_SERVICES.GET_USER_INVOICE_SERVICES_FAIL,
  payload
});