import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerServices from '../../../services/customer.services';

export default function* getDataBrregSaga() {
  yield takeEvery(types.CUSTOMER.GET_DATA_BRREG, getDataBrreg);
}

export function* getDataBrreg(action) {
  try {
    const response = yield call(customerServices.getDataBrreg, action.payload);
    if (response !== 1) {
      yield put(actions.getDataBrregSuccess(response));
    } else {
      yield put(actions.getDataBrregFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getDataBrregFail(error));
  }
}
