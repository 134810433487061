import './Payments.css';

import { Elements, StripeProvider } from 'react-stripe-elements';
import { collapsible, initDropdown } from '../../../../../common/custom.plugins';

import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import stripeConfigs from '../../../../../configs/stripe.configs';
import currencyHelper from '../../../../../helpers/currencyHelper';
import paymentsServices from '../../../../../services/payments.services';
import { checkExpired } from '../../../../../utils/Utils';
import Loading from '../../../../UI/Loading';
import InjectedCard from './InjectedCard';
import Language from './Language';

const { PUBLIC_KEY } = stripeConfigs[process.env.NODE_ENV];
class Payments extends React.Component {
  state = {
    data: [],
    errMsg: '',
    paySubscription: false,
    success: false,
  }

  componentDidMount() {
    this.init();
    initDropdown();
    collapsible();
  }

  componentDidUpdate() {
    initDropdown();
    collapsible();
  }

  init = async () => {
    const { t } = this.props;
    const { uuid } = this.props.match.params;
    const response = await paymentsServices.get(uuid);
    if (response.success) {
      const data = response.data;
      if (checkExpired(data?.expired_date)) {
        if (Number(data?.status) === 0) {
          this.setState({
            data: data,
          });
        } else {
          this.setState({
            errMsg: t('msgPaid')
          });
        }
      } else {
        this.setState({
          errMsg: t('msgExpired')
        });
      }
    } else {
      this.setState({
        errMsg: t('msgNotExisted')
      });
    }
  }

  buildTranslated = (text, args) => {
    const { t } = this.props;
    const translated = text.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined'
        ? args[number]
        : match;
    });
    return translated;
  }

  setSuccess = (success) => {
    this.setState({
      success
    });
  }

  renderCard = () => {
    const { t } = this.props;
    const { data } = this.state;

    const { location: { host } } = window;
    const prodHost = ['invoice.oryton.no', 'www.invoice.oryton.no'];
    let stripePublicKey = stripeConfigs['development']?.PUBLIC_KEY;
    if (prodHost.includes(host)) {
      stripePublicKey = stripeConfigs['production']?.PUBLIC_KEY;
    }

    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="row">
              <StripeProvider apiKey={stripePublicKey}>
                <Elements>
                  <InjectedCard
                    t={t}
                    data={data}
                    setSuccess={this.setSuccess}
                  />
                </Elements>
              </StripeProvider>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderError = () => {
    const { t } = this.props;
    return (
      <div className="col s12">
        <div className="card">
          <div className="card-content">
            <div className="row">
              {t(this.state.errMsg)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAmount = () => {
    const { data } = this.state;
    if (data?.package_price_include_vat > 0) {
      return (
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              <div className="row">
                <div className="col s12">
                  <div>
                    {data?.package_price_include_vat > 0 &&
                      <>
                        <p className="m-y-10 b">{data?.package_name}</p>
                        <p className="m-y-10">
                          {currencyHelper.formatMoney(data?.package_price_include_vat ?? 0)} NOK
                        </p>
                      </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return '';
  }

  renderInfoCompany = () => {
    const { t } = this.props;
    const { data } = this.state;
    if (data) {
      return (
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              <div className="row">
                <div className="col s12">
                  <p>{t('company_name')}: <b>{data?.company_name}</b></p>
                  <p>{t('organization_number')}: <b>{data?.organization_number}</b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  renderSubscription = () => {
    const { t } = this.props;
    const { data } = this.state;
    if (data) {
      let package_name = '';
      if (data.type === 'package') {
        package_name = this.buildTranslated(t(data?.package_name_translate), [currencyHelper.formatMoney(data?.num_of_payment, 0)]);
      }
      if (data.type === 'credit_check') {
        package_name = this.buildTranslated(t(data?.package_name_translate), [currencyHelper.formatMoney(data?.num_of_payment, 0), 50, 1]);
      }
      if (data.type === 'subscription') {
        package_name = t(data?.package_name_translate);
      }

      return (
        <div className="col s12">
          <div className="card">
            <div className="card-content">
              <div className="row">
                <div className="col s12">
                  <div>
                    <p className="m-y-10 b">{package_name}</p>
                    {data?.type === 'subscription' ?
                      <p className="m-y-10">
                        {t('itemVAT', {
                          price: currencyHelper.formatMoney(data?.package_price_include_vat ?? 0)
                        })}
                      </p>
                      :
                      <p className="m-y-10">
                        {currencyHelper.formatMoney(data?.package_price_include_vat ?? 0)} NOK
                      </p>
                    }
                    <div className="divider"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  renderPaymentSuccess = () => {
    return (
      <div className="col s12 center">
        <div className="card">
          <div className="card-content">
            <div className="row">
              <div className="col s12">
                <div className="box-icon">
                  <i className="material-icons m-t-10">check</i>
                </div>
                <div className="text-success">
                  Your payment is successful.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { t, loading } = this.props;
    return (
      <div className="section-paymentlink">
        <div className="row">
          <div className="col s12 right">
            <Language t={t} />
          </div>
          <Loading loading={loading} />
          {!this.state.success ?

            this.state.errMsg == ''
              ?
              <>
                {
                  this.state.data?.company_name
                    ?
                    <>
                      {this.renderInfoCompany()}
                      {this.renderSubscription()}
                    </>
                    :
                    <>
                      {this.renderAmount()}
                    </>
                }
                {this.renderCard()}
              </>
              :
              this.renderError()
            : null
          }
          {this.state.success && this.renderPaymentSuccess()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.payments.loading;
  return {
    loading,
  };
};
export default withRouter(connect(mapStateToProps)(withNamespaces('payments')(Payments)));
