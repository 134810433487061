import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';

export default {
  get: async companies_id => {
    try {
      const url = api.reminderSettings.get.replace(':companies_id', companies_id).replace(':companies_id', companies_id);
      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async params => {
    try {
      const url = api.reminderSettings.save.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async companies_id => {
    try {
      const url = api.reminderSettings.delete.replace(':companies_id', companies_id).replace(':companies_id', companies_id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
