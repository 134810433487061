import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesServicesServices from '../../../services/companies-services.services';

export default function* getPaymentsSaga() {
  yield takeEvery(types.COMPANIES_SERVICES.GET_PAYMENTS_REQUEST, getPayments);
}

export function* getPayments(action) {
  try {
    const response = yield call(companiesServicesServices.payment, action.payload);
    if (response.success) {
      yield put(actions.getPaymentsSuccess(response.data));
    } else {
      yield put(actions.getPaymentsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getPaymentsFail(error));
  }
}
