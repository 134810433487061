export const USER_INVOICE_SERVICES = {
  SET_FILTER_USER_INVOICE_SERVICES: 'SET_FILTER_USER_INVOICE_SERVICES',
  RESET_FILTER_USER_INVOICE_SERVICES: 'RESET_FILTER_USER_INVOICE_SERVICES',

  SET_USER_SELECTED: 'SET_USER_SELECTED',

  GET_USER_INVOICE_SERVICES: 'GET_USER_INVOICE_SERVICES',
  GET_USER_INVOICE_SERVICES_SUCCESS: 'GET_USER_INVOICE_SERVICES_SUCCESS',
  GET_USER_INVOICE_SERVICES_FAIL: 'GET_USER_INVOICE_SERVICES_FAIL',
};


