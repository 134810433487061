import * as types from '../actionTypes';

const resetFilter = {
  companies_id: 0,
  keyword: '',
  organization_number: '',
  customer_name: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  customerInvoices: {
    total: 0,
    rows: []
  },
};

const customerInvoices = (state = initialState, action) => {
  switch (action.type) {
    case types.CUSTOMER_INVOICES.SET_FILTER_CUSTOMER_INVOICES:
      return {
        ...state,
        filter: action.payload
      };

    case types.CUSTOMER_INVOICES.RESET_FILTER_CUSTOMER_INVOICES:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.CUSTOMER_INVOICES.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // LIST INVOICES
    case types.CUSTOMER_INVOICES.GET_CUSTOMER_INVOICES:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER_INVOICES.GET_CUSTOMER_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        customerInvoices: action.payload
      };
    case types.CUSTOMER_INVOICES.GET_CUSTOMER_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default customerInvoices;
