import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  send: async params => {
    try {
      const url = api.avtalegiro.send;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  check: async params => {
    try {
      const url = api.avtalegiro.check;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  avtalegiroStatistics: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.avtalegiro.avtalegiroStatistics}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  avtalegiroStatisticsList: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.avtalegiro.avtalegiroStatisticsList.replace(':companies_id', params.companies_id)}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
