import * as types from '../actionTypes';

const resetFilter = {
  noPagination: false,
  pageSize: 15,
  pageNumber: 1,
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  companiesInvoiceServicesExcept: {
    rows: [],
    total: 0
  },
  userSelected: {}
};

const companiesInvoiceServicesExcept = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANIES_INVOICE_SERVICES_EXCEPT.SET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT:
      return {
        ...state,
        filter: action.payload
      };

    case types.COMPANIES_INVOICE_SERVICES_EXCEPT.RESET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.COMPANIES_INVOICE_SERVICES_EXCEPT.SET_USER_SELECTED:
      return {
        ...state,
        userSelected: action.payload
      };

    // LIST
    case types.COMPANIES_INVOICE_SERVICES_EXCEPT.GET_COMPANIES_INVOICE_SERVICES_EXCEPT:
      return {
        ...state,
        loading: true
      };
    case types.COMPANIES_INVOICE_SERVICES_EXCEPT.GET_COMPANIES_INVOICE_SERVICES_EXCEPT_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesInvoiceServicesExcept: action.payload
      };
    case types.COMPANIES_INVOICE_SERVICES_EXCEPT.GET_COMPANIES_INVOICE_SERVICES_EXCEPT_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default companiesInvoiceServicesExcept;
