export const INVOICES = {
  SET_FILTER_INVOICES: 'SET_FILTER_INVOICES',
  RESET_FILTER_INVOICES: 'RESET_FILTER_INVOICES',

  SHOW_SEND_EMAIL: 'SHOW_SEND_EMAIL',
  SHOW_SEND_EMAIL_SUCCESS: 'SHOW_SEND_EMAIL_SUCCESS',

  SET_LOADING: 'SET_LOADING',

  FETCH_INVOICES_DATA_REQUEST: 'FETCH_INVOICES_DATA_REQUEST',
  FETCH_INVOICES_DATA_SUCCESS: 'FETCH_INVOICES_DATA_SUCCESS',
  FETCH_INVOICES_DATA_FAIL: 'FETCH_INVOICES_DATA_FAIL',

  ADD_INVOICES_REQUEST: 'ADD_INVOICES_REQUEST',
  ADD_INVOICES_REQUEST_SUCCESS: 'ADD_INVOICES_REQUEST_SUCCESS',
  ADD_INVOICES_REQUEST_FAIL: 'ADD_INVOICES_REQUEST_FAIL',

  UPDATE_INVOICES_REQUEST: 'UPDATE_INVOICES_REQUEST',
  UPDATE_INVOICES_REQUEST_SUCCESS: 'UPDATE_INVOICES_REQUEST_SUCCESS',
  UPDATE_INVOICES_REQUEST_FAIL: 'UPDATE_INVOICES_REQUEST_FAIL',

  DELETE_INVOICES_REQUEST: 'DELETE_INVOICES_REQUEST',
  DELETE_INVOICES_REQUEST_SUCCESS: 'DELETE_INVOICES_REQUEST_SUCCESS',
  DELETE_INVOICES_REQUEST_FAIL: 'DELETE_INVOICES_REQUEST_FAIL',

  GET_INVOICE_DETAIL: 'GET_INVOICE_DETAIL',
  GET_INVOICE_DETAIL_SUCCESS: 'GET_INVOICE_DETAIL_SUCCESS',
  GET_INVOICE_DETAIL_FAIL: 'GET_INVOICE_DETAIL_FAIL',

  INVOICE_PREVIEW_REQUEST: 'INVOICE_PREVIEW_REQUEST',
  INVOICE_PREVIEW_REQUEST_SUCCESS: 'INVOICE_PREVIEW_REQUEST_SUCCESS',
  INVOICE_PREVIEW_REQUEST_FAIL: 'INVOICE_PREVIEW_REQUEST_FAIL',

  CREATE_INVOICE_REQUEST: 'CREATE_INVOICE_REQUEST',
  CREATE_INVOICE_REQUEST_SUCCESS: 'CREATE_INVOICE_REQUEST_SUCCESS',
  CREATE_INVOICE_REQUEST_FAIL: 'CREATE_INVOICE_REQUEST_FAIL',

  SEND_INVOICE_REQUEST: 'SEND_INVOICE_REQUEST',
  SEND_INVOICE_REQUEST_SUCCESS: 'SEND_INVOICE_REQUEST_SUCCESS',
  SEND_INVOICE_REQUEST_FAIL: 'SEND_INVOICE_REQUEST_FAIL',  

  GET_INVOICE_OPTIONS: 'GET_INVOICE_OPTIONS',
  GET_INVOICE_OPTIONS_SUCCESS: 'GET_INVOICE_OPTIONS_SUCCESS',
  GET_INVOICE_OPTIONS_FAIL: 'GET_INVOICE_OPTIONS_FAIL',
};


