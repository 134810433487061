import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceDraftsServices from '../../../services/invoice-drafts.services';

export default function* getInvoiceDraftsSagas() {
  yield takeEvery(types.INVOICE_DRAFTS.GET_INVOICE_DRAFTS_REQUEST, getInvoiceDrafts);
}

export function* getInvoiceDrafts(action) {
  try {
    const response = yield call(invoiceDraftsServices.list, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceDraftsSuccess(response.data));
    } else {
      yield put(actions.getInvoiceDraftsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceDraftsFail(error));
  }
}
