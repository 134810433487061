import * as types from '../actionTypes';

//FILTER
export const setFilterAvtalegiroStatistics = payload => ({
  type: types.AVTALEGIRO.SET_FILTER_AVTALEGIRO_STATISTICS,
  payload
});

export const resetFilterAvtalegiroStatistics = payload => ({
  type: types.AVTALEGIRO.RESET_FILTER_AVTALEGIRO_STATISTICS,
  payload
});

//FILTER LIST
export const setFilterAvtalegiroStatisticsList = payload => ({
  type: types.AVTALEGIRO.SET_FILTER_AVTALEGIRO_STATISTICS_LIST,
  payload
});

export const resetFilterAvtalegiroStatisticsList = payload => ({
  type: types.AVTALEGIRO.RESET_FILTER_AVTALEGIRO_STATISTICS_LIST,
  payload
});



// LIST
export const checkInvoiceSent = payload => {
  return {
    type: types.AVTALEGIRO.CHECK_INVOICE_SENT,
    payload
  };
};
export const checkInvoiceSentSuccess = payload => ({
  type: types.AVTALEGIRO.CHECK_INVOICE_SENT_SUCCESS,
  payload
});
export const checkInvoiceSentFail = payload => ({
  type: types.AVTALEGIRO.CHECK_INVOICE_SENT_FAIL,
  payload
});

// STATISTICS
export const avtalegiroStatistics = payload => {
  return {
    type: types.AVTALEGIRO.AVTALEGIRO_STATISTICS,
    payload
  };
};
export const avtalegiroStatisticsSuccess = payload => ({
  type: types.AVTALEGIRO.AVTALEGIRO_STATISTICS_SUCCESS,
  payload
});
export const avtalegiroStatisticsFail = payload => ({
  type: types.AVTALEGIRO.AVTALEGIRO_STATISTICS_FAIL,
  payload
});

// STATISTICS LIST
export const avtalegiroStatisticsList = payload => {
  return {
    type: types.AVTALEGIRO.AVTALEGIRO_STATISTICS_LIST,
    payload
  };
};
export const avtalegiroStatisticsListSuccess = payload => ({
  type: types.AVTALEGIRO.AVTALEGIRO_STATISTICS_LIST_SUCCESS,
  payload
});
export const avtalegiroStatisticsListFail = payload => ({
  type: types.AVTALEGIRO.AVTALEGIRO_STATISTICS_LIST_FAIL,
  payload
});