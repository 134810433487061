import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceDistributionServices from '../../../services/invoice-distribution.services';

export default function* getDistributionSendsSaga() {
  yield takeEvery(types.INVOICE_DISTRIBUTION.GET_DISTRIBUTION_SENDS_REQUEST, getDistributionSends);
}

export function* getDistributionSends(action) {
  try {
    const response = yield call(invoiceDistributionServices.sends, action.payload);
    if (response.success) {
      yield put(actions.getDistributionSendsSuccess(response.data));
    } else {
      yield put(actions.getDistributionSendsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getDistributionSendsFail(error));
  }
}
