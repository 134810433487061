import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import reminderSettingsServices from '../../../services/reminder-settings.services';

export default function* getReminderSettingSaga() {
  yield takeEvery(types.REMINDER_SETTINGS.GET_REMINDER_SETTING_REQUEST, getReminderSetting);
}

export function* getReminderSetting(action) {
  try {
    const response = yield call(reminderSettingsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getReminderSettingSuccess(response.data));
    } else {
      yield put(actions.getReminderSettingFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getReminderSettingFail(error));
  }
}
