import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.repeatingInvoices.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.repeatingInvoices.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async params => {
    try {
      const url = api.repeatingInvoices.save;
      const data = await BaseServices.postDataTokenForm(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.repeatingInvoices.update.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async id => {
    try {
      const url = api.repeatingInvoices.get.replace(':id', id);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  preview: async id => {
    try {
      const url = api.repeatingInvoices.preview.replace(':id', id);
      const response = await BaseServices.getDataToken(url);
      if (response.status === 500) {
        return await response.json();
      } else {
        return await response.blob();
      }
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateStatus: async params => {
    try {
      const url = api.repeatingInvoices.updateStatus.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getChart: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.repeatingInvoices.chartData}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
