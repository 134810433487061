import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesServicesServices from '../../../services/companies-services.services';

export default function* getKidOcrsSaga() {
  yield takeEvery(types.COMPANIES_SERVICES.GET_KID_OCRS, getKidOcrs);
}

export function* getKidOcrs(action) {
  try {
    const response = yield call(companiesServicesServices.getKidOcrs, action.payload);    
    if (response.success) {
      yield put(actions.getKidOcrsSuccess(response.data));
    } else {
      yield put(actions.getKidOcrsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getKidOcrsFail(error));
  }
}
