import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ReportServices from '../../../services/report.services';

export default function* getCustomerInvoiceReportSaga() {
  yield takeEvery(types.REPORT.GET_CUSTOMER_INVOICE_REPORT_REQUEST, getCustomerInvoiceReport);
}

export function* getCustomerInvoiceReport(action) {
  try {
    const response = yield call(ReportServices.customerInvoice, action.payload);
    if (response.success) {
      yield put(actions.getCustomerInvoiceReportSuccess(response.data));
    } else {
      yield put(actions.getCustomerInvoiceReportFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerInvoiceReportFail(error));
  }
}
