import * as types from '../actionTypes';

export const setFilterInvoiceHistory = payload => ({
  type: types.INVOICE_HISTORY.SET_FILTER_INVOICE_HISTORY,
  payload
});

// LIST
export const fetchInvoiceHistory = payload => {
  return {
    type: types.INVOICE_HISTORY.FETCH_INVOICE_HISTORY_DATA_REQUEST,
    payload
  };
};
export const fetchInvoiceHistorySuccess = payload => ({
  type: types.INVOICE_HISTORY.FETCH_INVOICE_HISTORY_DATA_SUCCESS,
  payload
});
export const fetchInvoiceHistoryFail = payload => ({
  type: types.INVOICE_HISTORY.FETCH_INVOICE_HISTORY_DATA_FAIL,
  payload
});