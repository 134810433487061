import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';

export default {
  get: async params => {
    try {
      const url = api.invoiceConfirm.get.replace(':companies_id', params.companies_id).replace(':id', params.id).replace(':confirm_token', params.confirm_token);      
      const data = await BaseServices.getData({ url});
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  confirm: async params => {
    try {
      const url = api.invoiceConfirm.confirm.replace(':companies_id', params.companies_id).replace(':id', params.id).replace(':confirm_token', params.confirm_token);      
      const data = await BaseServices.postData({ url, data: params });
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
