import * as types from '../actionTypes';

// LIST
export const getOptions = payload => {
  return {
    type: types.OPTIONS.FETCH_OPTIONS_DATA_REQUEST,
    payload
  };
};
export const getOptionsSuccess = payload => ({
  type: types.OPTIONS.FETCH_OPTIONS_DATA_SUCCESS,
  payload
});
export const getOptionsFail = payload => ({
  type: types.OPTIONS.FETCH_OPTIONS_DATA_FAIL,
  payload
});