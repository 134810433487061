import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getAdminInvoicesReportSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_ADMIN_KIDS_REPORT, getAdminKidsReport);
}

export function* getAdminKidsReport(action) {
  try {
    const response = yield call(adminReportServices.kidsReport, action.payload);
    if (response.success) {
      yield put(actions.getAdminKidsReportSuccess(response.data));
    } else {
      yield put(actions.getAdminKidsReportFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getAdminKidsReportFail(error));
  }
}
