import $ from 'jquery';

// click event
export const collapsible = () => {
  $('.nav-collapsible .navbar-toggler').click(() => {
    const event = $('.nav-collapsible .navbar-toggler');
    // Set navigation lock / unlock with radio icon
    if (
      $(event)
        .children()
        .text() === 'radio_button_checked'
    ) {
      $('.col-statistic-mark')
        .removeClass('col-statistic')
        .addClass('col-statistic-nav-unlock');
      $('.col-pdf-view-mark')
        .removeClass('col-pdf-view')
        .addClass('col-pdf-view-nav-unlock');
    } else {
      $('.col-statistic-mark')
        .removeClass('col-statistic-nav-unlock')
        .addClass('col-statistic');
      $('.col-pdf-view-mark')
        .removeClass('col-pdf-view-nav-unlock')
        .addClass('col-pdf-view');
    }
  });
};

const __autoHeight = async () => {
  const statisticHeight = $('.col-statistic-mark').height();
  const pdfHeight = $('.col-pdf-view-mark').height();
  const screenHeight = document.documentElement.clientHeight || window.innerHeight;
  const screenWidth = document.documentElement.clientWidth || window.innerWidth;
  // 1 - onload
  if (statisticHeight > 0 || pdfHeight > 0) {
    if (screenWidth >= 993) {
      // only checking on web
      if (statisticHeight > screenHeight - 64 || pdfHeight > screenHeight - 64) { // 64 = header bar
        $('.col-statistic-mark').css('position', 'fixed');
        $('.col-pdf-view-mark').css({
          position: 'fixed',
          width: 'calc(100% - (50% + 290px))'
        });
      }
    } else {
      $('.col-statistic-mark').removeAttr('style');
      $('.col-pdf-view-mark').removeAttr('style');
    }
  }
};

const __autoWidth = () => {
  const screenWidth = document.documentElement.clientWidth || window.innerWidth;
  if (screenWidth <= 993) {
    // mobile
    $('.col-statistic-mark').removeClass('col-statistic');
    $('.col-table-mark').removeClass('col-table');
    $('.col-pdf-view-mark').removeClass('col-pdf-view');
  } else {
    // desktop
    $('.col-statistic-mark').addClass('col-statistic');
    $('.col-table-mark').addClass('col-table');
    $('.col-pdf-view-mark').addClass('col-pdf-view');
    // auto check nav-lock
    if ($('.nav-collapsible').hasClass('nav-lock')) {
      $('.col-statistic-mark')
        .removeClass('col-statistic-nav-unlock')
        .addClass('col-statistic');
      $('.col-pdf-view-mark')
        .removeClass('col-pdf-view-nav-unlock')
        .addClass('col-pdf-view');
    } else {
      $('.col-statistic-mark')
        .removeClass('col-statistic')
        .addClass('col-statistic-nav-unlock');
      $('.col-pdf-view-mark')
        .removeClass('col-pdf-view')
        .addClass('col-pdf-view-nav-unlock');
    }
  }
};

const _autoFixedColumn = () => {
  __autoHeight();
  __autoWidth();
};

export const handleChangeRoute = () => {
  _autoFixedColumn();
};

export const initOnload = () => {
  // 1 - onload
  window.onload = () => {
    _autoFixedColumn();
  };
  // 3 - onresize
  window.onresize = () => {
    _autoFixedColumn();
  };

  // window onscroll
  window.onscroll = () => {
    const scrollHeight = document.documentElement.scrollTop || window.pageYOffset;
    // add space to table sticky
    if (scrollHeight > 170) {
      const elemt = `
      <tr class="fake-tr">
        <td style="padding: 0">
          <div style="height: 20px; background-color: #eff3f6; margin-left: -10px; width: 110%;" />
        </td>
      </tr>`;
      const fakeTr = $('thead.table-head-sticky > tr.fake-tr');
      if (fakeTr.length === 0) {
        $('thead.table-head-sticky').prepend(elemt);
      }
    } else {
      $('thead.table-head-sticky > tr.fake-tr').remove();
    }
  };
};


export const initDropdown = () => {
  $('.dropdown-trigger').dropdown({
    constrainWidth: false
  });
}

export const initAutocomplete = (data) => {
  $('input.autocomplete').autocomplete({
    data: data,
    onAutocomplete: function (val) {
      return val;
    }
  });
}

export const updateDataAutocomplete = (data) => {
  $('input.autocomplete').autocomplete('updateData', data);
}

export const initSelect = () => {
  $('select').formSelect();
}

export const initTabs = () => {
  $('.tabs').tabs();
}