export const ORDERS = {
  SET_FILTER_ORDERS: 'SET_FILTER_ORDERS',
  RESET_FILTER_ORDERS: 'RESET_FILTER_ORDERS',

  SET_LOADING: 'SET_LOADING',

  GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAIL: 'GET_ORDERS_FAIL',  
};


