import * as types from '../actionTypes';

export const setFilterCustomerInvoices = payload => ({
  type: types.CUSTOMER_INVOICES.SET_FILTER_CUSTOMER_INVOICES,
  payload
});

export const resetFilterCustomerInvoices = payload => ({
  type: types.CUSTOMER_INVOICES.RESET_FILTER_CUSTOMER_INVOICES,
  payload
});

// LIST INVOICES
export const getCustomerInvoices = payload => {
  return {
    type: types.CUSTOMER_INVOICES.GET_CUSTOMER_INVOICES,
    payload
  };
};
export const getCustomerInvoicesSuccess = payload => ({
  type: types.CUSTOMER_INVOICES.GET_CUSTOMER_INVOICES_SUCCESS,
  payload
});
export const getCustomerInvoicesFail = payload => ({
  type: types.CUSTOMER_INVOICES.GET_CUSTOMER_INVOICES_FAIL,
  payload
});