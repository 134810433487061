import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';
import jsonp from '../utils/jsonp';

export default {
  payment: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companiesServices.payment.replace(':companies_id', params.companies_id)}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addPayment: async params => {
    try {
      const url = api.companiesServices.addPayment.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updatePayment: async params => {
    try {
      const { id } = params;
      const url = api.companiesServices.updatePayment.replace(':companies_id', params.companies_id).replace(':id', id);
      const data = await BaseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  checkOrganisation: async (company) => {
    try {
      const orgNumber = company.OrganisationNumber;
      const url = `https://hotell.difi.no/api/jsonp/difi/elma/participants?query=${orgNumber}`;
      return new Promise(resolve => {
        jsonp(url, response => {
          if (response.page) resolve(response);
        });
      });
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  fetchEHF: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.service.ehf}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  buyEHF: async params => {
    try {
      const url = api.service.ehf.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  deleteEHF: async params => {
    try {
      const url = api.service.deleteEhf.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getCompanyEHF: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companiesServices.companyEHF}?${query}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async params => {
    try {
      const url = api.companiesServices.get.replace(':companies_id', params.companies_id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  projectService: async params => {
    try {
      const url = api.companiesServices.projectService.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  debtCollection: async params => {
    try {
      const url = api.companiesServices.debtCollection;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getKidOcr: async params => {
    try {
      const url = api.companiesServices.getKidOcr.replace(':companies_id', params.companies_id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getKidOcrs: async params => {
    try {
      const url = api.companiesServices.getKidOcrs.replace(':companies_id', params.companies_id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  registerOcr: async params => {
    try {
      const url = api.companiesServices.registerOcr.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getOcrStatus: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companiesServices.getOcrStatus.replace(':companies_id', params.companies_id)}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  cancelOcr: async params => {
    try {
      const url = api.companiesServices.cancelOcr.replace(':companies_id', params.companies_id).replace(':bank_account_id', params.bank_account_id);
      const data = await BaseServices.deleteDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  registerOcrExists: async params => {
    try {
      const url = api.companiesServices.registerOcrExists.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  kraviaServices: async params => {
    try {
      const url = api.companiesServices.kraviaServices.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  getKraviaServices: async params => {
    try {
      const url = api.companiesServices.getKraviaServices.replace(':companies_id', params.companies_id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  visaCardServices: async params => {
    try {
      const url = api.companiesServices.visaCardServices.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  sendForCollection: async params => {
    try {
      const url = api.companiesServices.sendForCollection.replace(':companies_id', params.companies_id);
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  services: async companies_id => {
    try {
      const url = `${api.companiesServices.services}?companies_id=${companies_id}`;
      const data = await BaseServices.getDataToken(url, companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
