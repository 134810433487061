import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import offersServices from '../../../services/offers.services';

export default function* getOffersSaga() {
  yield takeEvery(types.OFFERS.GET_OFFERS_REQUEST, getOffers);
}

export function* getOffers(action) {
  try {
    const response = yield call(offersServices.list, action.payload);
    if (response.success) {
      yield put(actions.getOffersSuccess(response.data));
    } else {
      yield put(actions.getOffersFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getOffersFail(error));
  }
}
