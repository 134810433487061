import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';

export default {
  get: async uuid => {
    try {
      const url = api.payments.get.replace(':uuid', uuid);      
      const data = await BaseServices.getData({ url });
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  buy: async params => {
    try {
      const url = api.payments.buy.replace(':uuid', params.uuid);
      const data = await BaseServices.postData({ url, data: params });
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
