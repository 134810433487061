import * as types from '../actionTypes';

export const setFilterCustomerOverviewInvoices = payload => ({
  type: types.CUSTOMER_OVERVIEW.SET_FILTER,
  payload
});
export const resetFilterCustomerOverviewInvoices = payload => ({
  type: types.CUSTOMER_OVERVIEW.RESET_FILTER,
  payload
});

// LIST
export const getCustomerOverviewInvoices = payload => {
  return {
    type: types.CUSTOMER_OVERVIEW.GET_CUSTOMER_OVERVIEW_INVOICES,
    payload
  };
};
export const getCustomerOverviewInvoicesSuccess = payload => ({
  type: types.CUSTOMER_OVERVIEW.GET_CUSTOMER_OVERVIEW_INVOICES_SUCCESS,
  payload
});
export const getCustomerOverviewInvoicesFail = payload => ({
  type: types.CUSTOMER_OVERVIEW.GET_CUSTOMER_OVERVIEW_INVOICES_FAIL,
  payload
});