import * as types from '../actionTypes';

export const setFilterKraviaInvoices = payload => ({
  type: types.KRAVIA_INVOICES.SET_FILTER_KRAVIA_INVOICES,
  payload
});

export const resetFilterKraviaInvoices = payload => ({
  type: types.KRAVIA_INVOICES.RESET_FILTER_KRAVIA_INVOICES,
  payload
});

// LIST
export const getKraviaInvoices = payload => {
  return {
    type: types.KRAVIA_INVOICES.GET_KRAVIA_INVOICES,
    payload
  };
};
export const getKraviaInvoicesSuccess = payload => ({
  type: types.KRAVIA_INVOICES.GET_KRAVIA_INVOICES_SUCCESS,
  payload
});
export const getKraviaInvoicesFail = payload => ({
  type: types.KRAVIA_INVOICES.GET_KRAVIA_INVOICES_FAIL,
  payload
});

// GET
export const getKraviaInvoice = payload => {
  return {
    type: types.KRAVIA_INVOICES.GET_KRAVIA_INVOICE,
    payload
  };
};
export const getKraviaInvoiceSuccess = payload => ({
  type: types.KRAVIA_INVOICES.GET_KRAVIA_INVOICE_SUCCESS,
  payload
});
export const getKraviaInvoiceFail = payload => ({
  type: types.KRAVIA_INVOICES.GET_KRAVIA_INVOICE_FAIL,
  payload
});