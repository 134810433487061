import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = {...resetFilter};

const initialState = {
  loading: false,
  filter,
  invoiceBroadcasts: {
    total: 0,
    rows: []
  }
};

const invoiceBroadcasts = (state = initialState, action) => {
  switch (action.type) {
    // LIST
    case types.INVOICE_BROADCASTS.FETCH_INVOICE_BROADCASTS_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_BROADCASTS.FETCH_INVOICE_BROADCASTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceBroadcasts: action.payload
      };
    case types.INVOICE_BROADCASTS.FETCH_INVOICE_BROADCASTS_DATA_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceBroadcasts;
