export const COMPANIES_SERVICES = {
  SET_LOADING: 'SET_LOADING',
  SET_LOADING_OCR: 'SET_LOADING_OCR',

  GET_PAYMENTS_REQUEST: 'GET_PAYMENTS_REQUEST',
  GET_PAYMENTS_SUCCESS: 'GET_PAYMENTS_SUCCESS',
  GET_PAYMENTS_FAIL: 'GET_PAYMENTS_FAIL',

  GET_COMPANIES_SERVICE_REQUEST: 'GET_COMPANIES_SERVICE_REQUEST',
  GET_COMPANIES_SERVICE_SUCCESS: 'GET_COMPANIES_SERVICE_SUCCESS',
  GET_COMPANIES_SERVICE_FAIL: 'GET_COMPANIES_SERVICE_FAIL',

  GET_KID_OCR: 'GET_KID_OCR',
  GET_KID_OCR_SUCCESS: 'GET_KID_OCR_SUCCESS',
  GET_KID_OCR_FAIL: 'GET_KID_OCR_FAIL',

  GET_KID_OCRS: 'GET_KID_OCRS',
  GET_KID_OCRS_SUCCESS: 'GET_KID_OCRS_SUCCESS',
  GET_KID_OCRS_FAIL: 'GET_KID_OCRS_FAIL',

  GET_KRAVIA_SERVICES: 'GET_KRAVIA_SERVICES',
  GET_KRAVIA_SERVICES_SUCCESS: 'GET_KRAVIA_SERVICES_SUCCESS',
  GET_KRAVIA_SERVICES_FAIL: 'GET_KRAVIA_SERVICES_FAIL',
  
  GET_COMPANIES_SERVICES: 'GET_COMPANIES_SERVICES',
  GET_COMPANIES_SERVICES_SUCCESS: 'GET_COMPANIES_SERVICES_SUCCESS',
  GET_COMPANIES_SERVICES_FAIL: 'GET_COMPANIES_SERVICES_FAIL',
};
