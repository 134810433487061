import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  invoiceDistributionRun: null,
  distributionMethod: null,
};

const invoiceDistributionRuns = (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICE_DISTRIBUTION_RUN.SET_FILTER_INVOICE_DISTRIBUTION_RUN:
      return {
        ...state,
        filter: action.payload
      };

    // LIST
    case types.INVOICE_DISTRIBUTION_RUN.GET_INVOICE_DISTRIBUTION_RUN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DISTRIBUTION_RUN.GET_INVOICE_DISTRIBUTION_RUN_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDistributionRun: action.payload
      };
    case types.INVOICE_DISTRIBUTION_RUN.GET_INVOICE_DISTRIBUTION_RUN_FAIL:
      return {
        ...state,
        loading: false
      };

    // METHOD
    case types.INVOICE_DISTRIBUTION_RUN.GET_DISTRIBUTION_METHOD:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DISTRIBUTION_RUN.GET_DISTRIBUTION_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionMethod: action.payload
      };
    case types.INVOICE_DISTRIBUTION_RUN.GET_DISTRIBUTION_METHOD_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceDistributionRuns;
