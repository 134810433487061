import BaseServices from './base.services';
import {api} from '../constants/api.constant';
import {APIError} from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';

export default {
  get: async params => {
    try {
      let url = api.options.get;
      if (params) {
        const query = httpHelper.serialize(params);
        url = `${api.options.get}?${query}`;
      }
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return {success: false, code: APIError.ConnectFail};
    }
  }
};
