export const INVOICE_DISTRIBUTION = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_INVOICE_DISTRIBUTION: 'SET_FILTER_INVOICE_DISTRIBUTION',
  RESET_FILTER_INVOICE_DISTRIBUTION: 'RESET_FILTER_INVOICE_DISTRIBUTION',

  GET_INVOICE_DISTRIBUTIONS_REQUEST: 'GET_INVOICE_DISTRIBUTIONS_REQUEST',
  GET_INVOICE_DISTRIBUTIONS_SUCCESS: 'GET_INVOICE_DISTRIBUTIONS_SUCCESS',
  GET_INVOICE_DISTRIBUTIONS_FAIL: 'GET_INVOICE_DISTRIBUTIONS_FAIL',

  GET_INVOICE_DISTRIBUTION: 'GET_INVOICE_DISTRIBUTION',
  GET_INVOICE_DISTRIBUTION_SUCCESS: 'GET_INVOICE_DISTRIBUTION_SUCCESS',
  GET_INVOICE_DISTRIBUTION_FAIL: 'GET_INVOICE_DISTRIBUTION_FAIL',

  SET_FILTER_DISTRIBUTION_SENDS: 'SET_FILTER_DISTRIBUTION_SENDS',
  RESET_FILTER_DISTRIBUTION_SENDS: 'RESET_FILTER_DISTRIBUTION_SENDS',

  GET_DISTRIBUTION_SENDS_REQUEST: 'GET_DISTRIBUTION_SENDS_REQUEST',
  GET_DISTRIBUTION_SENDS_SUCCESS: 'GET_DISTRIBUTION_SENDS_SUCCESS',
  GET_DISTRIBUTION_SENDS_FAIL: 'GET_DISTRIBUTION_SENDS_FAIL',
};