import * as types from '../actionTypes';

export const setFilterInvoiceDistributionReceivers = payload => ({
  type: types.INVOICE_DISTRIBUTION_RECEIVERS.SET_FILTER_INVOICE_DISTRIBUTION_RECEIVERS,
  payload
});

// LIST
export const getInvoiceDistributionReceivers = payload => {
  return {
    type: types.INVOICE_DISTRIBUTION_RECEIVERS.GET_INVOICE_DISTRIBUTION_RECEIVERS_REQUEST,
    payload
  };
};
export const getInvoiceDistributionReceiversSuccess = payload => ({
  type: types.INVOICE_DISTRIBUTION_RECEIVERS.GET_INVOICE_DISTRIBUTION_RECEIVERS_SUCCESS,
  payload
});
export const getInvoiceDistributionReceiversFail = payload => ({
  type: types.INVOICE_DISTRIBUTION_RECEIVERS.GET_INVOICE_DISTRIBUTION_RECEIVERS_FAIL,
  payload
});