import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerNotesServices from '../../../services/customer-notes.services';

export default function* getCustomerNotesSaga() {
  yield takeEvery(types.CUSTOMER_NOTES.GET_CUSTOMER_NOTES, getCustomerNotes);
}

export function* getCustomerNotes(action) {
  try {
    const response = yield call(customerNotesServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCustomerNotesSuccess(response.data));
    } else {
      yield put(actions.getCustomerNotesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerNotesFail(error));
  }
}
