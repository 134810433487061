import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import productsServices from '../../../services/products.services';

export default function* getLastProductNoSaga() {
  yield takeEvery(types.PRODUCTS.GET_LAST_PRODUCTS_NO, getLastProductNo);
}

export function* getLastProductNo(action) {
  try {
    const response = yield call(productsServices.getLastProductNo, action.payload);
    if (response.success) {
      yield put(actions.getLastProductNoSuccess(response.data));
    } else {
      yield put(actions.getLastProductNoFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getLastProductNoFail(error));
  }
}
