export const OCR_TRANSACTIONS = {
  SET_FILTER_OCR_TRANSACTIONS: 'SET_FILTER_OCR_TRANSACTIONS',
  RESET_FILTER_OCR_TRANSACTIONS: 'RESET_FILTER_OCR_TRANSACTIONS',

  GET_OCR_TRANSACTIONS: 'GET_OCR_TRANSACTIONS',
  GET_OCR_TRANSACTIONS_SUCCESS: 'GET_OCR_TRANSACTIONS_SUCCESS',
  GET_OCR_TRANSACTIONS_FAIL: 'GET_OCR_TRANSACTIONS_FAIL',

  GET_OCR_TRANSACTIONS_NOT_MATCH: 'GET_OCR_TRANSACTIONS_NOT_MATCH',
  GET_OCR_TRANSACTIONS_NOT_MATCH_SUCCESS: 'GET_OCR_TRANSACTIONS_NOT_MATCH_SUCCESS',
  GET_OCR_TRANSACTIONS_NOT_MATCH_FAIL: 'GET_OCR_TRANSACTIONS_NOT_MATCH_FAIL',

  GET_OCR_TRANSACTION: 'GET_OCR_TRANSACTION',
  GET_OCR_TRANSACTION_SUCCESS: 'GET_OCR_TRANSACTION_SUCCESS',
  GET_OCR_TRANSACTION_FAIL: 'GET_OCR_TRANSACTION_FAIL',
};


