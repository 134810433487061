import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesPaymentPackagesServices from '../../../services/companies-payment-packages.services';

export default function* getCompaniesPaymentPackageSaga() {
  yield takeEvery(types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGE, getCompaniesPaymentPackage);
}

export function* getCompaniesPaymentPackage(action) {
  try {
    const response = yield call(companiesPaymentPackagesServices.get, action.payload);
    if (response.success) {
      yield put(actions.getCompaniesPaymentPackageSuccess(response.data));
    } else {
      yield put(actions.getCompaniesPaymentPackageFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCompaniesPaymentPackageFail(error));
  }
}
