import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceServices from '../../../services/invoices.services';

export default function* getInvoicePreviewSagas() {
  yield takeEvery(types.INVOICES.INVOICE_PREVIEW_REQUEST, getInvoicePreview);
}

export function* getInvoicePreview(action) {
  try {
    const response = yield call(invoiceServices.preview, action.payload);
    if (response) {
      yield put(actions.getInvoicePreviewSuccess(response));
    } else {
      yield put(actions.getInvoicePreviewFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoicePreviewFail(error));
  }
}