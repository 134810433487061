import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import vatSettingsServices from '../../../services/vat-settings.services';

export default function* getVatSettingsSaga() {
  yield takeEvery(types.VAT_SETTINGS.GET_VAT_SETTINGS_REQUEST, getVatSettings);
}

export function* getVatSettings(action) {
  try {
    const response = yield call(vatSettingsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getVatSettingsSuccess(response.data));
    } else {
      yield put(actions.getVatSettingsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getVatSettingsFail(error));
  }
}
