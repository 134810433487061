import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import creditCheckingServices from '../../../services/credit-checking.services';

export default function* creditCheckingRequestSaga() {
  yield takeEvery(types.CREDIT_CHECKING.CREDIT_CHECKING_REQUEST, creditCheckingRequest);
}

export function* creditCheckingRequest(action) {
  try {
    const response = yield call(creditCheckingServices.get, action.payload);
    if (!response.status) {
      yield put(actions.creditCheckingRequestSuccess(response.data));
    } else {
      yield put(actions.creditCheckingRequestFail(response?.message));
    }
  } catch (error) {
    yield put(actions.creditCheckingRequestFail(error));
  }
}
