import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import productsServices from '../../../services/products.services';

export default function* fetchListProductsSaga() {
  yield takeEvery(types.PRODUCTS.FETCH_PRODUCTS_DATA_REQUEST, fetchListProducts);
}

export function* fetchListProducts(action) {
  try {
    const response = yield call(productsServices.list, action.payload);
    if (response.success) {
      yield put(actions.fetchListProductsSuccess(response.data));
    } else {
      yield put(actions.fetchListProductsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.fetchListProductsFail(error));
  }
}
