import * as types from '../actionTypes';

export const setFilterCompaniesInvoiceServicesExcept = payload => ({
  type: types.COMPANIES_INVOICE_SERVICES_EXCEPT.SET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT,
  payload
});

export const resetFilterCompaniesInvoiceServicesExcept = payload => ({
  type: types.COMPANIES_INVOICE_SERVICES_EXCEPT.RESET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT,
  payload
});


export const setCompaniesSelected = payload => ({
  type: types.COMPANIES_INVOICE_SERVICES_EXCEPT.SET_COMPANIES_SELECTED,
  payload
});

// LIST
export const getCompaniesInvoiceServicesExcept = payload => {
  return {
    type: types.COMPANIES_INVOICE_SERVICES_EXCEPT.GET_COMPANIES_INVOICE_SERVICES_EXCEPT,
    payload
  };
};
export const getCompaniesInvoiceServicesExceptSuccess = payload => ({
  type: types.COMPANIES_INVOICE_SERVICES_EXCEPT.GET_COMPANIES_INVOICE_SERVICES_EXCEPT_SUCCESS,
  payload
});
export const getCompaniesInvoiceServicesExceptFail = payload => ({
  type: types.COMPANIES_INVOICE_SERVICES_EXCEPT.GET_COMPANIES_INVOICE_SERVICES_EXCEPT_FAIL,
  payload
});