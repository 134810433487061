import * as types from '../actionTypes';

const resetFilter = {
  companies_id: 0,
  invoice_id: 0,
  product_no: '',
  project_name: '',
  sortBy: 'Id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  projects: {
    total: 0,
    rows: []
  },
  project: null,
  lastProjectNo: null
};

const projects = (state = initialState, action) => {
  switch (action.type) {
    case types.PROJECTS.SET_FILTER_PROJECTS:
      return {
        ...state,
        filter: action.payload
      };
    case types.PROJECTS.RESET_FILTER_PROJECTS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.PROJECTS.FETCH_PROJECTS_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.PROJECTS.FETCH_PROJECTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload
      };
    case types.PROJECTS.FETCH_PROJECTS_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    //GET_LAST_PROJECTS_NO
    case types.PROJECTS.GET_LAST_PROJECTS_NO:
      return {
        ...state,
        loading: true,
      };
    case types.PROJECTS.GET_LAST_PROJECTS_NO_SUCCESS:
      return {
        ...state,
        loading: false,
        lastProjectNo: action.payload
      };
    case types.PROJECTS.GET_LAST_PROJECTS_NO_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default projects;
