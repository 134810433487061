import * as types from '../actionTypes';


export const setFilterAdminInvoices = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_ADMIN_INVOICES,
  payload
});

export const resetFilterAdminInvoices = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_ADMIN_INVOICES,
  payload
});

export const setFilterAdminCreditChecked = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_ADMIN_CREDIT_CHECKED,
  payload
});

export const resetFilterAdminCreditChecked = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_ADMIN_CREDIT_CHECKED,
  payload
});

export const setFilterAdminSentNoKid = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_ADMIN_SENT_NO_KID,
  payload
});

export const resetFilterAdminSentNoKid = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_ADMIN_SENT_NO_KID,
  payload
});

export const setFilterCompanyKidRegistered = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_COMPANY_KID_REGISTERED,
  payload
});

export const resetFilterCompanyKidRegistered = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_COMPANY_KID_REGISTERED,
  payload
});

export const setFilterAdminInvoicesStatus = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_ADMIN_INVOICES_STATUS,
  payload
});

export const resetFilterAdminInvoicesStatus = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_ADMIN_INVOICES_STATUS,
  payload
});

export const setFilterAdminCompaniesInvoicesStatus = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS,
  payload
});

export const resetFilterAdminCompaniesInvoicesStatus = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS,
  payload
});

export const setFilterSendPost = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_SEND_POST,
  payload
});

export const resetSetFilterSendPost = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_SEND_POST,
  payload
});

export const setFilterInvoiceSendPost = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_INVOICE_SEND_POST,
  payload
});

export const resetFilterInvoiceSendPosts = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_INVOICE_SEND_POST,
  payload
});

export const setFilterInvoiceServicesReport = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_INVOICE_SERVICES_REPORT,
  payload
});

export const resetFilterInvoiceServicesReport = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_INVOICE_SERVICES_REPORT,
  payload
});

export const setFilterCompanyInvoiceServicesReport = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_COMPANY_INVOICE_SERVICES_REPORT,
  payload
});

export const resetFilterCompanyInvoiceServicesReport = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_COMPANY_INVOICE_SERVICES_REPORT,
  payload
});

export const setFilterCreditStatistics = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_CREDIT_STATISTICS,
  payload
});

export const resetFilterCreditStatistics = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_CREDIT_STATISTICS,
  payload
});

export const setFilterCreditStatisticsList = payload => ({
  type: types.ADMIN_REPORT.SET_FILTER_CREDIT_STATISTICS_LIST,
  payload
});

export const resetFilterCreditStatisticsList = payload => ({
  type: types.ADMIN_REPORT.RESET_FILTER_CREDIT_STATISTICS_LIST,
  payload
});

// GET INVOICES ADMIN_REPORT
export const getAdminInvoicesReport = payload => {
  return {
    type: types.ADMIN_REPORT.GET_ADMIN_INVOICES_REPORT,
    payload
  };
};
export const getAdminInvoicesReportSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_INVOICES_REPORT_SUCCESS,
  payload
});
export const getAdminInvoicesReportFail = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_INVOICES_REPORT_FAIL,
  payload
});

// GET CREDIT CHECKED
export const getAdminCreditChecked = payload => {
  return {
    type: types.ADMIN_REPORT.GET_ADMIN_CREDIT_CHECKED,
    payload
  };
};
export const getAdminCreditCheckedSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_CREDIT_CHECKED_SUCCESS,
  payload
});
export const getAdminCreditCheckedFail = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_CREDIT_CHECKED_FAIL,
  payload
});

// GET CREDIT CHECKED
export const getAdminSentNoKids = payload => {
  return {
    type: types.ADMIN_REPORT.GET_ADMIN_SENT_NO_KID,
    payload
  };
};
export const getAdminSentNoKidsSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_SENT_NO_KID_SUCCESS,
  payload
});
export const getAdminSentNoKidsFail = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_SENT_NO_KID_FAIL,
  payload
});

// GET INVOICES ADMIN_REPORT
export const getAdminKidsReport = payload => {
  return {
    type: types.ADMIN_REPORT.GET_ADMIN_KIDS_REPORT,
    payload
  };
};
export const getAdminKidsReportSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_KIDS_REPORT_SUCCESS,
  payload
});
export const getAdminKidsReportFail = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_KIDS_REPORT_FAIL,
  payload
});

// GET COMPANY KID REGISTERED
export const getCompanyKidRegistered = payload => {
  return {
    type: types.ADMIN_REPORT.GET_COMPANY_KID_REGISTERED,
    payload
  };
};
export const getCompanyKidRegisteredSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_COMPANY_KID_REGISTERED_SUCCESS,
  payload
});
export const getCompanyKidRegisteredFail = payload => ({
  type: types.ADMIN_REPORT.GET_COMPANY_KID_REGISTERED_FAIL,
  payload
});

// COMPANIES INVOICES STATUS
export const getAdminInvoicesStatus = payload => {
  return {
    type: types.ADMIN_REPORT.GET_ADMIN_INVOICES_STATUS,
    payload
  };
};
export const getAdminInvoicesStatusSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_INVOICES_STATUS_SUCCESS,
  payload
});
export const getAdminInvoicesStatusFail = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_INVOICES_STATUS_FAIL,
  payload
});

// COMPANIES INVOICES STATUS
export const getAdminCompaniesInvoicesStatus = payload => {
  return {
    type: types.ADMIN_REPORT.GET_ADMIN_COMPANIES_INVOICES_STATUS,
    payload
  };
};
export const getAdminCompaniesInvoicesStatusSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_COMPANIES_INVOICES_STATUS_SUCCESS,
  payload
});
export const getAdminCompaniesInvoicesStatusFail = payload => ({
  type: types.ADMIN_REPORT.GET_ADMIN_COMPANIES_INVOICES_STATUS_FAIL,
  payload
});

// GET SEND POST
export const getSendPost = payload => {
  return {
    type: types.ADMIN_REPORT.GET_SEND_POST,
    payload
  };
};
export const getSendPostSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_SEND_POST_SUCCESS,
  payload
});
export const getSendPostFail = payload => ({
  type: types.ADMIN_REPORT.GET_SEND_POST_FAIL,
  payload
});

// GET INVOICE SEND POST
export const getInvoiceSendPosts = payload => {
  return {
    type: types.ADMIN_REPORT.GET_INVOICE_SEND_POST,
    payload
  };
};
export const getInvoiceSendPostsSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_INVOICE_SEND_POST_SUCCESS,
  payload
});
export const getInvoiceSendPostsFail = payload => ({
  type: types.ADMIN_REPORT.GET_INVOICE_SEND_POST_FAIL,
  payload
});


// GET USER INVOICE SERVICES
export const getInvoiceServicesReport = payload => {
  return {
    type: types.ADMIN_REPORT.GET_INVOICE_SERVICES_REPORT,
    payload
  };
};
export const getInvoiceServicesReportSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_INVOICE_SERVICES_REPORT_SUCCESS,
  payload
});
export const getInvoiceServicesReportFail = payload => ({
  type: types.ADMIN_REPORT.GET_INVOICE_SERVICES_REPORT_FAIL,
  payload
});

// GET LIST COMPANY USER INVOICE SERVICE
export const getCompanyInvoiceServicesReport = payload => {
  return {
    type: types.ADMIN_REPORT.GET_COMPANY_INVOICE_SERVICES_REPORT,
    payload
  };
};
export const getCompanyInvoiceServicesReportSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_COMPANY_INVOICE_SERVICES_REPORT_SUCCESS,
  payload
});
export const getCompanyInvoiceServicesReportFail = payload => ({
  type: types.ADMIN_REPORT.GET_COMPANY_INVOICE_SERVICES_REPORT_FAIL,
  payload
});

// GET CREDIT STATISTICS
export const getCreditStatistics = payload => {
  return {
    type: types.ADMIN_REPORT.GET_CREDIT_STATISTICS,
    payload
  };
};
export const getCreditStatisticsSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_CREDIT_STATISTICS_SUCCESS,
  payload
});
export const getCreditStatisticsFail = payload => ({
  type: types.ADMIN_REPORT.GET_CREDIT_STATISTICS_FAIL,
  payload
});

// GET CREDIT STATISTICS LIST
export const getCreditStatisticsList = payload => {
  return {
    type: types.ADMIN_REPORT.GET_CREDIT_STATISTICS_LIST,
    payload
  };
};
export const getCreditStatisticsListSuccess = payload => ({
  type: types.ADMIN_REPORT.GET_CREDIT_STATISTICS_LIST_SUCCESS,
  payload
});
export const getCreditStatisticsListFail = payload => ({
  type: types.ADMIN_REPORT.GET_CREDIT_STATISTICS_LIST_FAIL,
  payload
});