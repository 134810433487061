import * as types from '../actionTypes';

export const setFilterCustomerNotes = payload => ({
  type: types.CUSTOMER_NOTES.SET_FILTER_CUSTOMER_NOTES,
  payload
});

export const resetFilterCustomerNotes = payload => ({
  type: types.CUSTOMER_NOTES.RESET_FILTER_CUSTOMER_NOTES,
  payload
});

// LIST NOTES
export const getCustomerNotes = payload => {
  return {
    type: types.CUSTOMER_NOTES.GET_CUSTOMER_NOTES,
    payload
  };
};
export const getCustomerNotesSuccess = payload => ({
  type: types.CUSTOMER_NOTES.GET_CUSTOMER_NOTES_SUCCESS,
  payload
});
export const getCustomerNotesFail = payload => ({
  type: types.CUSTOMER_NOTES.GET_CUSTOMER_NOTES_FAIL,
  payload
});