import * as types from '../actionTypes';

// GET
export const getReminderSetting = payload => {
  return {
    type: types.REMINDER_SETTINGS.GET_REMINDER_SETTING_REQUEST,
    payload
  };
};
export const getReminderSettingSuccess = payload => ({
  type: types.REMINDER_SETTINGS.GET_REMINDER_SETTING_SUCCESS,
  payload
});
export const getReminderSettingFail = payload => ({
  type: types.REMINDER_SETTINGS.GET_REMINDER_SETTING_FAIL,
  payload
});
