import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companiesUsers.list}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  getCompaniesUsers: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companiesUsers.getCompaniesUsers}?${query}`;
      const data = await BaseServices.getData({ url });
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  get: async (id) => {
    try {
      const url = api.companiesUsers.get.replace(':id', id);
      const data = await BaseServices.getData(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  add: async (params) => {
    try {
      const url = api.companiesUsers.add;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  update: async (id, params) => {
    try {
      const url = api.companiesUsers.update.replace(':id', id);
      const data = await BaseServices.putDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  delete: async (id) => {
    try {
      const url = api.companiesUsers.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  listUsers: async (params) => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.companiesUsers.listUsers}?${query}`;
      const data = await BaseServices.getDataToken(url, params?.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
};
