import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.invoiceSettings.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async companies_id => {
    try {
      const url = api.invoiceSettings.item.replace(':companies_id', companies_id);
      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async params => {
    try {
      const url = api.invoiceSettings.save;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  preview: async params => {
    try {
      const url = api.invoiceSettings.preview.replace(':companies_id', params.companies_id);
      const response = await BaseServices.postDataToken(url, params);
      if (response.status !== 200) {
        return await response.json();
      } else {
        return await response.blob();
      }
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
