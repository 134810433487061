export const INVOICE_DISTRIBUTION_RUN = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_INVOICE_DISTRIBUTION_RUN: 'SET_FILTER_INVOICE_DISTRIBUTION_RUN',
  RESET_FILTER_INVOICE_DISTRIBUTION_RUN: 'RESET_FILTER_INVOICE_DISTRIBUTION_RUN',

  GET_INVOICE_DISTRIBUTION_RUN_REQUEST: 'GET_INVOICE_DISTRIBUTION_RUN_REQUEST',
  GET_INVOICE_DISTRIBUTION_RUN_SUCCESS: 'GET_INVOICE_DISTRIBUTION_RUN_SUCCESS',
  GET_INVOICE_DISTRIBUTION_RUN_FAIL: 'GET_INVOICE_DISTRIBUTION_RUN_FAIL',

  GET_DISTRIBUTION_METHOD: 'GET_DISTRIBUTION_METHOD',
  GET_DISTRIBUTION_METHOD_SUCCESS: 'GET_DISTRIBUTION_METHOD_SUCCESS',
  GET_DISTRIBUTION_METHOD_FAIL: 'GET_DISTRIBUTION_METHOD_FAIL',
};
