import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  get: async params => {
    try {
      const url = api.bankAccount.get.replace(':id', params.Id);
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  list: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.bankAccount.list}?${query}`;
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  create: async params => {
    try {
      const url = api.bankAccount.create;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.bankAccount.update.replace(':id', params.Id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  updateStatus: async params => {
    try {
      const url = api.bankAccount.updateStatus.replace(':id', params.Id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.bankAccount.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getBankBySwiftCode: async (swiftCode) => {
    try {
      const query = httpHelper.serialize(swiftCode);
      const url = `${api.bankAccount.getBankBySwiftCode}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  setDefaultAccountNumber: async params => {
    try {
      const url = api.bankAccount.defaultBank;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getBankByCompany: async (companies_id) => {
    try {
      const query = httpHelper.serialize({ companyId: companies_id });
      const url = `${api.bankAccount.getBankByCompanyId}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
