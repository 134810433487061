import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  getCompanyByUser: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.oryton.getListCompany}?${query}`;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: -1 };
    }
  },
  getInbox: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.oryton.getInbox}?${query}`;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: -1 };
    }
  },
  listUsers: async () => {
    try {
      const url = api.oryton.listUsers;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: -1 };
    }
  },
  renewSubscription: async (url) => {
    try {
      const data = await BaseServices.postDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: -1 };
    }
  },
  addCompany: async params => {
    try {
      const url = api.oryton.addCompany;
      const data = await BaseServices.postOrytonDataTokenForm(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  getCurrentCompany: async companies_id => {
    try {
      const url = api.oryton.getCurrentCompany.replace(':companies_id', companies_id);
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: -1 };
    }
  },
};
