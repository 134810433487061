export const CREDIT_CHECK = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER: 'SET_FILTER',
  RESET_FILTER: 'RESET_FILTER',

  CREDIT_CHECK_SEARCH: 'CREDIT_CHECK_SEARCH',
  CREDIT_CHECK_SEARCH_SUCCESS: 'CREDIT_CHECK_SEARCH_SUCCESS',
  CREDIT_CHECK_SEARCH_FAIL: 'CREDIT_CHECK_SEARCH_FAIL',

  GET_CREDIT_CHECKS: 'GET_CREDIT_CHECKS',
  GET_CREDIT_CHECKS_SUCCESS: 'GET_CREDIT_CHECKS_SUCCESS',
  GET_CREDIT_CHECKS_FAIL: 'GET_CREDIT_CHECKS_FAIL',
};


