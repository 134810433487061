import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  save: async params => {
    try {
      const url = api.company.save;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.voucher.update.replace(':id', params.voucherId);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  uploadLogo: async params => {
    try {
      const url = api.company.uploadLogo;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  removeLogo: async params => {
    try {
      const url = api.company.removeLogo;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async companies_id => {
    try {
      const url = api.company.get.replace(':id', companies_id);
      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getCounter: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.company.getCounter.replace(':id', params?.companies_id)}?${query}`;
      const data = await BaseServices.getDataToken(url, params?.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getTopCustomer: async params => {
    try {
      const { companies_id, ...query } = params;
      const queryStr = httpHelper.serialize(query);
      const url = `${api.company.getTopCustomer.replace(':id', companies_id)}?${queryStr}`;
      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getTopDecayed: async params => {
    try {
      const { companies_id, ...query } = params;
      const queryStr = httpHelper.serialize(query);
      const url = `${api.company.getTopDecayed.replace(':id', companies_id)}?${queryStr}`;
      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getUnpaidOnSales: async params => {
    try {
      const { companies_id, ...query } = params;
      const queryStr = httpHelper.serialize(query);
      const url = `${api.company.getUnpaidOnSales.replace(':id', companies_id)}?${queryStr}`;
      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getOwner: async companies_id => {
    try {
      const url = api.company.getOwner.replace(':id', companies_id);
      const data = await BaseServices.getDataToken(url, companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  list: async params => {
    try {
      const url = api.company.list;
      const data = await BaseServices.getDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  accountingCompany: async params => {
    try {
      const url = api.company.accountingCompany;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  listAll: async params => {
    try {
      const url = api.company.listAll;
      const data = await BaseServices.getDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
