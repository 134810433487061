import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'created',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: {
    invoiceHistory: false,
    create: false,
  },
  filter,
  invoiceHistory: {
    total: 0,
    rows: []
  }
};

const invoiceHistory = (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICE_HISTORY.SET_FILTER_INVOICE_HISTORY:
      return {
        ...state,
        filter: action.payload
      };

    // LIST
    case types.INVOICE_HISTORY.FETCH_INVOICE_HISTORY_DATA_REQUEST:
      return {
        ...state,
        loading: {
          invoiceHistory: true
        }
      };
    case types.INVOICE_HISTORY.FETCH_INVOICE_HISTORY_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          invoiceHistory: false
        },
        invoiceHistory: action.payload
      };
    case types.INVOICE_HISTORY.FETCH_INVOICE_HISTORY_DATA_FAIL:
      return {
        ...state,
        loading: {
          invoiceHistory: false
        }
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceHistory;
