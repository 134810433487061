import * as types from '../actionTypes';

//FILTER
export const setFilterBankAccounts = payload => ({
  type: types.BANK_ACCOUNT.SET_FILTER_BANK_ACCOUNT,
  payload
});

// LIST
export const getBankAccounts = payload => {
  return {
    type: types.BANK_ACCOUNT.FETCH_BANK_ACCOUNT_DATA_REQUEST,
    payload
  };
};
export const getBankAccountsSuccess = payload => ({
  type: types.BANK_ACCOUNT.FETCH_BANK_ACCOUNT_DATA_SUCCESS,
  payload
});
export const getBankAccountsFail = payload => ({
  type: types.BANK_ACCOUNT.FETCH_BANK_ACCOUNT_DATA_FAIL,
  payload
});

// LIST
export const getBankAccountCompany = payload => {
  return {
    type: types.BANK_ACCOUNT.GET_BANK_ACCOUNT_COMPANY_REQUEST,
    payload
  };
};
export const getBankAccountCompanySuccess = payload => ({
  type: types.BANK_ACCOUNT.GET_BANK_ACCOUNT_COMPANY_SUCCESS,
  payload
});
export const getBankAccountCompanyFail = payload => ({
  type: types.BANK_ACCOUNT.GET_BANK_ACCOUNT_COMPANY_FAIL,
  payload
});