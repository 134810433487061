import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.contracts.list}?${query}`;
      const data = await BaseServices.getOrytonDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { isSuccess: false, code: APIError.ConnectFail };
    }
  },
  file: async params => {
    try {
      const url = api.contracts.file;
      const data = await BaseServices.postOrytonDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  addPayment: async params => {
    try {
      const { contract_id, ...rest } = params;
      const url = api.contracts.addPayment.replace(':contract_id', contract_id);
      const data = await BaseServices.postOrytonDataToken(url, rest);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  deletePayment: async params => {
    try {
      const { transaction_id } = params;
      const url = api.contracts.deletePayment.replace(':transaction_id', transaction_id);
      const data = await BaseServices.deleteOrytonDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
