import React from 'react';

class LoadingIcon extends React.Component {
  render() {
    const clsDisplay = this.props.loading ? '' : 'd-none';
    return (
      <div className={`${clsDisplay} pt-5 center-align`}>
        <div className="preloader-wrapper small active">
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingIcon;
