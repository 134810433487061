import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.customerDiscounts.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async params => {
    try {
      const url = api.customerDiscounts.save;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async params => {
    try {
      const url = api.customerDiscounts.delete.replace(':companies_id', params.companies_id).replace(':customer_id', params.customer_id).replace(':product_id', params.product_id);
      const data = await BaseServices.deleteDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
