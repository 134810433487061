import * as types from '../actionTypes';

export const setFilterInvoiceDistributionRun = payload => ({
  type: types.INVOICE_DISTRIBUTION_RUN.SET_FILTER_INVOICE_DISTRIBUTION_RUN,
  payload
});

// LIST
export const getInvoiceDistributionRun = payload => {
  return {
    type: types.INVOICE_DISTRIBUTION_RUN.GET_INVOICE_DISTRIBUTION_RUN_REQUEST,
    payload
  };
};
export const getInvoiceDistributionRunSuccess = payload => ({
  type: types.INVOICE_DISTRIBUTION_RUN.GET_INVOICE_DISTRIBUTION_RUN_SUCCESS,
  payload
});
export const getInvoiceDistributionRunFail = payload => ({
  type: types.INVOICE_DISTRIBUTION_RUN.GET_INVOICE_DISTRIBUTION_RUN_FAIL,
  payload
});

export const getDistributionMethod = payload => {
  return {
    type: types.INVOICE_DISTRIBUTION_RUN.GET_DISTRIBUTION_METHOD,
    payload
  };
};
export const getDistributionMethodSuccess = payload => ({
  type: types.INVOICE_DISTRIBUTION_RUN.GET_DISTRIBUTION_METHOD_SUCCESS,
  payload
});
export const getDistributionMethodFail = payload => ({
  type: types.INVOICE_DISTRIBUTION_RUN.GET_DISTRIBUTION_METHOD_FAIL,
  payload
});