import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

const KeywordFilter = props => {
  const { t } = props;
  return (
    <div className="col s12 m3">
      <div className="input-field">
        <input
          onChange={props.handleSearch}
          type="text"
          name="keyword"
          placeholder={t('search')}
        />
      </div>
    </div>
  );
};

export default connect()(withNamespaces('invoices')(KeywordFilter));
