export const productColumns = [
  'product_no',
  'product_name',
  'types',
  'purchase_price',
  'price',
  'discount',
  'discount_from',
  'discount_to',
  'vat',
  'account_code',
  'price_icl_vat',
]