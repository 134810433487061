import * as types from '../actionTypes';

//FILTER
export const setFilterSalesMan = payload => ({
  type: types.SALES_MAN.SET_FILTER_SALES_MAN,
  payload
});

// LIST
export const getSalesMans = payload => {
  return {
    type: types.SALES_MAN.GET_SALES_MANS,
    payload
  };
};
export const getSalesMansSuccess = payload => ({
  type: types.SALES_MAN.GET_SALES_MANS_SUCCESS,
  payload
});
export const getSalesMansFail = payload => ({
  type: types.SALES_MAN.GET_SALES_MANS_FAIL,
  payload
});
