import * as types from '../actionTypes';

export const setFilterCustomerOrders = payload => ({
  type: types.CUSTOMER_ORDERS.SET_FILTER_CUSTOMER_ORDERS,
  payload
});

export const resetFilterCustomerOrders = payload => ({
  type: types.CUSTOMER_ORDERS.RESET_FILTER_CUSTOMER_ORDERS,
  payload
});

// LIST ORDERS
export const getCustomerOrders = payload => {
  return {
    type: types.CUSTOMER_ORDERS.GET_CUSTOMER_ORDERS,
    payload
  };
};
export const getCustomerOrdersSuccess = payload => ({
  type: types.CUSTOMER_ORDERS.GET_CUSTOMER_ORDERS_SUCCESS,
  payload
});
export const getCustomerOrdersFail = payload => ({
  type: types.CUSTOMER_ORDERS.GET_CUSTOMER_ORDERS_FAIL,
  payload
});