import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerServices from '../../../services/customer.services';

export default function* fetchListCustomerAutocompleteSaga() {
  yield takeEvery(types.CUSTOMER.FETCH_CUSTOMER_AUTOCOMPLETE_DATA_REQUEST, fetchListCustomerAutocomplete);
}

export function* fetchListCustomerAutocomplete(action) {
  try {
    yield put(actions.setAutocompleteLoading(true));
    const response = yield call(customerServices.autocomplete, action.payload);
    if (response.status === 'success') {
      yield put(actions.fetchListCustomerAutocompleteSuccess(response.data));
    } else {
      yield put(actions.fetchListCustomerAutocompleteFail(response?.message));
    }
    yield put(actions.setAutocompleteLoading(false));
  } catch (error) {
    yield put(actions.fetchListCustomerAutocompleteFail(error));
    yield put(actions.setAutocompleteLoading(false));
  }
}