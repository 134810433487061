import BaseServices from './base.services';
import { api } from '../constants/api.constant';

export default {
  get: async (params) => {
    try {
      const url = api.userPermission.get.replace(':companies_id', params.companies_id).replace(':users_id', params.users_id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
  add: async (params) => {
    try {
      const url = api.userPermission.add;
      const data = await BaseServices.postDataToken(url, params);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: -1 };
    }
  },
};
