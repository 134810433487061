import * as types from '../actionTypes';

const resetFilter = {
  companies_id: 0,
  invoice_id: 0,
  product_no: '',
  product_name: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterSales = {
  product_id: 0,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterDiscounts = {
  product_id: 0,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};


const filter = { ...resetFilter };
const filterSales = { ...resetFilterSales };
const filterDiscounts = { ...resetFilterDiscounts };

const initialState = {
  loading: false,
  filter,
  filterSales,
  filterDiscounts,
  productsListData: {
    total: 0,
    rows: []
  },
  productInfo: null,
  lastProductNo: null,
  productDiscounts: {
    total: 0,
    rows: []
  }
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case types.PRODUCTS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
      
    case types.PRODUCTS.SET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: action.payload
      };
    case types.PRODUCTS.RESET_FILTER_PRODUCTS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.PRODUCTS.SET_FILTER_PRODUCT_SALES:
      return {
        ...state,
        filterSales: action.payload
      };
    case types.PRODUCTS.RESET_FILTER_PRODUCT_SALES:
      return {
        ...state,
        filterSales: { ...resetFilterSales }
      };

    case types.PRODUCTS.SET_FILTER_PRODUCT_DISCOUNTS:
      return {
        ...state,
        filterDiscounts: action.payload
      };
    case types.PRODUCTS.RESET_FILTER_PRODUCT_DISCOUNTS:
      return {
        ...state,
        filterDiscounts: { ...resetFilterDiscounts }
      };

    // LIST
    case types.PRODUCTS.FETCH_PRODUCTS_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.PRODUCTS.FETCH_PRODUCTS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        productsListData: action.payload
      };
    case types.PRODUCTS.FETCH_PRODUCTS_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };
    // GET-DETAIL
    case types.PRODUCTS.GET_PRODUCTS_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS.GET_PRODUCTS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        productInfo: action.payload
      };
    case types.PRODUCTS.GET_PRODUCTS_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
      };
    // ADD
    case types.PRODUCTS.ADD_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS.ADD_PRODUCTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.PRODUCTS.ADD_PRODUCTS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };
    // UPDATE
    case types.PRODUCTS.UPDATE_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS.UPDATE_PRODUCTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.PRODUCTS.UPDATE_PRODUCTS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };
    // UPDATE
    case types.PRODUCTS.DELETE_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS.DELETE_PRODUCTS_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.PRODUCTS.DELETE_PRODUCTS_REQUEST_FAIL:
      return {
        ...state,
        loading: false,
      };
    // UPDATE
    case types.PRODUCTS.FETCH_PRODUCT_SALES_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS.FETCH_PRODUCT_SALES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productSalesList: action.payload
      };
    case types.PRODUCTS.FETCH_PRODUCT_SALES_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    // LAST PRODUCT NO
    case types.PRODUCTS.GET_LAST_PRODUCTS_NO:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS.GET_LAST_PRODUCTS_NO_SUCCESS:
      return {
        ...state,
        loading: false,
        lastProductNo: action.payload
      };
    case types.PRODUCTS.GET_LAST_PRODUCTS_NO_FAIL:
      return {
        ...state,
        loading: false,
      };

    // PRODUCT DISCOUNTS
    case types.PRODUCTS.FETCH_PRODUCT_DISCOUNTS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.PRODUCTS.FETCH_PRODUCT_DISCOUNTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productDiscounts: action.payload
      };
    case types.PRODUCTS.FETCH_PRODUCT_DISCOUNTS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default products;
