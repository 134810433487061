import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceBroadcastsServices from '../../../services/invoiceBroadcasts.services';

export default function* fetchInvoiceBroadcastsSaga() {
  yield takeEvery(types.INVOICE_BROADCASTS.FETCH_INVOICE_BROADCASTS_DATA_REQUEST, fetchInvoiceBroadcasts);
}

export function* fetchInvoiceBroadcasts(action) {
  try {
    const response = yield call(invoiceBroadcastsServices.list, action.payload);
    if (response.success) {
      yield put(actions.fetchInvoiceBroadcastsSuccess(response.data));
    } else {
      yield put(actions.fetchInvoiceBroadcastsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.fetchInvoiceBroadcastsFail(error));
  }
}
