import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import productsServices from '../../../services/products.services';

export default function* updateProductsSaga() {
  yield takeEvery(types.PRODUCTS.UPDATE_PRODUCTS_REQUEST, updateProducts);
}

export function* updateProducts(action) {
  try {
    const response = yield call(productsServices.update, action.payload);
    if (response.success) {
      yield put(actions.updateProductsSuccess(response.data));
    } else {
      yield put(actions.updateProductsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.updateProductsFail(error));
  }
}