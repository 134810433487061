import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getInvoiceSendPostsReportSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_INVOICE_SEND_POST, getInvoiceSendPosts);
}

export function* getInvoiceSendPosts(action) {
  try {
    const response = yield call(adminReportServices.invoiceSendPosts, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceSendPostsSuccess(response.data));
    } else {
      yield put(actions.getInvoiceSendPostsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceSendPostsFail(error));
  }
}
