export const CUSTOMER_NOTES = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_CUSTOMER_NOTES: 'SET_FILTER_CUSTOMER_NOTES',
  RESET_FILTER_CUSTOMER_NOTES: 'RESET_FILTER_CUSTOMER_NOTES',

  GET_CUSTOMER_NOTES: 'GET_CUSTOMER_NOTES',
  GET_CUSTOMER_NOTES_SUCCESS: 'GET_CUSTOMER_NOTES_SUCCESS',
  GET_CUSTOMER_NOTES_FAIL: 'GET_CUSTOMER_NOTES_FAIL',
};
