import * as types from '../actionTypes';

export const setFilterImportLog = payload => ({
  type: types.IMPORT_LOG.SET_FILTER_IMPORT_LOG,
  payload
});
export const resetFilterImportLog = payload => ({
  type: types.IMPORT_LOG.RESET_FILTER_IMPORT_LOG,
  payload
});

// LIST
export const getImportLogs = payload => {
  return {
    type: types.IMPORT_LOG.GET_IMPORT_LOGS,
    payload
  };
};
export const getImportLogsSuccess = payload => ({
  type: types.IMPORT_LOG.GET_IMPORT_LOGS_SUCCESS,
  payload
});
export const getImportLogsFail = payload => ({
  type: types.IMPORT_LOG.GET_IMPORT_LOGS_FAIL,
  payload
});