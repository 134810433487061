import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import avtalegiroServices from '../../../services/avtalegiro.services';

export default function* avtalegiroStatisticsListSaga() {
  yield takeEvery(types.AVTALEGIRO.AVTALEGIRO_STATISTICS_LIST, avtalegiroStatisticsList);
}

export function* avtalegiroStatisticsList(action) {
  try {
    const response = yield call(avtalegiroServices.avtalegiroStatisticsList, action.payload);
    if (response.success) {
      yield put(actions.avtalegiroStatisticsListSuccess(response.data));
    } else {
      yield put(actions.avtalegiroStatisticsListFail(response?.message));
    }
  } catch (error) {
    yield put(actions.avtalegiroStatisticsListFail(error));
  }
}
