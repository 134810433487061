export const KRAVIA_TRANSACTIONS = {
  SET_FILTER_KRAVIA_TRANSACTIONS: 'SET_FILTER_KRAVIA_TRANSACTIONS',
  RESET_FILTER_KRAVIA_TRANSACTIONS: 'RESET_FILTER_KRAVIA_TRANSACTIONS',

  GET_KRAVIA_TRANSACTIONS: 'GET_KRAVIA_TRANSACTIONS',
  GET_KRAVIA_TRANSACTIONS_SUCCESS: 'GET_KRAVIA_TRANSACTIONS_SUCCESS',
  GET_KRAVIA_TRANSACTIONS_FAIL: 'GET_KRAVIA_TRANSACTIONS_FAIL',

  GET_KRAVIA_TRANSACTIONS_NOT_MATCH: 'GET_KRAVIA_TRANSACTIONS_NOT_MATCH',
  GET_KRAVIA_TRANSACTIONS_NOT_MATCH_SUCCESS: 'GET_KRAVIA_TRANSACTIONS_NOT_MATCH_SUCCESS',
  GET_KRAVIA_TRANSACTIONS_NOT_MATCH_FAIL: 'GET_KRAVIA_TRANSACTIONS_NOT_MATCH_FAIL',

  GET_KRAVIA_TRANSACTION: 'GET_KRAVIA_TRANSACTION',
  GET_KRAVIA_TRANSACTION_SUCCESS: 'GET_KRAVIA_TRANSACTION_SUCCESS',
  GET_KRAVIA_TRANSACTION_FAIL: 'GET_KRAVIA_TRANSACTION_FAIL',
};


