import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerOverviewServices from '../../../services/customer-overview.services';

export default function* getCustomerOverviewInvoicesSaga() {
  yield takeEvery(types.CUSTOMER_OVERVIEW.GET_CUSTOMER_OVERVIEW_INVOICES, getCustomerOverviewInvoices);
}

export function* getCustomerOverviewInvoices(action) {
  try {
    const response = yield call(customerOverviewServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCustomerOverviewInvoicesSuccess(response.data));
    } else {
      yield put(actions.getCustomerOverviewInvoicesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerOverviewInvoicesFail(error));
  }
}
