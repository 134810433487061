export const COMPANIES_INVOICE_SERVICES_EXCEPT = {
  SET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT: 'SET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT',
  RESET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT: 'RESET_FILTER_COMPANIES_INVOICE_SERVICES_EXCEPT',

  SET_COMPANIES_SELECTED: 'SET_COMPANIES_SELECTED',

  GET_COMPANIES_INVOICE_SERVICES_EXCEPT: 'GET_COMPANIES_INVOICE_SERVICES_EXCEPT',
  GET_COMPANIES_INVOICE_SERVICES_EXCEPT_SUCCESS: 'GET_COMPANIES_INVOICE_SERVICES_EXCEPT_SUCCESS',
  GET_COMPANIES_INVOICE_SERVICES_EXCEPT_FAIL: 'GET_COMPANIES_INVOICE_SERVICES_EXCEPT_FAIL',
};


