export const invoiceVatPercent = {
  1: 0,
  2: 12,
  3: 15,
  4: 25
}

export const invoicePercentVat = {
  0: 1,
  12: 2,
  15: 3,
  25: 4
};