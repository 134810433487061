import * as types from '../actionTypes';

// GET
export const getSubscriptionSettings = payload => {
  return {
    type: types.SUBSCRIPTION_SETTINGS.GET_SUBSCRIPTION_SETTINGS_REQUEST,
    payload
  };
};
export const getSubscriptionSettingsSuccess = payload => ({
  type: types.SUBSCRIPTION_SETTINGS.GET_SUBSCRIPTION_SETTINGS_SUCCESS,
  payload
});
export const getSubscriptionSettingsFail = payload => ({
  type: types.SUBSCRIPTION_SETTINGS.GET_SUBSCRIPTION_SETTINGS_FAIL,
  payload
});
