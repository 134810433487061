import * as types from '../actionTypes';

export const setFilterProducts = payload => ({
  type: types.PRODUCTS.SET_FILTER_PRODUCTS,
  payload
});

export const resetFilterProducts = payload => ({
  type: types.PRODUCTS.RESET_FILTER_PRODUCTS,
  payload
});

export const setFilterProductSales = payload => ({
  type: types.PRODUCTS.SET_FILTER_PRODUCT_SALES,
  payload
});

export const resetFilterProductSales = payload => ({
  type: types.PRODUCTS.RESET_FILTER_PRODUCT_SALES,
  payload
});

export const setFilterProductDiscounts = payload => ({
  type: types.PRODUCTS.SET_FILTER_PRODUCT_DISCOUNTS,
  payload
});

export const resetFilterProductDiscounts = payload => ({
  type: types.PRODUCTS.RESET_FILTER_PRODUCT_DISCOUNTS,
  payload
});

// LIST
export const fetchListProducts = payload => {
  return {
    type: types.PRODUCTS.FETCH_PRODUCTS_DATA_REQUEST,
    payload
  };
};
export const fetchListProductsSuccess = payload => ({
  type: types.PRODUCTS.FETCH_PRODUCTS_DATA_SUCCESS,
  payload
});
export const fetchListProductsFail = payload => ({
  type: types.PRODUCTS.FETCH_PRODUCTS_DATA_FAIL,
  payload
});

// ADD
export const addNewProducts = payload => {
  return {
    type: types.PRODUCTS.ADD_PRODUCTS_REQUEST,
    payload
  };
};
export const addNewProductsSuccess = payload => ({
  type: types.PRODUCTS.ADD_PRODUCTS_REQUEST_SUCCESS,
  payload
});
export const addNewProductsFail = payload => ({
  type: types.PRODUCTS.ADD_PRODUCTS_REQUEST_FAIL,
  payload
});

// UPDATE
export const updateProducts = payload => {
  return {
    type: types.PRODUCTS.UPDATE_PRODUCTS_REQUEST,
    payload
  };
};
export const updateProductsSuccess = payload => ({
  type: types.PRODUCTS.UPDATE_PRODUCTS_REQUEST_SUCCESS,
  payload
});
export const updateProductsFail = payload => ({
  type: types.PRODUCTS.UPDATE_PRODUCTS_REQUEST_FAIL,
  payload
});

// DELETE
export const deleteProducts = payload => {
  return {
    type: types.PRODUCTS.DELETE_PRODUCTS_REQUEST,
    payload
  };
};
export const deleteProductsSuccess = payload => ({
  type: types.PRODUCTS.DELETE_PRODUCTS_REQUEST_SUCCESS,
  payload
});
export const deleteProductsFail = payload => ({
  type: types.PRODUCTS.DELETE_PRODUCTS_REQUEST_FAIL,
  payload
});

// GET-ITEM
export const getProductsDetail = payload => {
  return {
    type: types.PRODUCTS.GET_PRODUCTS_DETAIL,
    payload
  };
};
export const getProductsDetailSuccess = payload => ({
  type: types.PRODUCTS.GET_PRODUCTS_DETAIL_SUCCESS,
  payload
});
export const getProductsDetailFail = payload => ({
  type: types.PRODUCTS.GET_PRODUCTS_DETAIL_FAIL,
  payload
});

// PRODUCT SALES LIST
export const fetchListProductSales = payload => {
  return {
    type: types.PRODUCTS.FETCH_PRODUCT_SALES_LIST_REQUEST,
    payload
  };
};
export const fetchListProductSalesSuccess = payload => ({
  type: types.PRODUCTS.FETCH_PRODUCT_SALES_LIST_SUCCESS,
  payload
});
export const fetchListProductSalesFail = payload => ({
  type: types.PRODUCTS.FETCH_PRODUCT_SALES_LIST_FAIL,
  payload
});

// PRODUCT SALES LIST
export const getLastProductNo = payload => {
  return {
    type: types.PRODUCTS.GET_LAST_PRODUCTS_NO,
    payload
  };
};
export const getLastProductNoSuccess = payload => ({
  type: types.PRODUCTS.GET_LAST_PRODUCTS_NO_SUCCESS,
  payload
});
export const getLastProductNoFail = payload => ({
  type: types.PRODUCTS.GET_LAST_PRODUCTS_NO_FAIL,
  payload
});

// PRODUCT LIST DISCOUNT
export const fetchListProductDiscounts = payload => {
  return {
    type: types.PRODUCTS.FETCH_PRODUCT_DISCOUNTS_LIST_REQUEST,
    payload
  };
};
export const fetchListProductDiscountsSuccess = payload => ({
  type: types.PRODUCTS.FETCH_PRODUCT_DISCOUNTS_LIST_SUCCESS,
  payload
});
export const fetchListProductDiscountsFail = payload => ({
  type: types.PRODUCTS.FETCH_PRODUCT_DISCOUNTS_LIST_FAIL,
  payload
});