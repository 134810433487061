import { Link } from 'react-router-dom';
import React from 'react';
import { loadNewBody } from '../utils/Utils';
import { webConfig } from '../configs/system.configs';

const bodyClass = ['1-colum', 'bg-full-screen-image', 'blank-page'];
const baseUrl = webConfig.webUrl;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    loadNewBody(bodyClass);
  }

  componentWillUnmount() {
    revertBody(bodyClass);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(14, { error, errorInfo });
  }

  goBack = () => {
    window.history.go(-1);
    setInterval(() => {
      window.location.reload();
    }, 200);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="row">
          <div className="col s12">
            <div className="container">
              <div className="section p-0 m-0 height-100vh section-maintenance white">
                <div className="row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <div id="maintenance" className="col s12 center-align">
                    <img src={`${baseUrl}/assets/images/gallery/maintenance.png`} className="responsive-img maintenance-img" alt="" />
                    <h4 className="error-code">This page is under maintenance</h4>
                    <h6 className="mb-2 mt-2">We're sorry for the inconvenience. <br /> Please check back later.</h6>
                    <Link to={'#'} onClick={this.goBack} className="bold" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <icon className="material-icons">arrow_back</icon>
                      Go back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;