export const KRAVIA_INVOICES = {
  SET_LOADING: 'SET_LOADING',
  SET_FILTER_KRAVIA_INVOICES: 'SET_FILTER_KRAVIA_INVOICES',
  RESET_FILTER_KRAVIA_INVOICES: 'RESET_FILTER_KRAVIA_INVOICES',

  GET_KRAVIA_INVOICES: 'GET_KRAVIA_INVOICES',
  GET_KRAVIA_INVOICES_SUCCESS: 'GET_KRAVIA_INVOICES_SUCCESS',
  GET_KRAVIA_INVOICES_FAIL: 'GET_KRAVIA_INVOICES_FAIL',  

  GET_KRAVIA_INVOICE: 'GET_KRAVIA_INVOICE',
  GET_KRAVIA_INVOICE_SUCCESS: 'GET_KRAVIA_INVOICE_SUCCESS',
  GET_KRAVIA_INVOICE_FAIL: 'GET_KRAVIA_INVOICE_FAIL',
};


