import * as types from '../actionTypes';

const resetFilter = {
  noPagination: false,
  pageSize: 3,
  pageNumber: 1,
}

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  bankAccounts: [],
  bankAccountCompany: null,
};

const bankAccounts = (state = initialState, action) => {
  switch (action.type) {
    case types.BANK_ACCOUNT.SET_FILTER_BANK_ACCOUNT:
      return {
        ...state,
        filter: action.payload
      };

    case types.BANK_ACCOUNT.FETCH_BANK_ACCOUNT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BANK_ACCOUNT.FETCH_BANK_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccounts: action.payload
      };
    case types.BANK_ACCOUNT.FETCH_BANK_ACCOUNT_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    case types.BANK_ACCOUNT.GET_BANK_ACCOUNT_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.BANK_ACCOUNT.GET_BANK_ACCOUNT_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccountCompany: action.payload
      };
    case types.BANK_ACCOUNT.GET_BANK_ACCOUNT_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default bankAccounts;
