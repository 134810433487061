export const customerColumns = [
  'customer_no',
  'customer_name',
  'type',
  'organization_number',
  'office_address1',
  'office_address2',
  'office_zip_code',
  'office_district',
  'office_province',
  'countries_id',
  'address1',
  'address2',
  'zip_code',
  'district',
  'delivery_address1',
  'delivery_address2',
  'delivery_zip_code',
  'delivery_district',
  'delivery_province',
  'email',
  'phone_number',
]