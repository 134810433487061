export const CREDIT_CHECKING = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_CREDIT_CHECK: 'SET_FILTER_CREDIT_CHECK',
  RESET_FILTER_CREDIT_CHECK: 'RESET_FILTER_CREDIT_CHECK',

  CREDIT_CHECKING_REQUEST: 'CREDIT_CHECKING_REQUEST',
  CREDIT_CHECKING_SUCCESS: 'CREDIT_CHECKING_SUCCESS',
  CREDIT_CHECKING_FAIL: 'CREDIT_CHECKING_FAIL',

  CREDIT_CHECK_LIST_REQUEST: 'CREDIT_CHECK_LIST_REQUEST',
  CREDIT_CHECK_LIST_SUCCESS: 'CREDIT_CHECK_LIST_SUCCESS',
  CREDIT_CHECK_LIST_FAIL: 'CREDIT_CHECK_LIST_FAIL',
};


