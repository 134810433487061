export const CUSTOMER_INVOICES = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_CUSTOMER_INVOICES: 'SET_FILTER_CUSTOMER_INVOICES',
  RESET_FILTER_CUSTOMER_INVOICES: 'RESET_FILTER_CUSTOMER_INVOICES',

  GET_CUSTOMER_INVOICES: 'GET_CUSTOMER_INVOICES',
  GET_CUSTOMER_INVOICES_SUCCESS: 'GET_CUSTOMER_INVOICES_SUCCESS',
  GET_CUSTOMER_INVOICES_FAIL: 'GET_CUSTOMER_INVOICES_FAIL',
};
