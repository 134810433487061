import bigDecimal from 'js-big-decimal';

export const parseNumber = (number) => {
  if (!number)
    return 0

  const strNumber = number.toString().replace(/\s/g, '').replace(',', '.')
  return !isNaN(+strNumber) ? new bigDecimal(strNumber).getValue() : strNumber
}

export const addNumber = (number1, number2) => {
  const strNumber1 = parseNumber(number1)
  const strNumber2 = parseNumber(number2)
  return bigDecimal.add(strNumber1, strNumber2)
}

export const subNumber = (number1, number2) => {
  const strNumber1 = parseNumber(number1)
  const strNumber2 = parseNumber(number2)
  return bigDecimal.subtract(strNumber1, strNumber2)
}

export const divNumber = (number1, number2) => {
  const strNumber1 = parseNumber(number1)
  const strNumber2 = parseNumber(number2)
  return bigDecimal.divide(strNumber1, strNumber2)
}

export const mulNumber = (number1, number2) => {
  const strNumber1 = parseNumber(number1)
  const strNumber2 = parseNumber(number2)
  return bigDecimal.multiply(strNumber1, strNumber2)
}

export const roundNumber = (number, fixed = 0) => {
  const n = parseNumber(number)
  const bd = new bigDecimal(n)
  return bd.round(fixed, bigDecimal.RoundingModes.HALF_UP).getValue()
}