import React from 'react';

const Selection = ({ options, handleChange, classes, value }) => {
  return (
    <select
      onChange={handleChange}
      value={value}
      className={`browser-default ${classes}`}
    >
      {options.map((item, index) => (
        <option key={index} value={item.value}>
          {item.text}
        </option>
      ))}
    </select>
  );
};

export default Selection;