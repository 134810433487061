import React from 'react';

class Loading extends React.PureComponent {
  render() {
    return (
      <div id='loading-init'>
        <div className="spinner-init loading-init">
          <div className="loading-text">
            <span>Invoice</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;