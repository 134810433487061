import moment from 'moment-timezone';
import * as types from '../actionTypes';

const resetFilterAdminInvoicesReport = {
  keyword: '',
  year: moment().year(),
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterAdminCreditChecked = {
  keyword: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterAdminSentNoKid = {
  keyword: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterAdminKidsReport = {
  keyword: '',
  year: moment().year(),
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterCompanyKidRegistered = {
  keyword: '',
  year: moment().year(),
  month: moment().month() + 1,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterAdminInvoicesStatus = {
  keyword: '',
  hideEmpty: 'hide',
  sortBy: 'companies_id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
}

const resetFilterAdminCompaniesInvoicesStatus = {
  keyword: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
}

const resetFilterSendPost = {
  keyword: '',
  year: moment().year(),
};

const resetFilterInvoiceSendPosts = {
  keyword: '',
  year: moment().year(),
  month: moment().month() + 1,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterInvoiceServicesReport = {
  keyword: '',
  year: moment().year(),
};

const resetFilterCompanyInvoiceServicesReport = {
  keyword: '',
  year: moment().year(),
  month: moment().month() + 1,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const resetFilterCreditStatistics = {
  keyword: '',
  sortBy: 'companies_id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
}

const resetFilterCreditStatisticsList = {
  keyword: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
}

const filterAdminInvoicesReport = { ...resetFilterAdminInvoicesReport };
const filterAdminCreditChecked = { ...resetFilterAdminCreditChecked };
const filterAdminSentNoKid = { ...resetFilterAdminSentNoKid };
const filterAdminKidsReport = { ...resetFilterAdminKidsReport };
const filterCompanyKidRegistered = { ...resetFilterCompanyKidRegistered };
const filterAdminInvoicesStatus = { ...resetFilterAdminInvoicesStatus };
const filterAdminCompaniesInvoicesStatus = { ...resetFilterAdminCompaniesInvoicesStatus };
const filterSendPost = { ...resetFilterSendPost };
const filterInvoiceSendPosts = { ...resetFilterInvoiceSendPosts };
const filterInvoiceServicesReport = { ...resetFilterInvoiceServicesReport };
const filterCompanyInvoiceServicesReport = { ...resetFilterCompanyInvoiceServicesReport };
const filterCreditStatistics = { ...resetFilterCreditStatistics };
const filterCreditStatisticsList = { ...resetFilterCreditStatisticsList };

const initialState = {
  loading: false,
  filterAdminInvoicesReport,
  filterAdminCreditChecked,
  filterAdminSentNoKid,
  filterAdminKidsReport,
  filterCompanyKidRegistered,
  filterAdminInvoicesStatus,
  filterAdminCompaniesInvoicesStatus,
  filterSendPost,
  filterInvoiceSendPosts,
  filterInvoiceServicesReport,
  filterCompanyInvoiceServicesReport,
  filterCreditStatistics,
  filterCreditStatisticsList,
  adminInvoicesReport: [],
  adminCreditChecked: [],
  adminSentNoKids: {
    total: 0,
    rows: [],
  },
  adminKidsReport: [],
  companyKidRegistered: {
    total: 0,
    rows: [],
  },
  adminInvoicesStatus: {
    total: 0,
    rows: [],
  },
  companiesInvoicesStatus: {
    total: 0,
    rows: [],
  },
  sendPost: [],
  invoiceSendPosts: {
    total: 0,
    rows: [],
  },
  invoiceServicesReport: [],
  companyInvoiceServicesReport: {
    total: 0,
    rows: [],
  },
  creditStatistics: {
    total: 0,
    rows: [],
  },
  creditStatisticsList: {
    total: 0,
    rows: [],
  },
};

const adminReport = (state = initialState, action) => {
  switch (action.type) {
    case types.ADMIN_REPORT.SET_FILTER_ADMIN_INVOICES:
      return {
        ...state,
        filterAdminInvoicesReport: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_ADMIN_INVOICES:
      return {
        ...state,
        filterAdminInvoicesReport: { ...resetFilterAdminInvoicesReport }
      };

    case types.ADMIN_REPORT.SET_FILTER_ADMIN_CREDIT_CHECKED:
      return {
        ...state,
        filterAdminCreditChecked: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_ADMIN_CREDIT_CHECKED:
      return {
        ...state,
        filterAdminCreditChecked: { ...resetFilterAdminCreditChecked }
      };

    case types.ADMIN_REPORT.SET_FILTER_ADMIN_SENT_NO_KID:
      return {
        ...state,
        filterAdminSentNoKid: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_ADMIN_SENT_NO_KID:
      return {
        ...state,
        filterAdminCreditChecked: { ...resetFilterAdminSentNoKid }
      };

    case types.ADMIN_REPORT.SET_FILTER_ADMIN_KIDS:
      return {
        ...state,
        filterAdminKidsReport: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_ADMIN_KIDS:
      return {
        ...state,
        filterAdminKidsReport: { ...resetFilterAdminKidsReport }
      };

    case types.ADMIN_REPORT.SET_FILTER_COMPANY_KID_REGISTERED:
      return {
        ...state,
        filterCompanyKidRegistered: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_COMPANY_KID_REGISTERED:
      return {
        ...state,
        filterCompanyKidRegistered: { ...resetFilterCompanyKidRegistered }
      };

    case types.ADMIN_REPORT.SET_FILTER_ADMIN_INVOICES_STATUS:
      return {
        ...state,
        filterAdminInvoicesStatus: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_ADMIN_INVOICES_STATUS:
      return {
        ...state,
        filterAdminInvoicesStatus: { ...resetFilterAdminInvoicesStatus }
      };

    case types.ADMIN_REPORT.SET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS:
      return {
        ...state,
        filterAdminCompaniesInvoicesStatus: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_ADMIN_COMPANIES_INVOICES_STATUS:
      return {
        ...state,
        filterAdminCompaniesInvoicesStatus: { ...resetFilterAdminCompaniesInvoicesStatus }
      };

    case types.ADMIN_REPORT.SET_FILTER_INVOICE_SERVICES_REPORT:
      return {
        ...state,
        filterInvoiceServicesReport: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_INVOICE_SERVICES_REPORT:
      return {
        ...state,
        filterInvoiceServicesReport: { ...resetFilterInvoiceServicesReport }
      };

    case types.ADMIN_REPORT.SET_FILTER_COMPANY_INVOICE_SERVICES_REPORT:
      return {
        ...state,
        filterCompanyInvoiceServicesReport: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_COMPANY_INVOICE_SERVICES_REPORT:
      return {
        ...state,
        filterCompanyInvoiceServicesReport: { ...resetFilterCompanyInvoiceServicesReport }
      };

    case types.ADMIN_REPORT.SET_FILTER_SEND_POST:
      return {
        ...state,
        filterSendPost: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_SEND_POST:
      return {
        ...state,
        filterSendPost: { ...resetFilterSendPost }
      };

    case types.ADMIN_REPORT.SET_FILTER_INVOICE_SEND_POST:
      return {
        ...state,
        filterInvoiceSendPosts: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_INVOICE_SEND_POST:
      return {
        ...state,
        filterInvoiceSendPosts: { ...resetFilterInvoiceSendPosts }
      };

    case types.ADMIN_REPORT.SET_FILTER_CREDIT_STATISTICS:
      return {
        ...state,
        filterCreditStatistics: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_CREDIT_STATISTICS:
      return {
        ...state,
        filterCreditStatistics: { ...resetFilterCreditStatistics }
      };

    case types.ADMIN_REPORT.SET_FILTER_CREDIT_STATISTICS_LIST:
      return {
        ...state,
        filterCreditStatisticsList: action.payload
      };
    case types.ADMIN_REPORT.RESET_FILTER_CREDIT_STATISTICS_LIST:
      return {
        ...state,
        filterCreditStatisticsList: { ...resetFilterCreditStatisticsList }
      };

    // GET INVOICE REPORT
    case types.ADMIN_REPORT.GET_ADMIN_INVOICES_REPORT:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_ADMIN_INVOICES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        adminInvoicesReport: action.payload
      };
    case types.ADMIN_REPORT.GET_ADMIN_INVOICES_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET INVOICE REPORT
    case types.ADMIN_REPORT.GET_ADMIN_CREDIT_CHECKED:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_ADMIN_CREDIT_CHECKED_SUCCESS:
      return {
        ...state,
        loading: false,
        adminCreditChecked: action.payload
      };
    case types.ADMIN_REPORT.GET_ADMIN_CREDIT_CHECKED_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET INVOICE REPORT
    case types.ADMIN_REPORT.GET_ADMIN_SENT_NO_KID:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_ADMIN_SENT_NO_KID_SUCCESS:
      return {
        ...state,
        loading: false,
        adminSentNoKids: action.payload
      };
    case types.ADMIN_REPORT.GET_ADMIN_SENT_NO_KID_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET INVOICE REPORT
    case types.ADMIN_REPORT.GET_ADMIN_KIDS_REPORT:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_ADMIN_KIDS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        adminKidsReport: action.payload
      };
    case types.ADMIN_REPORT.GET_ADMIN_KIDS_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET COMPANY KID REGISTERED
    case types.ADMIN_REPORT.GET_COMPANY_KID_REGISTERED:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_COMPANY_KID_REGISTERED_SUCCESS:
      return {
        ...state,
        loading: false,
        companyKidRegistered: action.payload
      };
    case types.ADMIN_REPORT.GET_COMPANY_KID_REGISTERED_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET ADMIN INVOICES STATUS
    case types.ADMIN_REPORT.GET_ADMIN_INVOICES_STATUS:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_ADMIN_INVOICES_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        adminInvoicesStatus: action.payload
      };
    case types.ADMIN_REPORT.GET_ADMIN_INVOICES_STATUS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET INVOICE REPORT
    case types.ADMIN_REPORT.GET_ADMIN_COMPANIES_INVOICES_STATUS:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_ADMIN_COMPANIES_INVOICES_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesInvoicesStatus: action.payload
      };
    case types.ADMIN_REPORT.GET_ADMIN_COMPANIES_INVOICES_STATUS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET SEND POST
    case types.ADMIN_REPORT.GET_SEND_POST:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_SEND_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        sendPost: action.payload
      };
    case types.ADMIN_REPORT.GET_SEND_POST_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET INVOICE SEND POST
    case types.ADMIN_REPORT.GET_INVOICE_SEND_POST:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_INVOICE_SEND_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceSendPosts: action.payload
      };
    case types.ADMIN_REPORT.GET_INVOICE_SEND_POST_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET SEND POST
    case types.ADMIN_REPORT.GET_INVOICE_SERVICES_REPORT:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_INVOICE_SERVICES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceServicesReport: action.payload
      };
    case types.ADMIN_REPORT.GET_INVOICE_SERVICES_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET INVOICE SEND POST
    case types.ADMIN_REPORT.GET_COMPANY_INVOICE_SERVICES_REPORT:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_COMPANY_INVOICE_SERVICES_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        companyInvoiceServicesReport: action.payload
      };
    case types.ADMIN_REPORT.GET_COMPANY_INVOICE_SERVICES_REPORT_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET CREDIT STATISTICS
    case types.ADMIN_REPORT.GET_CREDIT_STATISTICS:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_CREDIT_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        creditStatistics: action.payload
      };
    case types.ADMIN_REPORT.GET_CREDIT_STATISTICS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET CREDIT STATISTICS LIST
    case types.ADMIN_REPORT.GET_CREDIT_STATISTICS_LIST:
      return {
        ...state,
        loading: true
      };
    case types.ADMIN_REPORT.GET_CREDIT_STATISTICS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        creditStatisticsList: action.payload
      };
    case types.ADMIN_REPORT.GET_CREDIT_STATISTICS_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default adminReport;
