import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerServices from '../../../services/customer.services';

export default function* fetchListCustomerSaga() {
  yield takeEvery(types.CUSTOMER.FETCH_CUSTOMER_DATA_REQUEST, fetchListCustomer);
}

export function* fetchListCustomer(action) {
  try {
    const response = yield call(customerServices.list, action.payload);
    if (response.success) {
      yield put(actions.fetchListCustomerSuccess(response.data));
    } else {
      yield put(actions.fetchListCustomerFail(response?.message));
    }
  } catch (error) {
    yield put(actions.fetchListCustomerFail(error));
  }
}
