import * as types from '../actionTypes';

export const setFilterInvoices = payload => ({
  type: types.INVOICES.SET_FILTER_INVOICES,
  payload
});
export const resetFilterInvoices = payload => ({
  type: types.INVOICES.RESET_FILTER_INVOICES,
  payload
});

// LIST
export const fetchListInvoices = payload => {
  return {
    type: types.INVOICES.FETCH_INVOICES_DATA_REQUEST,
    payload
  };
};
export const fetchListInvoicesSuccess = payload => ({
  type: types.INVOICES.FETCH_INVOICES_DATA_SUCCESS,
  payload
});
export const fetchListInvoicesFail = payload => ({
  type: types.INVOICES.FETCH_INVOICES_DATA_FAIL,
  payload
});

// ADD
export const addNewInvoices = payload => {
  return {
    type: types.INVOICES.ADD_INVOICES_REQUEST,
    payload
  };
};
export const addNewInvoicesSuccess = payload => ({
  type: types.INVOICES.ADD_INVOICES_REQUEST_SUCCESS,
  payload
});
export const addNewInvoicesFail = payload => ({
  type: types.INVOICES.ADD_INVOICES_REQUEST_FAIL,
  payload
});

// UPDATE
export const updateInvoices = payload => {
  return {
    type: types.INVOICES.UPDATE_INVOICES_REQUEST,
    payload
  };
};
export const updateInvoicesSuccess = payload => ({
  type: types.INVOICES.UPDATE_INVOICES_REQUEST_SUCCESS,
  payload
});
export const updateInvoicesFail = payload => ({
  type: types.INVOICES.UPDATE_INVOICES_REQUEST_FAIL,
  payload
});

// DELETE
export const deleteInvoices = payload => {
  return {
    type: types.INVOICES.DELETE_INVOICES_REQUEST,
    payload
  };
};
export const deleteInvoicesSuccess = payload => ({
  type: types.INVOICES.DELETE_INVOICES_REQUEST_SUCCESS,
  payload
});
export const deleteInvoicesFail = payload => ({
  type: types.INVOICES.DELETE_INVOICES_REQUEST_FAIL,
  payload
});

// GET-ITEM
export const getInvoiceDetail = payload => {
  return {
    type: types.INVOICES.GET_INVOICE_DETAIL,
    payload
  };
};
export const getInvoiceDetailSuccess = payload => ({
  type: types.INVOICES.GET_INVOICE_DETAIL_SUCCESS,
  payload
});
export const getInvoiceDetailFail = payload => ({
  type: types.INVOICES.GET_INVOICE_DETAIL_FAIL,
  payload
});

// PREVIEW
export const getInvoicePreview = payload => {
  return {
    type: types.INVOICES.INVOICE_PREVIEW_REQUEST,
    payload
  };
};
export const getInvoicePreviewSuccess = payload => ({
  type: types.INVOICES.INVOICE_PREVIEW_REQUEST_SUCCESS,
  payload
});
export const getInvoicePreviewFail = payload => ({
  type: types.INVOICES.INVOICE_PREVIEW_REQUEST_FAIL,
  payload
});

// CREATE
export const createInvoiceRequest = payload => {
  return {
    type: types.INVOICES.CREATE_INVOICE_REQUEST,
    payload
  };
};
export const createInvoiceRequestSuccess = payload => ({
  type: types.INVOICES.CREATE_INVOICE_REQUEST_SUCCESS,
  payload
});
export const createInvoiceRequestFail = payload => ({
  type: types.INVOICES.CREATE_INVOICE_REQUEST_FAIL,
  payload
});

// SEND INVOICE
export const sendInvoiceRequest = payload => {
  return {
    type: types.INVOICES.SEND_INVOICE_REQUEST,
    payload
  };
};
export const sendInvoiceRequestSuccess = payload => ({
  type: types.INVOICES.SEND_INVOICE_REQUEST_SUCCESS,
  payload
});
export const sendInvoiceRequestFail = payload => ({
  type: types.INVOICES.SEND_INVOICE_REQUEST_FAIL,
  payload
});

// INVOICE OPTIONS
export const getInvoiceOptions = payload => {
  return {
    type: types.INVOICES.GET_INVOICE_OPTIONS,
    payload
  };
};
export const getInvoiceOptionsSuccess = payload => ({
  type: types.INVOICES.GET_INVOICE_OPTIONS_SUCCESS,
  payload
});
export const getInvoiceOptionsFail = payload => ({
  type: types.INVOICES.GET_INVOICE_OPTIONS_FAIL,
  payload
});