import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = {...resetFilter};

const initialState = {
  loading: false,
  filter,
  reminderInvoices: {
    total: 0,
    rows: []
  }
};

const reminderInvoice = (state = initialState, action) => {
  switch (action.type) {
    case types.REMINDER_INVOICE.SET_FILTER_REMINDER_INVOICE:
      return {
        ...state,
        filter: action.payload
      };

// LIST
    case types.REMINDER_INVOICE.FETCH_REMINDER_INVOICE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMINDER_INVOICE.FETCH_REMINDER_INVOICE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        reminderInvoices: action.payload
      };
    case types.REMINDER_INVOICE.FETCH_REMINDER_INVOICE_DATA_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default reminderInvoice;
