import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.customer.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async params => {
    try {
      const url = api.customer.get.replace(':companies_id', params.companies_id).replace(':id', params.id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  autocomplete: async params => {
    try {
      const url = api.oryton.getListCompanyAutocomplete.replace(':keyword', params);
      const data = await BaseServices.getData({ url });
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  add: async params => {
    try {
      const url = api.customer.add;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.customer.update.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getDataBrreg: async keyword => {
    try {
      const url = `${api.dataBrreg.get}${keyword}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  checkEhf: async organization_number => {
    try {
      const url = api.elma.checkEhf.replace(':organization_number', organization_number);
      const data = await BaseServices.getDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async params => {
    try {
      const url = api.customer.delete.replace(':companies_id', params.companies_id).replace(':id', params.id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
