import Config from './server.configs';

export const authorization = 'authorization';
export const TokenSecret = 'fPY4pwhSOo1SakxFDaEd';
export const webConfig = {
  webUrl: Config[process.env.NODE_ENV].web,
  loginUrl: `${Config[process.env.NODE_ENV].ACCOUNT_WEB}/login?redirect-url=${encodeURIComponent(window.location.origin)}`,
  logoutUrl: `${Config[process.env.NODE_ENV].ACCOUNT_WEB}/logout.html?redirect-url=:redirectUrl`,
  addCompany: `${Config[process.env.NODE_ENV].web}/company/add`,
  refreshToken: `${Config[process.env.NODE_ENV].ACCOUNT_API}/api/refresh-token`
};
