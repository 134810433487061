import * as types from '../actionTypes';

const resetCreditChecks = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15,
  status: 1,
  date_option: 2,
  credit_rate: ''
};

const filterCreditChecks = { ...resetCreditChecks };

const initialState = {
  loading: false,
  creditChecking: null,
  filterCreditChecks,
  creditChecks: {
    total: 0,
    rows: []
  }
};

const creditChecking = (state = initialState, action) => {
  switch (action.type) {
    //loading
    case types.CREDIT_CHECKING.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // filter
    case types.CREDIT_CHECKING.SET_FILTER_CREDIT_CHECK:
      return {
        ...state,
        filterCreditChecks: action.payload
      };
    case types.CREDIT_CHECKING.RESET_FILTER_CREDIT_CHECK:
      return {
        ...state,
        filterCreditChecks: { ...resetCreditChecks }
      };

    // list
    case types.CREDIT_CHECKING.CREDIT_CHECK_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREDIT_CHECKING.CREDIT_CHECK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        creditChecks: action.payload
      };
    case types.CREDIT_CHECKING.CREDIT_CHECK_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    // get
    case types.CREDIT_CHECKING.CREDIT_CHECKING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREDIT_CHECKING.CREDIT_CHECKING_SUCCESS:
      return {
        ...state,
        loading: false,
        creditChecking: action.payload
      };
    case types.CREDIT_CHECKING.CREDIT_CHECKING_FAIL:
      return {
        ...state,
        loading: false,
      };


    //DEFAULT
    default:
      return state;
  }
};
export default creditChecking;
