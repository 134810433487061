import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.customerNotes.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async data => {
    try {
      const url = api.customerNotes.save;
      const result = await BaseServices.postDataToken(url, data);
      return await result.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async params => {
    try {
      const url = api.customerNotes.delete.replace(':customer_id', params.customer_id).replace(':id', params.id)
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
