import * as types from '../actionTypes';

const INITIAL_STATE = {
  forwardSuccessData: null
};

const emailForward = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // GET INBOX
    case types.EMAIL_FORWARD.GET_FORWARD_SUCCESS_DATA:
      return {
        ...state,
        forwardSuccessData: action.payload
      };
    case types.EMAIL_FORWARD.SET_FORWARD_SUCCESS_DATA:
      return {
        ...state,
        forwardSuccessData: action.payload
      };
    default:
      return state;
  }
};
export default emailForward;
