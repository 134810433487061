import * as types from '../actionTypes';

const resetFilter = {
  noPagination: false,
  pageSize: 3,
  pageNumber: 1,
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  companiesUsers: [],
  orytonUsers: {
    total: 0,
    rows: []
  },
  userSelected: {}
};

const companiesUsers = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANIES_USERS.SET_FILTER_COMPANIES_USERS:
      return {
        ...state,
        filter: action.payload
      };

    case types.COMPANIES_USERS.SET_USER_SELECTED:
      return {
        ...state,
        userSelected: action.payload
      };

    // LIST
    case types.COMPANIES_USERS.GET_COMPANIES_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.COMPANIES_USERS.GET_COMPANIES_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesUsers: action.payload
      };
    case types.COMPANIES_USERS.GET_COMPANIES_USERS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // ORYTON USERS
    case types.COMPANIES_USERS.GET_ORYTON_USERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.COMPANIES_USERS.GET_ORYTON_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orytonUsers: action.payload
      };
    case types.COMPANIES_USERS.GET_ORYTON_USERS_FAIL:
      return {
        ...state,
        loading: false,
      };




    //DEFAULT
    default:
      return state;
  }
};
export default companiesUsers;
