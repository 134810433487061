import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import importLogServices from '../../../services/import-log.services';

export default function* getImportLogsSaga() {
  yield takeEvery(types.IMPORT_LOG.GET_IMPORT_LOGS, getImportLogs);
}

export function* getImportLogs(action) {
  try {
    const response = yield call(importLogServices.list, action.payload);
    if (response.success) {
      yield put(actions.getImportLogsSuccess(response.data));
    } else {
      yield put(actions.getImportLogsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getImportLogsFail(error));
  }
}
