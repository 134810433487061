import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerContactServices from '../../../services/customer-contact.services';

export default function* getContactPersonOptionsSaga() {
  yield takeEvery(types.CUSTOMER_CONTACT.GET_CONTACT_PERSON_OPTIONS, getContactPersonOptions);
}

export function* getContactPersonOptions(action) {
  try {
    const response = yield call(customerContactServices.contactOptions, action.payload);
    if (response.success) {
      yield put(actions.getContactPersonOptionsSuccess(response.data));
    } else {
      yield put(actions.getContactPersonOptionsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getContactPersonOptionsFail(error));
  }
}
