import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import projectsServices from '../../../services/projects.services';

export default function* getListProjectsSaga() {
  yield takeEvery(types.PROJECTS.FETCH_PROJECTS_DATA_REQUEST, getListProjects);
}

export function* getListProjects(action) {
  try {
    const response = yield call(projectsServices.list, action.payload);
    if (response.success) {
      yield put(actions.getListProjectsSuccess(response.data));
    } else {
      yield put(actions.getListProjectsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getListProjectsFail(error));
  }
}
