import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ordersServices from '../../../services/orders.services';

export default function* getOrdersSaga() {
  yield takeEvery(types.ORDERS.GET_ORDERS_REQUEST, getOrders);
}

export function* getOrders(action) {
  try {
    const response = yield call(ordersServices.list, action.payload);
    if (response.success) {
      yield put(actions.getOrdersSuccess(response.data));
    } else {
      yield put(actions.getOrdersFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getOrdersFail(error));
  }
}
