import * as types from '../actionTypes';

const resetFilter = {
  status: '0',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  contracts: {
    total: 0,
    data: []
  }
};

const contracts = (state = initialState, action) => {
  switch (action.type) {
    case types.CONTRACTS.SET_FILTER_CONTRACTS:
      return {
        ...state,
        filter: action.payload
      };
    case types.CONTRACTS.RESET_FILTER_CONTRACTS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.CONTRACTS.GET_CONTRACTS:
      return {
        ...state,
        loading: true,
      };
    case types.CONTRACTS.GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contracts: action.payload
      };
    case types.CONTRACTS.GET_CONTRACTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default contracts;
