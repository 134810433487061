import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';

export default {
  upload: async params => {
    try {
      const url = api.kraviaDocuments.upload;
      const data = await BaseServices.postDataTokenFormV2(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  preview: async params => {
    try {
      const url = api.kraviaDocuments.preview;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  }
};
