import moment from 'moment-timezone';
import serverConfigs from '../configs/server.configs';

export const languageLocale = {
  1: 'nb',
  2: 'en',
  3: 'vi',
};

const timeHelper = {
  handleChange: value => {
    return value.replace(/[a-zA-Z]/g, '');
  },
  onBlur: value => {
    if (value.length === 8) {
      const d = value.substr(0, 2);
      const m = value.substr(2, 2);
      const y = value.substr(4, 4);
      return `${d}.${m}.${y}`;
    }
    const dirs = (value.match(/\//g) || []).length;
    const dashes = (value.match(/-/g) || []).length;
    if (dirs === 2) value = value.replace(/\//g, '.');
    if (dashes === 2) value = value.replace(/-/g, '.');
    return value;
  },
  format(time, timezone, monthStrings) {
    try {
      const dateTime = new Date(time);
      dateTime.setHours(dateTime.getHours() + timezone);
      return `${dateTime.getDate()} ${monthStrings[dateTime.getMonth() + 1]}`;
    } catch (e) {
      return '';
    }
  },
  getDate(time) {
    const dateTime = new Date(time);
    return `${dateTime.getMonth() < 9
      ? '0' + (dateTime.getMonth() + 1)
      : (dateTime.getMonth() + 1)}/${dateTime.getDate() < 10 ? '0' + dateTime.getDate() : dateTime.getDate()}/${dateTime.getFullYear()}`;
  },
  getMonthDateRange(year, month) {
    // month: 0 = Jan
    const date = new Date(year, month - 1);
    const startDate = moment(date).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(date).endOf('months').format('YYYY-MM-DD');
    // make sure to call toDate() for plain JavaScript date type
    return {
      startDate,
      endDate
    };
  },
  getYearRange(year) {
    // month: 0 = Jan
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;
    // make sure to call toDate() for plain JavaScript date type
    return {
      startDate,
      endDate
    };
  },
  formatTz: (input, format = 'DD.MM.YYYY') => {
    const date = moment(input).tz(moment.tz.guess());
    return date.format(format);
  },
  formatDate(time, type = 'DD.MM.YYYY') {
    return moment(time).format(type);
  },
  formatDateUtc(time, type = 'DD.MM.YYYY') {
    return moment.utc(time).format(type)
  },
  formatDateTime(time, type = 'DD.MM.YYYY hh:mm:ss') {
    return moment(time).format(type);
  },
  formatDB(date, dateFormat, dateDB = 'YYYY-MM-DD') {
    return moment(date, dateFormat).format(dateDB);
  },
  autoFormat(dateInput) {
    const value = dateInput;
    const regexp = /^[0-9]+$/;
    let date = 'Invalid date';
    if (regexp.test(value) && value.length > 0) {
      let day = null;
      let month = null;
      let year = new Date().getFullYear();
      switch (value.length) {
        case 1:
          day = value;
          month = value;
          break;
        case 2:
          day = value.substr(0, 1);
          month = value.substr(1, 2);
          break;
        case 3:
          day = value.substr(0, 2);
          month = value.substr(2, 1);
          break;
        case 4:
          day = value.substr(0, 2);
          month = value.substr(2, 2);
          break;
        case 6:
          day = value.substr(0, 2);
          month = value.substr(2, 2);
          year = value.substr(4, 2);
          break;
        case 8:
          day = value.substr(0, 2);
          month = value.substr(2, 2);
          year = value.substr(6, 2);
          break;
        default:
          year = 20;
          break;
      }
      date = `${day}-${month}-${year}`;
      date = moment(date, 'DD-MM-YY').format('DD.MM.YYYY');
    }
    return date;
  },
  validateDate(date) {
    return moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
  },
  isFutureDate: date => { // date input format = 'YYYY-MM-DD'
    const today = moment().format('YYYY-MM-DD');
    return moment(date, 'YYYY-MM-DD').isAfter(today, 'day');
  },
  today: () => {
    return new Date(moment().format('YYYY/MM/DD'));
  },
  currentWeekRange: () => {
    const today = moment();
    const from = today.startOf('week').format('YYYY/MM/DD');
    const to = today.endOf('week').format('YYYY/MM/DD');
    return {
      from: new Date(from),
      to: new Date(to)
    };
  },
  currentMonthRange: () => {
    const today = moment();
    const start = today.startOf('month').format('YYYY/MM/DD');
    const end = today.endOf('month').format('YYYY/MM/DD');
    return {
      from: new Date(start),
      to: new Date(end)
    };
  },
  currentYearRange: () => {
    const today = moment();
    const from = today.startOf('year').format('YYYY/MM/DD');
    const to = today.endOf('year').format('YYYY/MM/DD');
    return {
      from: new Date(from),
      to: new Date(to)
    };
  },
  monthAgo: () => {
    return new Date(moment().subtract(1, 'months').format('YYYY/MM/DD'));
  },
  formatDatePicker: date => {
    return moment(date).format('YYYY/MM/DD');
  },
  parseNote: (note, date, language) => {
    // const language = localStorage.getItem('lang') || 'gb';
    const newLanguage = languageLocale[language] || languageLocale[2];
    const formatIgnore = ['quarter', 'firstm', 'lastm', 'firstq', 'lastq', 'firsty', 'lasty'];
    let show_results = note?.trim()?.replace(/null/g, '');
    let show_format = 'DD.MM.YYYY';
    const regex = /{([^}]+)}/g;
    let match = [];
    while (match = regex.exec(note)) {
      const variable = match[1].replace(/\s+/g, '');
      const format = variable.split('|');
      const fValue = format[0];
      const reFormat = format.slice(0);
      let result = '';
      if (fValue === 'date') {
        result = moment(date);
        if (newLanguage) {
          result = result.locale(newLanguage);
        }
      } else {
        const dateRegex = /date([+-][0-9]+[dmyw])+/g;
        if (fValue.match(dateRegex)) {
          const regexCal = /(\+|\-)[0-9]+[d|m|y|w]/g;
          if (fValue.match(regexCal)) {
            let match1 = [];
            result = moment(date);
            if (newLanguage) {
              result = result.locale(newLanguage);
            }
            while (match1 = regexCal.exec(fValue)) {
              const cal = match1[0];
              const getNumber = cal.match(/[+-]?\d+(\.\d+)?/);
              const number = parseFloat(getNumber[0]);
              if (cal.includes('d')) {
                result = moment(result).add(number, 'days');
              }
              if (cal.includes('w')) {
                result = moment(result).add(number, 'weeks');
              }
              if (cal.includes('m')) {
                result = timeHelper.subscriptionDate(result, number, 'months');
              }
              if (cal.includes('y')) {
                result = moment(result).add(number, 'years');
              }
            }
          }
        }
      }

      let letterFormat = '';
      if (reFormat.length > 1) {
        reFormat.forEach((item) => {
          if (item === 'yyyymmdd') {
            show_format = 'YYYY.MM.DD';
          }
          if (item === 'ddmmyyyy') {
            show_format = 'DD.MM.YYYY';
          }
          if (item === 'ddmmmyyyy') {
            show_format = 'DD.MMM.YYYY';
          }
          if (item === 'ddmmmmyyyy') {
            show_format = 'DD.MMMM.YYYY';
          }
          if (item === 'year') {
            show_format = 'YYYY';
          }
          if (item === 'month') {
            show_format = 'MM';
          }
          if (item === 'month_name_len3') {
            show_format = 'MMM';
          }
          if (item === 'month_name') {
            show_format = 'MMMM';
          }
          if (item === 'week') {
            show_format = 'W';
          }
          if (item === 'quarter') {
            show_format = 'quarter';
          }
          if (item === 'firstm') {
            show_format = 'firstm';
          }
          if (item === 'lastm') {
            show_format = 'lastm';
          }
          if (item === 'firstq') {
            show_format = 'firstq';
          }
          if (item === 'lastq') {
            show_format = 'lastq';
          }
          if (item === 'firsty') {
            show_format = 'firsty';
          }
          if (item === 'lasty') {
            show_format = 'lasty';
          }
          if (item === 'lower_case') {
            letterFormat = 'lower_case';
          }
          if (item === 'first_upper_case') {
            letterFormat = 'first_upper_case';
          }
          if (item === 'upper_case') {
            letterFormat = 'upper_case';
          }
        });
      }



      if (!formatIgnore.includes(show_format) && result !== '') {
        result = result.format(show_format);
      }
      if (show_format === 'quarter' && result !== '') {
        result = result.quarter();
      }
      if (show_format === 'firstm' && result !== '') {
        result = result.startOf('month').format('DD.MM.YYYY');
      }
      if (show_format === 'lastm' && result !== '') {
        result = result.endOf('month').format('DD.MM.YYYY');
      }
      if (show_format === 'firstq' && result !== '') {
        result = result.startOf('quarter').format('DD.MM.YYYY');
      }
      if (show_format === 'lastq' && result !== '') {
        result = result.endOf('quarter').format('DD.MM.YYYY');
      }
      if (show_format === 'firsty' && result !== '') {
        result = result.startOf('year').format('DD.MM.YYYY');
      }
      if (show_format === 'lasty' && result !== '') {
        result = result.endOf('year').format('DD.MM.YYYY');
      }
      if (letterFormat === 'lower_case') {
        result = result.toLowerCase();
      }
      if (letterFormat === 'first_upper_case') {
        result = result.charAt(0).toUpperCase() + result.slice(1);
      }
      if (letterFormat === 'upper_case') {
        result = result.toUpperCase();
      }
      show_results = show_results.replace(match[0], result);
    }

    return show_results;
  },
  subscriptionDate: (date, number, type = 'months') => {
    const day = moment(date).date();
    const last_day_of_month = moment(date).endOf('month').date();

    let next_date = moment(date).add(number, type)
    if (day === last_day_of_month) {
      next_date = moment(date).add(number, type).endOf('month')
    }
    return next_date;
  },
  timestampToLocal: (date, format = 'DD.MM.YYYY HH:mm') => {
    const timezone = serverConfigs[process.env.NODE_ENV].timezone;
    const offsetTimezone = moment.tz(date, timezone).utcOffset();
    return moment(date).subtract(offsetTimezone, 'm').local().format(format);
  }
};

export default timeHelper;
