export const PRODUCTS = {
  SET_LOADING: 'SET_LOADING',
  
  SET_FILTER_PRODUCTS: 'SET_FILTER_PRODUCTS',
  RESET_FILTER_PRODUCTS: 'RESET_FILTER_PRODUCTS',

  SET_FILTER_PRODUCT_SALES: 'SET_FILTER_PRODUCT_SALES',
  RESET_FILTER_PRODUCT_SALES: 'RESET_FILTER_PRODUCT_SALES',

  SET_FILTER_PRODUCT_DISCOUNTS: 'SET_FILTER_PRODUCT_DISCOUNTS',
  RESET_FILTER_PRODUCT_DISCOUNTS: 'RESET_FILTER_PRODUCT_DISCOUNTS',

  FETCH_PRODUCTS_DATA_REQUEST: 'FETCH_PRODUCTS_DATA_REQUEST',
  FETCH_PRODUCTS_DATA_SUCCESS: 'FETCH_PRODUCTS_DATA_SUCCESS',
  FETCH_PRODUCTS_DATA_FAIL: 'FETCH_PRODUCTS_DATA_FAIL',

  ADD_PRODUCTS_REQUEST: 'ADD_PRODUCTS_REQUEST',
  ADD_PRODUCTS_REQUEST_SUCCESS: 'ADD_PRODUCTS_REQUEST_SUCCESS',
  ADD_PRODUCTS_REQUEST_FAIL: 'ADD_PRODUCTS_REQUEST_FAIL',

  UPDATE_PRODUCTS_REQUEST: 'UPDATE_PRODUCTS_REQUEST',
  UPDATE_PRODUCTS_REQUEST_SUCCESS: 'UPDATE_PRODUCTS_REQUEST_SUCCESS',
  UPDATE_PRODUCTS_REQUEST_FAIL: 'UPDATE_PRODUCTS_REQUEST_FAIL',

  DELETE_PRODUCTS_REQUEST: 'DELETE_PRODUCTS_REQUEST',
  DELETE_PRODUCTS_REQUEST_SUCCESS: 'DELETE_PRODUCTS_REQUEST_SUCCESS',
  DELETE_PRODUCTS_REQUEST_FAIL: 'DELETE_PRODUCTS_REQUEST_FAIL',

  GET_PRODUCTS_DETAIL: 'GET_PRODUCTS_DETAIL',
  GET_PRODUCTS_DETAIL_SUCCESS: 'GET_PRODUCTS_DETAIL_SUCCESS',
  GET_PRODUCTS_DETAIL_FAIL: 'GET_PRODUCTS_DETAIL_FAIL',

  FETCH_PRODUCT_SALES_LIST_REQUEST: 'FETCH_PRODUCT_SALES_LIST_REQUEST',
  FETCH_PRODUCT_SALES_LIST_SUCCESS: 'FETCH_PRODUCT_SALES_LIST_SUCCESS',
  FETCH_PRODUCT_SALES_LIST_FAIL: 'FETCH_PRODUCT_SALES_LIST_FAIL',

  GET_LAST_PRODUCTS_NO: 'GET_LAST_PRODUCTS_NO',
  GET_LAST_PRODUCTS_NO_SUCCESS: 'GET_LAST_PRODUCTS_NO_SUCCESS',
  GET_LAST_PRODUCTS_NO_FAIL: 'GET_LAST_PRODUCTS_NO_FAIL',

  FETCH_PRODUCT_DISCOUNTS_LIST_REQUEST: 'FETCH_PRODUCT_DISCOUNTS_LIST_REQUEST',
  FETCH_PRODUCT_DISCOUNTS_LIST_SUCCESS: 'FETCH_PRODUCT_DISCOUNTS_LIST_SUCCESS',
  FETCH_PRODUCT_DISCOUNTS_LIST_FAIL: 'FETCH_PRODUCT_DISCOUNTS_LIST_FAIL',
};


