import * as types from '../actionTypes';


export const setFilterInvoiceReport = payload => ({
  type: types.REPORT.SET_FILTER_INVOICE_REPORT,
  payload
});

export const resetFilterInvoiceReport = payload => ({
  type: types.REPORT.RESET_FILTER_INVOICE_REPORT,
  payload
});

export const setFilterProductSales = payload => ({
  type: types.REPORT.SET_FILTER_PRODUCT_SALES,
  payload
});

export const resetFilterProductSales = payload => ({
  type: types.REPORT.RESET_FILTER_PRODUCT_SALES,
  payload
});

export const setFilterCustomerInvoice = payload => ({
  type: types.REPORT.SET_FILTER_CUSTOMER_INVOICE,
  payload
});

export const resetFilterCustomerInvoice = payload => ({
  type: types.REPORT.RESET_FILTER_CUSTOMER_INVOICE,
  payload
});

export const setFilterDiscountReport = payload => ({
  type: types.REPORT.SET_FILTER_DISCOUNT_REPORT,
  payload
});

export const resetFilterDiscountReport = payload => ({
  type: types.REPORT.RESET_FILTER_DISCOUNT_REPORT,
  payload
});

export const setFilterAccountReceivableReport = payload => ({
  type: types.REPORT.SET_FILTER_ACCOUNT_RECEIVABLE_REPORT,
  payload
});

export const resetFilterAccountReceivableReport = payload => ({
  type: types.REPORT.RESET_FILTER_ACCOUNT_RECEIVABLE_REPORT,
  payload
});

// SET LOADING
export const setReportLoading = payload => ({
  type: types.REPORT.SET_REPORT_LOADING,
  payload
});

// GET INVOICE REPORT
export const getInvoiceReport = payload => {
  return {
    type: types.REPORT.GET_INVOICE_REPORT_REQUEST,
    payload
  };
};
export const getInvoiceReportSuccess = payload => ({
  type: types.REPORT.GET_INVOICE_REPORT_SUCCESS,
  payload
});
export const getInvoiceReportFail = payload => ({
  type: types.REPORT.GET_INVOICE_REPORT_FAIL,
  payload
});

// GET PRODUCT SALES
export const getProductSalesReport = payload => {
  return {
    type: types.REPORT.GET_PRODUCT_SALES_REPORT_REQUEST,
    payload
  };
};
export const getProductSalesReportSuccess = payload => ({
  type: types.REPORT.GET_PRODUCT_SALES_REPORT_SUCCESS,
  payload
});
export const getProductSalesReportFail = payload => ({
  type: types.REPORT.GET_PRODUCT_SALES_REPORT_FAIL,
  payload
});

// GET CUSTOMER INVOICE
export const getCustomerInvoiceReport = payload => {
  return {
    type: types.REPORT.GET_CUSTOMER_INVOICE_REPORT_REQUEST,
    payload
  };
};
export const getCustomerInvoiceReportSuccess = payload => ({
  type: types.REPORT.GET_CUSTOMER_INVOICE_REPORT_SUCCESS,
  payload
});
export const getCustomerInvoiceReportFail = payload => ({
  type: types.REPORT.GET_CUSTOMER_INVOICE_REPORT_FAIL,
  payload
});

// GET DISCOUNT REPORT
export const getDiscountReport = payload => {
  return {
    type: types.REPORT.GET_DISCOUNT_REPORT_REQUEST,
    payload
  };
};
export const getDiscountReportSuccess = payload => ({
  type: types.REPORT.GET_DISCOUNT_REPORT_SUCCESS,
  payload
});
export const getDiscountReportFail = payload => ({
  type: types.REPORT.GET_DISCOUNT_REPORT_FAIL,
  payload
});

// GET ACCOUNT RECEIVABLE REPORT
export const getAccountReceivableReport = payload => {
  return {
    type: types.REPORT.GET_ACCOUNT_RECEIVABLE_REPORT_REQUEST,
    payload
  };
};
export const getAccountReceivableReportSuccess = payload => ({
  type: types.REPORT.GET_ACCOUNT_RECEIVABLE_REPORT_SUCCESS,
  payload
});
export const getAccountReceivableReportFail = payload => ({
  type: types.REPORT.GET_ACCOUNT_RECEIVABLE_REPORT_FAIL,
  payload
});