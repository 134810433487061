export const REPORT = {
  SET_FILTER_INVOICE_REPORT: 'SET_FILTER_INVOICE_REPORT',
  RESET_FILTER_INVOICE_REPORT: 'RESET_FILTER_INVOICE_REPORT',

  SET_FILTER_PRODUCT_SALES: 'SET_FILTER_PRODUCT_SALES',
  RESET_FILTER_PRODUCT_SALES: 'RESET_FILTER_PRODUCT_SALES',

  SET_FILTER_CUSTOMER_INVOICE: 'SET_FILTER_CUSTOMER_INVOICE',
  RESET_FILTER_CUSTOMER_INVOICE: 'RESET_FILTER_CUSTOMER_INVOICE',

  SET_FILTER_DISCOUNT_REPORT: 'SET_FILTER_DISCOUNT_REPORT',
  RESET_FILTER_DISCOUNT_REPORT: 'RESET_FILTER_DISCOUNT_REPORT',

  SET_FILTER_ACCOUNT_RECEIVABLE_REPORT: 'SET_FILTER_ACCOUNT_RECEIVABLE_REPORT',
  RESET_FILTER_ACCOUNT_RECEIVABLE_REPORT: 'RESET_FILTER_ACCOUNT_RECEIVABLE_REPORT',

  SET_REPORT_LOADING: 'SET_REPORT_LOADING',

  GET_INVOICE_REPORT_REQUEST: 'GET_INVOICE_REPORT_REQUEST',
  GET_INVOICE_REPORT_SUCCESS: 'GET_INVOICE_REPORT_SUCCESS',
  GET_INVOICE_REPORT_FAIL: 'GET_INVOICE_REPORT_FAIL',

  GET_PRODUCT_SALES_REPORT_REQUEST: 'GET_PRODUCT_SALES_REPORT_REQUEST',
  GET_PRODUCT_SALES_REPORT_SUCCESS: 'GET_PRODUCT_SALES_REPORT_SUCCESS',
  GET_PRODUCT_SALES_REPORT_FAIL: 'GET_PRODUCT_SALES_REPORT_FAIL',

  GET_CUSTOMER_INVOICE_REPORT_REQUEST: 'GET_CUSTOMER_INVOICE_REPORT_REQUEST',
  GET_CUSTOMER_INVOICE_REPORT_SUCCESS: 'GET_CUSTOMER_INVOICE_REPORT_SUCCESS',
  GET_CUSTOMER_INVOICE_REPORT_FAIL: 'GET_CUSTOMER_INVOICE_REPORT_FAIL',

  GET_DISCOUNT_REPORT_REQUEST: 'GET_DISCOUNT_REPORT_REQUEST',
  GET_DISCOUNT_REPORT_SUCCESS: 'GET_DISCOUNT_REPORT_SUCCESS',
  GET_DISCOUNT_REPORT_FAIL: 'GET_DISCOUNT_REPORT_FAIL',

  GET_ACCOUNT_RECEIVABLE_REPORT_REQUEST: 'GET_ACCOUNT_RECEIVABLE_REPORT_REQUEST',
  GET_ACCOUNT_RECEIVABLE_REPORT_SUCCESS: 'GET_ACCOUNT_RECEIVABLE_REPORT_SUCCESS',
  GET_ACCOUNT_RECEIVABLE_REPORT_FAIL: 'GET_ACCOUNT_RECEIVABLE_REPORT_FAIL',
};


