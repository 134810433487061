import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import productsServices from '../../../services/products.services';

export default function* addNewProductsSaga() {
  yield takeEvery(types.PRODUCTS.ADD_PRODUCTS_REQUEST, addNewProducts);
}

export function* addNewProducts(action) {
  try {
    const response = yield call(productsServices.add, action.payload);
    if (response.success) {
      yield put(actions.addNewProductsSuccess(response.data));
    } else {
      yield put(actions.addNewProductsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.addNewProductsFail(error));
  }
}