import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerContactServices from '../../../services/customer-contact.services';

export default function* getCustomerContactsSaga() {
  yield takeEvery(types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACTS, getCustomerContacts);
}

export function* getCustomerContacts(action) {
  try {
    const response = yield call(customerContactServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCustomerContactsSuccess(response.data));
    } else {
      yield put(actions.getCustomerContactsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerContactsFail(error));
  }
}
