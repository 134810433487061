import i18n from '../../../../i18n';
import moment from 'moment-timezone';

export default {
  required: value => {    
    return value ? undefined : i18n.t('form:validation.required');
  },
  validMoney: value => {
    const regex1 = /^-?((?:\d+|\d{1,3}(?: \d{3})+)(?:,\d+)?)$/;
    const regex2 = /^-?((?:\d+|\d{1,3}(?: \d{3})+)(?:.\d+)?)$/;
    return (regex1.test(value) === false && regex2.test(value) === false)
      ? i18n.t('form:validation.invalidAmount')
      : undefined;
  },
  validDate: value => {
    return moment(value, 'DD.MM.YYYY', true).isValid() === false
      ? i18n.t('form:validation.invalidDate')
      : undefined;
  },
  email: value => {
    return (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) ? i18n.t('validation.email') : undefined;
  },
  maxLength: (value, length) => {
    return value && value.length >= length ? i18n.t('form:validation.maxLength').replace('{length}', length) : undefined;
  },
  maxValue: value => {
    return value && parseFloat(value) > 100 ? i18n.t('form:validation.maxValue').replace('{max}', '100') : undefined;
  }
};
