import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';

export default {
  creditorCampaign: async params => {
    try {
      const url = api.mandate.creditorCampaign;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },  
};
