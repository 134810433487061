import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerServices from '../../../services/customer.services';

export default function* updateCustomerSaga() {
  yield takeEvery(types.CUSTOMER.UPDATE_CUSTOMER_REQUEST, updateCustomer);
}

export function* updateCustomer(action) {
  try {
    const response = yield call(customerServices.update, action.payload);
    if (response.success) {
      yield put(actions.updateCustomerSuccess(response.data));
    } else {
      yield put(actions.updateCustomerFail(response?.message));
    }
  } catch (error) {
    yield put(actions.updateCustomerFail(error));
  }
}