import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.invoiceDistributionRun.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async id => {
    try {
      const url = api.invoiceDistributionRun.item.replace(':id', id);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getItem: async params => {
    try {
      const url = api.invoiceDistributionRun.getItem.replace(':companies_id', params.companies_id).replace(':distribution_id', params.distribution_id);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.invoiceDistributionRun.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  save: async params => {
    try {
      const url = api.invoiceDistributionRun.save;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  method: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.invoiceDistributionRun.method}?${query}`;
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
