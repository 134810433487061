import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerInvoicesServices from '../../../services/customer-invoices.services';

export default function* getCustomerInvoicesSaga() {
  yield takeEvery(types.CUSTOMER_INVOICES.GET_CUSTOMER_INVOICES, getCustomerInvoices);
}

export function* getCustomerInvoices(action) {
  try {
    const response = yield call(customerInvoicesServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCustomerInvoicesSuccess(response.data));
    } else {
      yield put(actions.getCustomerInvoicesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerInvoicesFail(error));
  }
}
