import * as types from '../actionTypes';

export const setFilterKraviaTransactions = payload => ({
  type: types.KRAVIA_TRANSACTIONS.SET_FILTER_KRAVIA_TRANSACTIONS,
  payload
});

export const resetFilterKraviaTransactions = payload => ({
  type: types.KRAVIA_TRANSACTIONS.RESET_FILTER_KRAVIA_TRANSACTIONS,
  payload
});

// LIST
export const getKraviaTransactions = payload => {
  return {
    type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS,
    payload
  };
};
export const getKraviaTransactionsSuccess = payload => ({
  type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_SUCCESS,
  payload
});
export const getKraviaTransactionsFail = payload => ({
  type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_FAIL,
  payload
});

// LIST NOT MATCH
export const getKraviaTransactionsNotMatch = payload => {
  return {
    type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_NOT_MATCH,
    payload
  };
};
export const getKraviaTransactionsNotMatchSuccess = payload => ({
  type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_NOT_MATCH_SUCCESS,
  payload
});
export const getKraviaTransactionsNotMatchFail = payload => ({
  type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_NOT_MATCH_FAIL,
  payload
});

// GET
export const getKraviaTransaction = payload => {
  return {
    type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTION,
    payload
  };
};
export const getKraviaTransactionSuccess = payload => ({
  type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTION_SUCCESS,
  payload
});
export const getKraviaTransactionFail = payload => ({
  type: types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTION_FAIL,
  payload
});