import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import vatCodeSettingsServices from '../../../services/vat-code-settings.services';

export default function* getVatCodeSettingsSaga() {
  yield takeEvery(types.VAT_CODE_SETTINGS.GET_VAT_CODE_SETTINGS_REQUEST, getVatCodeSettings);
}

export function* getVatCodeSettings(action) {
  try {
    const response = yield call(vatCodeSettingsServices.list, action.payload);
    if (response.success) {
      yield put(actions.getVatCodeSettingsSuccess(response.data));
    } else {
      yield put(actions.getVatCodeSettingsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getVatCodeSettingsFail(error));
  }
}
