import * as types from '../actionTypes';

export const setFilterReminderInvoice = payload => ({
  type: types.REMINDER_INVOICE.SET_FILTER_REMINDER_INVOICE,
  payload
});

// LIST
export const fetchReminderInvoice = payload => {
  return {
    type: types.REMINDER_INVOICE.FETCH_REMINDER_INVOICE_DATA_REQUEST,
    payload
  };
};
export const fetchReminderInvoiceSuccess = payload => ({
  type: types.REMINDER_INVOICE.FETCH_REMINDER_INVOICE_DATA_SUCCESS,
  payload
});
export const fetchReminderInvoiceFail = payload => ({
  type: types.REMINDER_INVOICE.FETCH_REMINDER_INVOICE_DATA_FAIL,
  payload
});