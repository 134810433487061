import * as actionTypes from '../../actionTypes';
import * as actions from '../../actions';

import { call, put, takeEvery } from 'redux-saga/effects';

import companyServices from '../../../services/company.services';

export default function* getOwnerSaga() {
  yield takeEvery(actionTypes.COMPANY.GET_OWNER_REQUEST, getOwner);
}

export function* getOwner(action) {
  try {
    const response = yield call(companyServices.getOwner, action.payload);
    if (response.success) {
      yield put(actions.getOwnerSuccess(response.data));
    } else {
      yield put(actions.getOwnerFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getOwnerFail(error));
  }
}
