import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import optionsServices from '../../../services/options.services';

export default function* getOptionsSaga() {
  yield takeEvery(types.OPTIONS.FETCH_OPTIONS_DATA_REQUEST, getOptions);
}

export function* getOptions(action) {
  try {
    const response = yield call(optionsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getOptionsSuccess(response.data));
    } else {
      yield put(actions.getOptionsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getOptionsFail(error));
  }
}
