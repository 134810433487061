import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import productsServices from '../../../services/products.services';

export default function* getProductsDetailSaga() {
  yield takeEvery(types.PRODUCTS.GET_PRODUCTS_DETAIL, getProductsDetail);
}

export function* getProductsDetail(action) {
  try {
    const response = yield call(productsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getProductsDetailSuccess(response.data));
    } else {
      yield put(actions.getProductsDetailFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getProductsDetailFail(error));
  }
}
