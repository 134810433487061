import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import creditCheckingServices from '../../../services/credit-checking.services';

export default function* creditCheckListRequestSaga() {
  yield takeEvery(types.CREDIT_CHECKING.CREDIT_CHECK_LIST_REQUEST, creditCheckListRequest);
}

export function* creditCheckListRequest(action) {
  try {
    const response = yield call(creditCheckingServices.list, action.payload);
    if (!response.status) {
      yield put(actions.creditCheckListRequestSuccess(response.data));
    } else {
      yield put(actions.creditCheckListRequestFail(response?.message));
    }
  } catch (error) {
    yield put(actions.creditCheckListRequestFail(error));
  }
}
