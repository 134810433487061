import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  invoiceReport: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.report.invoiceReport}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  productSales: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.report.productSales}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  customerInvoice: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.report.customerInvoice}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  discountReport: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.report.discountReport}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  accountReceivableReport: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.report.accountReceivableReport}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  export: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.report.export}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
