import * as types from '../actionTypes';

export const setFilterProjects = payload => ({
  type: types.PROJECTS.SET_FILTER_PROJECTS,
  payload
});

export const resetFilterProjects = payload => ({
  type: types.PROJECTS.RESET_FILTER_PROJECTS,
  payload
});

// LIST
export const getListProjects = payload => {
  return {
    type: types.PROJECTS.FETCH_PROJECTS_DATA_REQUEST,
    payload
  };
};
export const getListProjectsSuccess = payload => ({
  type: types.PROJECTS.FETCH_PROJECTS_DATA_SUCCESS,
  payload
});
export const getListProjectsFail = payload => ({
  type: types.PROJECTS.FETCH_PROJECTS_DATA_FAIL,
  payload
});

// GET LAST PROJECT NO
export const getLastProjectNo = payload => {
  return {
    type: types.PROJECTS.GET_LAST_PROJECTS_NO,
    payload
  };
};
export const getLastProjectNoSuccess = payload => ({
  type: types.PROJECTS.GET_LAST_PROJECTS_NO_SUCCESS,
  payload
});
export const getLastProjectNoFail = payload => ({
  type: types.PROJECTS.GET_LAST_PROJECTS_NO_FAIL,
  payload
});