import * as types from '../actionTypes';

// LIST
export const getCompaniesService = payload => {
  return {
    type: types.COMPANIES_SERVICES.GET_COMPANIES_SERVICE_REQUEST,
    payload
  };
};
export const getCompaniesServiceSuccess = payload => ({
  type: types.COMPANIES_SERVICES.GET_COMPANIES_SERVICE_SUCCESS,
  payload
});
export const getCompaniesServiceFail = payload => ({
  type: types.COMPANIES_SERVICES.GET_COMPANIES_SERVICE_FAIL,
  payload
});

// LIST
export const getPayments = payload => {
  return {
    type: types.COMPANIES_SERVICES.GET_PAYMENTS_REQUEST,
    payload
  };
};
export const getPaymentsSuccess = payload => ({
  type: types.COMPANIES_SERVICES.GET_PAYMENTS_SUCCESS,
  payload
});
export const getPaymentsFail = payload => ({
  type: types.COMPANIES_SERVICES.GET_PAYMENTS_FAIL,
  payload
});

// KID OCR
export const getKidOcr = payload => {
  return {
    type: types.COMPANIES_SERVICES.GET_KID_OCR,
    payload
  };
};
export const getKidOcrSuccess = payload => ({
  type: types.COMPANIES_SERVICES.GET_KID_OCR_SUCCESS,
  payload
});
export const getKidOcrFail = payload => ({
  type: types.COMPANIES_SERVICES.GET_KID_OCR_FAIL,
  payload
});

// KID OCR
export const getKidOcrs = payload => {
  return {
    type: types.COMPANIES_SERVICES.GET_KID_OCRS,
    payload
  };
};
export const getKidOcrsSuccess = payload => ({
  type: types.COMPANIES_SERVICES.GET_KID_OCRS_SUCCESS,
  payload
});
export const getKidOcrsFail = payload => ({
  type: types.COMPANIES_SERVICES.GET_KID_OCRS_FAIL,
  payload
});

// KRAVIA
export const getKraviaServices = payload => {
  return {
    type: types.COMPANIES_SERVICES.GET_KRAVIA_SERVICES,
    payload
  };
};
export const getKraviaServicesSuccess = payload => ({
  type: types.COMPANIES_SERVICES.GET_KRAVIA_SERVICES_SUCCESS,
  payload
});
export const getKraviaServicesFail = payload => ({
  type: types.COMPANIES_SERVICES.GET_KRAVIA_SERVICES_FAIL,
  payload
});

// SERVICES
export const getCompaniesServices = payload => {
  return {
    type: types.COMPANIES_SERVICES.GET_COMPANIES_SERVICES,
    payload
  };
};
export const getCompaniesServicesSuccess = payload => ({
  type: types.COMPANIES_SERVICES.GET_COMPANIES_SERVICES_SUCCESS,
  payload
});
export const getCompaniesServicesFail = payload => ({
  type: types.COMPANIES_SERVICES.GET_COMPANIES_SERVICES_FAIL,
  payload
});