export const COMPANIES_PAYMENT_PACKAGES = {
  SET_LOADING: 'SET_LOADING',

  GET_COMPANIES_PAYMENT_PACKAGES: 'GET_COMPANIES_PAYMENT_PACKAGES',
  GET_COMPANIES_PAYMENT_PACKAGES_SUCCESS: 'GET_COMPANIES_PAYMENT_PACKAGES_SUCCESS',
  GET_COMPANIES_PAYMENT_PACKAGES_FAIL: 'GET_COMPANIES_PAYMENT_PACKAGES_FAIL',

  GET_COMPANIES_PAYMENT_PACKAGE: 'GET_COMPANIES_PAYMENT_PACKAGE',
  GET_COMPANIES_PAYMENT_PACKAGE_SUCCESS: 'GET_COMPANIES_PAYMENT_PACKAGE_SUCCESS',
  GET_COMPANIES_PAYMENT_PACKAGE_FAIL: 'GET_COMPANIES_PAYMENT_PACKAGE_FAIL',

  GET_PAYMENT_CREDIT_CHECK: 'GET_PAYMENT_CREDIT_CHECK',
  GET_PAYMENT_CREDIT_CHECK_SUCCESS: 'GET_PAYMENT_CREDIT_CHECK_SUCCESS',
  GET_PAYMENT_CREDIT_CHECK_FAIL: 'GET_PAYMENT_CREDIT_CHECK_FAIL',
};


