import * as types from '../actionTypes';

export const setFilterCustomer = payload => ({
  type: types.CUSTOMER.SET_FILTER_CUSTOMER,
  payload
});

export const resetFilterCustomer = payload => ({
  type: types.CUSTOMER.RESET_FILTER_CUSTOMER,
  payload
});


// LIST
export const fetchListCustomer = payload => {
  return {
    type: types.CUSTOMER.FETCH_CUSTOMER_DATA_REQUEST,
    payload
  };
};
export const fetchListCustomerSuccess = payload => ({
  type: types.CUSTOMER.FETCH_CUSTOMER_DATA_SUCCESS,
  payload
});
export const fetchListCustomerFail = payload => ({
  type: types.CUSTOMER.FETCH_CUSTOMER_DATA_FAIL,
  payload
});

export const setAutocompleteLoading = payload => ({
  type: types.CUSTOMER.SET_AUTOCOMPLETE_LOADING,
  payload,
});

// LIST AUTOCOMPLETE
export const fetchListCustomerAutocomplete = payload => {
  return {
    type: types.CUSTOMER.FETCH_CUSTOMER_AUTOCOMPLETE_DATA_REQUEST,
    payload
  };
};
export const fetchListCustomerAutocompleteSuccess = payload => ({
  type: types.CUSTOMER.FETCH_CUSTOMER_AUTOCOMPLETE_DATA_SUCCESS,
  payload
});
export const fetchListCustomerAutocompleteFail = payload => ({
  type: types.CUSTOMER.FETCH_CUSTOMER_AUTOCOMPLETE_DATA_FAIL,
  payload
});

// ADD
export const addNewCustomer = payload => {
  return {
    type: types.CUSTOMER.ADD_CUSTOMER_REQUEST,
    payload
  };
};
export const addNewCustomerSuccess = payload => ({
  type: types.CUSTOMER.ADD_CUSTOMER_REQUEST_SUCCESS,
  payload
});
export const addNewCustomerFail = payload => ({
  type: types.CUSTOMER.ADD_CUSTOMER_REQUEST_FAIL,
  payload
});

// UPDATE
export const updateCustomer = payload => {
  return {
    type: types.CUSTOMER.UPDATE_CUSTOMER_REQUEST,
    payload
  };
};
export const updateCustomerSuccess = payload => ({
  type: types.CUSTOMER.UPDATE_CUSTOMER_REQUEST_SUCCESS,
  payload
});
export const updateCustomerFail = payload => ({
  type: types.CUSTOMER.UPDATE_CUSTOMER_REQUEST_FAIL,
  payload
});

// GET-ITEM
export const getCustomerDetail = payload => {
  return {
    type: types.CUSTOMER.GET_CUSTOMER_DETAIL,
    payload
  };
};
export const getCustomerDetailSuccess = payload => ({
  type: types.CUSTOMER.GET_CUSTOMER_DETAIL_SUCCESS,
  payload
});
export const getCustomerDetailFail = payload => ({
  type: types.CUSTOMER.GET_CUSTOMER_DETAIL_FAIL,
  payload
});

// GET-DATA-BRREG
export const getDataBrreg = payload => {
  return {
    type: types.CUSTOMER.GET_DATA_BRREG,
    payload
  };
};
export const getDataBrregSuccess = payload => ({
  type: types.CUSTOMER.GET_DATA_BRREG_SUCCESS,
  payload
});
export const getDataBrregFail = payload => ({
  type: types.CUSTOMER.GET_DATA_BRREG_FAIL,
  payload
});