import * as types from '../actionTypes';

export const setFilterCompaniesSuspendInvoices = payload => ({
  type: types.COMPANIES_SUSPEND.SET_FILTER_COMPANIES_SUSPEND_INVOICES,
  payload
});

export const resetFilterCompaniesSuspendInvoices = payload => ({
  type: types.COMPANIES_SUSPEND.RESET_FILTER_COMPANIES_SUSPEND_INVOICES,
  payload
});


// LIST
export const getCompaniesSuspendInvoices = payload => {
  return {
    type: types.COMPANIES_SUSPEND.GET_COMPANIES_SUSPEND_INVOICES,
    payload
  };
};
export const getCompaniesSuspendInvoicesSuccess = payload => ({
  type: types.COMPANIES_SUSPEND.GET_COMPANIES_SUSPEND_INVOICES_SUCCESS,
  payload
});
export const getCompaniesSuspendInvoicesFail = payload => ({
  type: types.COMPANIES_SUSPEND.GET_COMPANIES_SUSPEND_INVOICES_FAIL,
  payload
});