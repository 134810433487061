import React, { Component } from 'react';
import actions, { getCompaniesSuspendInvoices, resetFilterCompaniesSuspendInvoices, setFilterCompaniesSuspendInvoices } from '../../../store/actions';

import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import pages from '../../../constants/pages.constants';
import KeywordFilter from './components/KeywordFilter';
import './InvoicesList.css';
import InvoicesListTable from './InvoicesListTable';

class InvoicesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModalSendInvoice: false,
      openModalAttachFiles: false,
    };
  }

  componentDidMount = async () => {
    const { t, dispatch, match } = this.props;
    dispatch(actions.setBreadcrumb({
      title: t('breadcrumb'),
      page: pages.InvoicesList
    }));

    this.getData();
  }

  componentDidUpdate = async (prevProps) => {
    const { match } = this.props;
    if (prevProps.match.params.companies_id !== match.params.companies_id) {
      this.getData();
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetFilterCompaniesSuspendInvoices());
  }

  getData = async () => {
    const { filterInvoices, dispatch, match } = this.props;
    let params = Object.assign({}, filterInvoices);
    params.companies_id = match.params.companies_id;
    params.type = 'invoice';
    params.status = this.state.status;
    await dispatch(setFilterCompaniesSuspendInvoices(params));
    await dispatch(getCompaniesSuspendInvoices(params));
  }

  changeSearch = async (event) => {
    const { filterInvoices, dispatch, match } = this.props;
    let params = Object.assign({}, filterInvoices);
    params.companies_id = match.params.companies_id;
    params.type = 'invoice';
    params.status = this.state.status;
    params[event.target.name] = event.target.value;
    params.pageNumber = 1;
    params.pageSize = 15;
    
    await dispatch(setFilterCompaniesSuspendInvoices(params));
    await dispatch(getCompaniesSuspendInvoices(params));
  }

  rebuildRouter = router => {
    const { match } = this.props;
    if (match.params.company !== 'company') {
      return router.replace(':company', match.params.company);
    }
    return router;
  }

  render() {
    const { t, invoices } = this.props;
    const company = invoices.company;
    const is_suspend = invoices.is_suspend;

    return (
      <div className="row">
        <div className="col s12">
          <div className="container">
            <div className="section">
              {/*stats list*/}
              <div className="row">
                <div className="col s12 center-align">
                  <p style={{ fontSize: '20px', fontWeight: 'bold' }}>{company?.CompanyName}</p>
                </div>
              </div>

              {is_suspend === true &&
                <div className="row">
                  <div className="col s12">
                    <div className="card">
                      <div className="card-title pad-10">
                        <div className="row">
                          <div className="col s6 padding-1">{t('invoice_unpaid_information')}</div>
                        </div>
                      </div>
                      <div className="card-content">
                        <div className="row mb-2">
                          <KeywordFilter handleSearch={this.changeSearch} />
                        </div>
                        <InvoicesListTable getData={this.getData} />
                      </div>
                    </div>
                  </div>
                </div>
              }

              {is_suspend === false &&
                <div className="row mb-2">
                  <div className="col s12 center-align">
                    <p className="blue-text bold" style={{ fontSize: '20px' }}>This company is not suspended</p>
                  </div>
                </div>
              }

              {/*end list*/}
            </div>
            <div className="content-overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.companiesSuspend.loading;
  const filterInvoices = state.companiesSuspend.filterInvoices;
  const invoices = state.companiesSuspend.invoices;
  return {
    loading,
    filterInvoices,
    invoices,
  };
};
export default withRouter(connect(mapStateToProps)(withNamespaces('invoices')(InvoicesList)));
