import * as types from '../actionTypes';

export const setFilterOcrTransactions = payload => ({
  type: types.OCR_TRANSACTIONS.SET_FILTER_OCR_TRANSACTIONS,
  payload
});

export const resetFilterOcrTransactions = payload => ({
  type: types.OCR_TRANSACTIONS.RESET_FILTER_OCR_TRANSACTIONS,
  payload
});

// LIST
export const getOcrTransactions = payload => {
  return {
    type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS,
    payload
  };
};
export const getOcrTransactionsSuccess = payload => ({
  type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_SUCCESS,
  payload
});
export const getOcrTransactionsFail = payload => ({
  type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_FAIL,
  payload
});

// LIST NOT MATCH
export const getOcrTransactionsNotMatch = payload => {
  return {
    type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_NOT_MATCH,
    payload
  };
};
export const getOcrTransactionsNotMatchSuccess = payload => ({
  type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_NOT_MATCH_SUCCESS,
  payload
});
export const getOcrTransactionsNotMatchFail = payload => ({
  type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_NOT_MATCH_FAIL,
  payload
});

// GET
export const getOcrTransaction = payload => {
  return {
    type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTION,
    payload
  };
};
export const getOcrTransactionSuccess = payload => ({
  type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTION_SUCCESS,
  payload
});
export const getOcrTransactionFail = payload => ({
  type: types.OCR_TRANSACTIONS.GET_OCR_TRANSACTION_FAIL,
  payload
});