import * as types from '../actionTypes';

// USER PERMISSION
export const getUserPermission = payload => {
  return {
    type: types.USERS_PERMISSION.GET_USER_PERMISSION,
    payload
  };
};
export const getUserPermissionSuccess = payload => ({
  type: types.USERS_PERMISSION.GET_USER_PERMISSION_SUCCESS,
  payload
});
export const getUserPermissionFail = payload => ({
  type: types.USERS_PERMISSION.GET_USER_PERMISSION_FAIL,
  payload
});