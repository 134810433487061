export const COMPANY = {
  SET_SHOW_MODAL_SELECT_COMPANY: 'SET_SHOW_MODAL_SELECT_COMPANY',

  SET_CURRENT_COMPANY: 'SET_CURRENT_COMPANY',

  GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_FAIL: 'GET_COMPANIES_FAIL',


  GET_OWNER_REQUEST: 'GET_OWNER_REQUEST',
  GET_OWNER_SUCCESS: 'GET_OWNER_SUCCESS',
  GET_OWNER_FAIL: 'GET_OWNER_FAIL',

  GET_COUNTER_REQUEST: 'GET_COUNTER_REQUEST',
  GET_COUNTER_SUCCESS: 'GET_COUNTER_SUCCESS',
  GET_COUNTER_FAIL: 'GET_COUNTER_FAIL'
};
