import React, { PureComponent } from 'react';
import { webConfig } from '../configs/system.configs';
import { loadNewBody, revertBody } from '../utils/Utils';

const bodyClass = ['1-colum', 'bg-full-screen-image', 'blank-page'];
const baseUrl = webConfig.webUrl;

class Error500 extends PureComponent {
  componentDidMount() {
    loadNewBody(bodyClass);
  }

  componentWillUnmount() {
    revertBody(bodyClass);
  }

  render() {
    return (
      <div className="row">
        <div className="col s12 center-align white">
          <img
            style={{
              width: '100%',
              height: '80vh'
            }}
            src={`${baseUrl}/assets/images/gallery/error-2.png`}
            className="bg-image-404" alt="404-page-img"
          />
          <h1 className="error-code m-0">500</h1>
          <h6 className="mb-2">Bad Request</h6>
        </div>
      </div>
    );
  }
}

export default Error500;
