import { api } from '../constants/api.constant';
export default {
  rebuildRouter: (router, match) => {
    if (match.params.company !== 'company') {
      return router.replace(':company', match.params.company);
    }
    return router;
  },
  buildDownloadUrl: (file) => {
    return api.files.download.replace(':file', file);
  }
}