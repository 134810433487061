import * as types from '../../actionTypes';

const resetCreditCheck = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15,
  status: 1,
  date_option: 2,
  score_character: '',
  list_type: 'company'
};

const filterCreditCheck = { ...resetCreditCheck };

const initialState = {
  loading: false,
  creditCheck: null,
  filterCreditCheck,
  creditChecks: {
    total: 0,
    rows: []
  }
};

const creditCheck = (state = initialState, action) => {
  switch (action.type) {
    //loading
    case types.CREDIT_CHECK.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // filter
    case types.CREDIT_CHECK.SET_FILTER:
      return {
        ...state,
        filterCreditCheck: action.payload
      };
    case types.CREDIT_CHECK.RESET_FILTER:
      return {
        ...state,
        filterCreditCheck: { ...resetCreditCheck }
      };

    // list
    case types.CREDIT_CHECK.GET_CREDIT_CHECKS:
      return {
        ...state,
        loading: true
      };
    case types.CREDIT_CHECK.GET_CREDIT_CHECKS_SUCCESS:
      return {
        ...state,
        loading: false,
        creditChecks: action.payload
      };
    case types.CREDIT_CHECK.GET_CREDIT_CHECKS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // get
    case types.CREDIT_CHECK.CREDIT_CHECK_SEARCH:
      return {
        ...state,
        loading: true
      };
    case types.CREDIT_CHECK.CREDIT_CHECK_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        creditCheck: action.payload
      };
    case types.CREDIT_CHECK.CREDIT_CHECK_SEARCH_FAIL:
      return {
        ...state,
        loading: false,
      };


    //DEFAULT
    default:
      return state;
  }
};
export default creditCheck;
