import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getSendPostReportSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_SEND_POST, getSendPostReport);
}

export function* getSendPostReport(action) {
  try {
    const response = yield call(adminReportServices.sendPost, action.payload);
    if (response.success) {
      yield put(actions.getSendPostSuccess(response.data));
    } else {
      yield put(actions.getSendPostFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getSendPostFail(error));
  }
}
