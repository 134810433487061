import * as actionTypes from '../actionTypes';


export const setShowModalSelectCompany = payload => {
  return {
    type: actionTypes.COMPANY.SET_SHOW_MODAL_SELECT_COMPANY,
    payload
  };
};

export const getCompaniesRequest = payload => {
  return {
    type: actionTypes.COMPANY.GET_COMPANIES_REQUEST,
    payload
  };
};
export const getCompaniesSuccess = payload => {
  return {
    type: actionTypes.COMPANY.GET_COMPANIES_SUCCESS,
    payload
  };
};
export const getCompaniesFail = payload => {
  return {
    type: actionTypes.COMPANY.GET_COMPANIES_FAIL,
    payload
  };
};

export const getOwnerRequest = payload => {
  return {
    type: actionTypes.COMPANY.GET_OWNER_REQUEST,
    payload
  };
};
export const getOwnerSuccess = payload => {
  return {
    type: actionTypes.COMPANY.GET_OWNER_SUCCESS,
    payload
  };
};
export const getOwnerFail = payload => {
  return {
    type: actionTypes.COMPANY.GET_OWNER_FAIL,
    payload
  };
};

export const setCurrentCompany = payload => {
  return {
    type: actionTypes.COMPANY.SET_CURRENT_COMPANY,
    payload
  };
};

export const getCounterRequest = payload => {
  return {
    type: actionTypes.COMPANY.GET_COUNTER_REQUEST,
    payload
  };
};
export const getCounterSuccess = payload => {
  return {
    type: actionTypes.COMPANY.GET_COUNTER_SUCCESS,
    payload
  };
};
export const getCounterFail = payload => {
  return {
    type: actionTypes.COMPANY.GET_COUNTER_FAIL,
    payload
  };
};
