import * as types from '../actionTypes';

const resetFilter = {
  customer_id: 0,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  customerContacts: {
    total: 0,
    rows: []
  },
  customerContact: null,
  customerContactOptions: null,
  contactPersonOptions: null,
};

const customerContacts = (state = initialState, action) => {
  switch (action.type) {
    case types.CUSTOMER_CONTACT.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // FILTER
    case types.CUSTOMER_CONTACT.SET_FILTER_CUSTOMER_CONTACTS:
      return {
        ...state,
        filter: action.payload
      };
    case types.CUSTOMER_CONTACT.RESET_FILTER_CUSTOMER_CONTACTS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACTS:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerContacts: action.payload
      };
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // OPTIONS
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_OPTIONS:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        customerContactOptions: action.payload
      };
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // GET ITEM
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        customerContact: action.payload
      };
    case types.CUSTOMER_CONTACT.GET_CUSTOMER_CONTACT_FAIL:
      return {
        ...state,
        loading: false,
      };


    // GET CONTACT PERSON OPTIONS
    case types.CUSTOMER_CONTACT.GET_CONTACT_PERSON_OPTIONS:
      return {
        ...state,
        loading: true
      };
    case types.CUSTOMER_CONTACT.GET_CONTACT_PERSON_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactPersonOptions: action.payload
      };
    case types.CUSTOMER_CONTACT.GET_CONTACT_PERSON_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default customerContacts;
