import * as types from '../actionTypes';

// GET
export const getInvoiceSetting = payload => {
  return {
    type: types.INVOICE_SETTINGS.GET_INVOICE_SETTINGS_REQUEST,
    payload
  };
};
export const getInvoiceSettingSuccess = payload => ({
  type: types.INVOICE_SETTINGS.GET_INVOICE_SETTINGS_SUCCESS,
  payload
});
export const getInvoiceSettingFail = payload => ({
  type: types.INVOICE_SETTINGS.GET_INVOICE_SETTINGS_FAIL,
  payload
});
