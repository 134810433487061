import {APIError} from '../constants/errorCode/system';
import BaseServices from './base.services';
import {api} from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.reminderInvoice.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return {success: false, code: APIError.ConnectFail};
    }
  },
  save: async params => {
    try {
      const url = api.reminderInvoice.save;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return {success: false, code: APIError.ConnectFail};
    }
  },
};
