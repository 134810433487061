import phoneFormat from 'phoneformat.js';
import React from 'react';
import { initDropdown } from '../../../common/custom.plugins';
import Select from '../../UI/reactSelect/ReactSelect';

import './css/flags16.css';
import './css/flags32.css';
import './css/styles.css';

const defaultStyle = {
  control: base => ({
    ...base,
    minHeight: 25,
    height: '2.64rem',
    margin: '0',
    backgroundColor: '#c9c9c9',
    border: 'none',
    borderRight: '1px solid #9e9e9e !important',
    borderRadius: '.4rem 0 0 .4rem !important',
    boxShadow: 'none',
    textAlign: 'center',
    '&:hover': {
      outline: '0 !important',
      border: 'none !important',
      borderRight: '1px solid #9e9e9e !important',
    },
    '&:focus': {
      outline: '0 !important',
      border: 'none !important',
      borderRight: '1px solid #9e9e9e !important',
      boxShadow: 'none !important'
    }
  }),
  indicatorSeparator: base => ({
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: '0',
    height: '2.5rem',
    paddingLeft: '5px'
  }),
  placeholder: base => ({
    ...base,
    top: '49%',
  }),
  singleValue: base => ({
    ...base,
    top: '49%'
  }),
  menu: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
    zIndex: '9999',
  }),
  menuList: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
  }),
};
class InputPhoneNumber extends React.PureComponent {

  constructor(props) {
    initDropdown();
    super(props);

    const countryCode = this.props.defaultValue ? phoneFormat.countryForE164Number(this.props.defaultValue) : null;
    const alphaCode = countryCode ? countryCode : 'NO';
    const phoneNumber = props.defaultValue ? props.defaultValue : '';
    let newPhoneNumber = phoneNumber;
    if (phoneNumber && countryCode) {
      newPhoneNumber = phoneNumber.slice(countryCode.length + 1, phoneNumber.length);
    }

    this.state = {
      phone_flag: '',
      phone_code: '',
      phone_number: newPhoneNumber ? phoneFormat.formatInternational(alphaCode, newPhoneNumber) : newPhoneNumber,
      placeholder: '',
      width: '100px',
    }
    this.selectRef = React.createRef();
  }

  componentDidMount = () => {
    this.init();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.init();
    }
  }

  init = () => {
    const { data } = this.props;
    const countryCode = this.props.defaultValue ? phoneFormat.countryForE164Number(this.props.defaultValue) : null;
    const alpha_code = countryCode ? countryCode : 'NO';
    const countries = data && data.find((row) => row.alpha_code === alpha_code);
    const phoneNumber = this.props.defaultValue ? this.props.defaultValue : '';
    let newPhoneNumber = phoneNumber;
    if (phoneNumber && countryCode) {
      newPhoneNumber = phoneNumber.slice(countryCode.length + 1, phoneNumber.length);
    }

    this.setValue({
      alpha_code: alpha_code,
      phone_code: countries?.phone_code ? countries.phone_code : '47',
      phone_number: newPhoneNumber ? phoneFormat.formatInternational(alpha_code, newPhoneNumber) : '',
    });

    if (!this.state.phone_number) {
      this.setState({
        phone_number: newPhoneNumber ? phoneFormat.formatInternational(alpha_code, newPhoneNumber) : '',
      })
    }
  }

  onChange = (value) => {
    this.setValue(value);
  }

  setValue = (value) => {
    const { setCountryCode } = this.props;
    let placeholder = phoneFormat.formatInternational(value.alpha_code, phoneFormat.exampleMobileNumber(value.alpha_code));

    let phone_number = this.state.phone_number;
    if (phone_number) {
      phone_number = phone_number.replace(/[^0-9]/g, '');
      phone_number = phoneFormat.formatInternational(value.alpha_code, phone_number);
    }

    this.setState({ phone_flag: value.alpha_code ? value.alpha_code : '', phone_code: value.phone_code ? value.phone_code : '', placeholder, phone_number })

    if (setCountryCode)
      setCountryCode(value.alpha_code);
  }

  onInputChange = (event) => {
    const { handleChange } = this.props;
    const { phone_code, phone_flag } = this.state;
    const value = event.target.value;
    this.setState({ phone_number: phoneFormat.formatInternational(phone_flag, value) });

    handleChange && handleChange(event);
  }

  listPhoneNumber = () => {
    const { data, disabled } = this.props;

    if (disabled)
      return;

    return data && data.map((countries, index) => {
      if (!countries.alpha_code || !countries.phone_code)
        return;

      const active = countries.alpha_code === this.state.phone_flag ? 'active' : '';
      return (
        <li
          key={index}
          className={active}
          data-dial-code={countries.phone_code}
          data-countries_id-code={countries.alpha_code ? countries.alpha_code.toLowerCase() : ''}
          onClick={() => this.onChange(countries)}
        >
          <div className={`flag ${countries.alpha_code ? countries.alpha_code.toLowerCase() : ''}`}></div>
          <span className="countries_id-name">{countries.name}</span>
          <span className="dial-code">+{countries.phone_code}</span>
        </li>
      )
    })
  }

  handleSelect = (selected) => event => {
    const { data } = this.props;
    this.setState({ [selected]: event.value }, () => {
      const findCountries = data?.find(c => c?.phone_code === event.value)
      findCountries && this.setValue(findCountries)
    });
  }

  formatPhoneCodeOptionsLabel = ({ label, value }, { context }) => {
    if (context === "value") {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={`flag ${label ? label.toLowerCase() : ''}`}></div>
          <span className="dial-code">+{value}</span>
        </div>
      )
    } else if (context === "menu") {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={`flag ${label ? label.toLowerCase() : ''}`}></div>
          <span className="dial-code">+{value}</span>
        </div>
      )
    }
  }

  listPhoneNumber2 = () => {
    const { data, disabled, disabledCountryCode, selectStyles } = this.props;
    let phoneCodeOptions = [];
    data && data.map((countries, index) => {
      if (!countries.alpha_code || !countries.phone_code)
        return;

      phoneCodeOptions.push({ label: countries.alpha_code, value: countries.phone_code });
    });


    if (disabled)
      return;

    const phoneSelected = this.state.phone_code;

    return (
      <div className="react-select width90">
        <Select
          components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
          selectedLabel={phoneSelected}
          groupOptions={phoneCodeOptions}
          handleSelect={this.handleSelect('phone_code')}
          formatOptionLabel={this.formatPhoneCodeOptionsLabel}
          styles={selectStyles ? selectStyles : defaultStyle}
          disabled={disabledCountryCode}
          isSearchable={!disabledCountryCode}
        />
      </div>
    )
  }

  render() {
    const { fieldClass, dataTarget, meta, input, className, disabled, isBorder, isBorderRadius } = this.props
    const { width, phone_number, placeholder } = this.state
    const dataTargetList = dataTarget ? dataTarget : 'listPhoneNumber';

    return (
      <div className={`input-phone-number f16 ${isBorder && 'border'} ${isBorderRadius && 'border-radius'} ${fieldClass}`}>
        {this.listPhoneNumber2()}

        <input
          {...input}
          type="tel"
          className={`${meta.touched && meta.error && 'invalid'} validate input-flex padding-left ${isBorder && 'input-none-border'} ${className}`}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete="off"
          value={phone_number}
          onChange={this.onInputChange}
          style={{ paddingLeft: `${width + 2}px`, width: `calc(100% - ${width + 10}px)` }}
        />
      </div>
    );
  }
}

export default InputPhoneNumber;
