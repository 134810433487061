import * as types from '../actionTypes';

export const setFilterInvoiceDrafts = payload => ({
  type: types.INVOICE_DRAFTS.SET_FILTER_INVOICES_DRAFT,
  payload
});
export const resetFilterInvoiceDrafts = payload => ({
  type: types.INVOICE_DRAFTS.RESET_FILTER_INVOICES_DRAFT,
  payload
});

// LIST
export const getInvoiceDrafts = payload => {
  return {
    type: types.INVOICE_DRAFTS.GET_INVOICE_DRAFTS_REQUEST,
    payload
  };
};
export const getInvoiceDraftsSuccess = payload => ({
  type: types.INVOICE_DRAFTS.GET_INVOICE_DRAFTS_SUCCESS,
  payload
});
export const getInvoiceDraftsFail = payload => ({
  type: types.INVOICE_DRAFTS.GET_INVOICE_DRAFTS_FAIL,
  payload
});

// DELETE
export const deleteInvoicesDraft = payload => {
  return {
    type: types.INVOICE_DRAFTS.DELETE_INVOICES_DRAFT_REQUEST,
    payload
  };
};
export const deleteInvoicesDraftSuccess = payload => ({
  type: types.INVOICE_DRAFTS.DELETE_INVOICES_DRAFT_REQUEST_SUCCESS,
  payload
});
export const deleteInvoicesDraftFail = payload => ({
  type: types.INVOICE_DRAFTS.DELETE_INVOICES_DRAFT_REQUEST_FAIL,
  payload
});