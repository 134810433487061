import * as types from '../actionTypes';

export const setFilterCustomer = payload => ({
  type: types.IMPORTS.SET_FILTER_CUSTOMER,
  payload
});
export const resetFilterCustomer = payload => ({
  type: types.IMPORTS.RESET_FILTER_CUSTOMER,
  payload
});

// SET_CUSTOMER_DATA_IMPORT
export const setCustomerDataImport = payload => {
  return {
    type: types.IMPORTS.SET_CUSTOMER_DATA_IMPORT,
    payload
  };
};

// SET_PRODUCT_DATA_IMPORT
export const setProductDataImport = payload => {
  return {
    type: types.IMPORTS.SET_PRODUCT_DATA_IMPORT,
    payload
  };
};