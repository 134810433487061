import * as types from '../actionTypes';

export const setFilterRepeatingInvoices = payload => ({
  type: types.REPEATING_INVOICE.SET_FILTER_REPEATING_INVOICE,
  payload
});
export const resetFilterRepeatingInvoices = payload => ({
  type: types.REPEATING_INVOICE.RESET_FILTER_REPEATING_INVOICE,
  payload
});

// LIST
export const getRepeatingInvoices = payload => {
  return {
    type: types.REPEATING_INVOICE.FETCH_REPEATING_INVOICE_DATA_REQUEST,
    payload
  };
};
export const getRepeatingInvoicesSuccess = payload => ({
  type: types.REPEATING_INVOICE.FETCH_REPEATING_INVOICE_DATA_SUCCESS,
  payload
});
export const getRepeatingInvoicesFail = payload => ({
  type: types.REPEATING_INVOICE.FETCH_REPEATING_INVOICE_DATA_FAIL,
  payload
});

// GET
export const getRepeatingInvoice = payload => {
  return {
    type: types.REPEATING_INVOICE.GET_REPEATING_INVOICE_REQUEST,
    payload
  };
};
export const getRepeatingInvoiceSuccess = payload => ({
  type: types.REPEATING_INVOICE.GET_REPEATING_INVOICE_REQUEST_SUCCESS,
  payload
});
export const getRepeatingInvoiceFail = payload => ({
  type: types.REPEATING_INVOICE.GET_REPEATING_INVOICE_REQUEST_FAIL,
  payload
});

// SAVE
export const saveRepeatingInvoice = payload => {
  return {
    type: types.REPEATING_INVOICE.SAVE_REPEATING_INVOICE_REQUEST,
    payload
  };
};
export const saveRepeatingInvoiceSuccess = payload => ({
  type: types.REPEATING_INVOICE.SAVE_REPEATING_INVOICE_REQUEST_SUCCESS,
  payload
});
export const saveRepeatingInvoiceFail = payload => ({
  type: types.REPEATING_INVOICE.SAVE_REPEATING_INVOICE_REQUEST_FAIL,
  payload
});

// FILTER CHART
export const setFilterChart = payload => ({
  type: types.REPEATING_INVOICE.SET_FILTER_CHART,
  payload
});
export const resetFilterChart = payload => ({
  type: types.REPEATING_INVOICE.RESET_FILTER_CHART,
  payload
});

// GET CHART DATA
export const getChartData = payload => {
  return {
    type: types.REPEATING_INVOICE.GET_CHART_DATA_REQUEST,
    payload
  };
};
export const getChartDataSuccess = payload => ({
  type: types.REPEATING_INVOICE.GET_CHART_DATA_SUCCESS,
  payload
});
export const getChartDataFail = payload => ({
  type: types.REPEATING_INVOICE.GET_CHART_DATA_FAIL,
  payload
});