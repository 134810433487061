import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceDistributionRunServices from '../../../services/invoice-distribution-run.services';

export default function* getDistributionMethodSaga() {
  yield takeEvery(types.INVOICE_DISTRIBUTION_RUN.GET_DISTRIBUTION_METHOD, getDistributionMethod);
}

export function* getDistributionMethod(action) {
  try {
    const response = yield call(invoiceDistributionRunServices.method, action.payload);
    if (response.success) {
      yield put(actions.getDistributionMethodSuccess(response.data));
    } else {
      yield put(actions.getDistributionMethodFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getDistributionMethodFail(error));
  }
}
