import * as types from '../actionTypes';

const resetFilter = {
  noPagination: false,
  pageSize: 3,
  pageNumber: 1,
}

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  salesMans: [],
  salesMan: null,
};

const salesMans = (state = initialState, action) => {
  switch (action.type) {
    case types.SALES_MAN.SET_FILTER_SALES_MAN:
      return {
        ...state,
        filter: action.payload
      };

    case types.SALES_MAN.GET_SALES_MANS:
      return {
        ...state,
        loading: true,
      };
    case types.SALES_MAN.GET_SALES_MANS_SUCCESS:
      return {
        ...state,
        loading: false,
        salesMans: action.payload
      };
    case types.SALES_MAN.GET_SALES_MANS_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default salesMans;
