export const COMPANIES_SUSPEND = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_COMPANIES_SUSPEND_INVOICES: 'SET_FILTER_COMPANIES_SUSPEND_INVOICES',
  RESET_FILTER_COMPANIES_SUSPEND_INVOICES: 'RESET_FILTER_COMPANIES_SUSPEND_INVOICES',

  GET_COMPANIES_SUSPEND_INVOICES: 'GET_COMPANIES_SUSPEND_INVOICES',
  GET_COMPANIES_SUSPEND_INVOICES_SUCCESS: 'GET_COMPANIES_SUSPEND_INVOICES_SUCCESS',
  GET_COMPANIES_SUSPEND_INVOICES_FAIL: 'GET_COMPANIES_SUSPEND_INVOICES_FAIL',
};
