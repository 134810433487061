import contractsServices from '../../../services/contracts.services';
import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

export default function* getContractsSaga() {
  yield takeEvery(types.CONTRACTS.GET_CONTRACTS, getContracts);
}

export function* getContracts(action) {
  try {
    const response = yield call(contractsServices.list, action.payload);
    if (response.isSuccess) {
      yield put(actions.getContractsSuccess(response.data));
    } else {
      yield put(actions.getContractsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getContractsFail(error));
  }
}
