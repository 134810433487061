import * as types from '../actionTypes';

const INITIAL_STATE = {
  isShowCompanySelect: false,
  isLoadingCompany: false,
  isLoadingOwner: false,
  companyData: null,
  company: {},
  owner: {},
  counter: {},
};

const company = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.COMPANY.SET_SHOW_MODAL_SELECT_COMPANY: {
      return {
        ...types,
        isShowCompanySelect: action.payload
      };
    }

    //SET_CURRENT_COMPANY
    case types.COMPANY.SET_CURRENT_COMPANY:
      return {
        ...state,
        company: action.payload
      };

    //GET_COMPANIES_REQUEST
    case types.COMPANY.GET_COMPANIES_REQUEST:
      return {
        ...state,
        isLoadingCompany: true
      };
    case types.COMPANY.GET_COMPANIES_SUCCESS:
      return {
        ...state,
        companyData: action.payload,
        isLoadingCompany: false
      };
    case types.COMPANY.GET_COMPANIES_FAIL:
      return {
        ...state,
        isLoadingCompany: false
      };

    //GET_OWNER_REQUEST
    case types.COMPANY.GET_OWNER_REQUEST:
      return {
        ...state,
        isLoadingOwner: true
      };
    case types.COMPANY.GET_OWNER_SUCCESS:
      return {
        ...state,
        owner: action.payload,
        isLoadingOwner: false
      };
    case types.COMPANY.GET_OWNER_FAIL:
      return {
        ...state,
        isLoadingOwner: false
      };

    //GET_OWNER_REQUEST
    case types.COMPANY.GET_COUNTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.COMPANY.GET_COUNTER_SUCCESS:
      return {
        ...state,
        counter: action.payload,
        loading: false
      };
    case types.COMPANY.GET_COUNTER_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return state;
  }
};
export default company;
