import React from 'react';
import { Link } from 'react-router-dom';
import { setLocalStorage } from '../../../../../utils/Utils';

class Language extends React.Component {
  changeLanguage = (lang) => async () => {
    const params = {
      Language: lang
    };
    setLocalStorage('lang', lang);
    window.location.reload();
  }

  render() {
    const { t } = this.props;
    let icon = localStorage.getItem('lang') || 'gb';
    icon = icon === 'en' ? 'gb' : icon;
    return (
      <>
        <Link
          to='#'
          className="waves-effect waves-block waves-light translation-button dropdown-trigger right mt-3"
          data-target="translation-dropdown"
        >
          <span className={`flag-icon flag-icon-${icon}`}></span>
        </Link>
        <ul className="dropdown-content right-align" id="translation-dropdown">
          <li>
            <Link
              to='#'
              className="grey-text text-darken-1"
              onClick={this.changeLanguage('no')}
            >
              <i className="flag-icon flag-icon-no"></i>
              {t('payments:no')}
            </Link>
          </li>
          <li>
            <Link
              to='#'
              className="grey-text text-darken-1"
              onClick={this.changeLanguage('en')}
            >
              <i className="flag-icon flag-icon-gb"></i>
              {t('en')}
            </Link>
          </li>
          <li>
            <Link
              to='#'
              className="grey-text text-darken-1"
              onClick={this.changeLanguage('vn')}
            >
              <i className="flag-icon flag-icon-vn"></i>
              {t('vn')}
            </Link>
          </li>
        </ul>
      </>
    );
  }
}

export default Language;
