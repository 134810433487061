import React from 'react';
import './index.css';

const TableSort = props => {
  const { title, id, currentSort } = props;
  const sortActive = currentSort && currentSort === id;
  // const sortType = props.sortType === 'desc' ? 'keyboard_arrow_down' : 'keyboard_arrow_up';
  const styles = props.styles ? props.styles : {};
  return (
    <th
      style={styles}
      onClick={props.clickSort}
      className={props.classes}
      width={props.width}
    >
      <div className="d-inline-grid">
        <span className='display-grid center'>
          <span className={`d-block cursor-pointer ${sortActive && 'grey-text text-darken-2'}`}>
            {title}
          </span>
          {/* {sortActive
            ? <i className='material-icons th-icon'>{sortType}</i>
            : <i className='material-icons th-icon' style={{ height: 24 }} />
          } */}
        </span>
      </div>
    </th>
  );
};

export default TableSort;
