import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import bankAccountServices from '../../../services/bankAccount.services';

export default function* getBankAccountsSaga() {
  yield takeEvery(types.BANK_ACCOUNT.FETCH_BANK_ACCOUNT_DATA_REQUEST, getBankAccounts);
}

export function* getBankAccounts(action) {
  try {
    const response = yield call(bankAccountServices.list, action.payload);
    if (response.success) {
      yield put(actions.getBankAccountsSuccess(response.data));
    } else {
      yield put(actions.getBankAccountsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getBankAccountsFail(error));
  }
}
