import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceHistoryServices from '../../../services/invoiceHistory.services';

export default function* fetchInvoiceHistorySaga() {
  yield takeEvery(types.INVOICE_HISTORY.FETCH_INVOICE_HISTORY_DATA_REQUEST, fetchInvoiceHistory);
}

export function* fetchInvoiceHistory(action) {
  try {
    const response = yield call(invoiceHistoryServices.list, action.payload);
    if (response.success) {
      yield put(actions.fetchInvoiceHistorySuccess(response.data));
    } else {
      yield put(actions.fetchInvoiceHistoryFail(response?.message));
    }
  } catch (error) {
    yield put(actions.fetchInvoiceHistoryFail(error));
  }
}
