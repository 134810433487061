import * as types from '../actionTypes';

export const setFilterCreditCheck = payload => ({
  type: types.CREDIT_CHECKING.SET_FILTER_CREDIT_CHECK,
  payload
});

export const resetFilterCreditCheck = payload => ({
  type: types.CREDIT_CHECKING.RESET_FILTER_CREDIT_CHECK,
  payload
});

// GET
export const creditCheckingRequest = payload => {
  return {
    type: types.CREDIT_CHECKING.CREDIT_CHECKING_REQUEST,
    payload
  };
};
export const creditCheckingRequestSuccess = payload => ({
  type: types.CREDIT_CHECKING.CREDIT_CHECKING_SUCCESS,
  payload
});
export const creditCheckingRequestFail = payload => ({
  type: types.CREDIT_CHECKING.CREDIT_CHECKING_FAIL,
  payload
});

// LIST
export const creditCheckListRequest = payload => {
  return {
    type: types.CREDIT_CHECKING.CREDIT_CHECK_LIST_REQUEST,
    payload
  };
};
export const creditCheckListRequestSuccess = payload => ({
  type: types.CREDIT_CHECKING.CREDIT_CHECK_LIST_SUCCESS,
  payload
});
export const creditCheckListRequestFail = payload => ({
  type: types.CREDIT_CHECKING.CREDIT_CHECK_LIST_FAIL,
  payload
});