import * as types from '../actionTypes';

const initialState = {
  loading: false,
  payments: null,
};

const payments = (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENTS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    //DEFAULT
    default:
      return state;
  }
};
export default payments;
