import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { api } from '../../../constants/api.constant';
import { ErrorRoutes } from '../../../constants/routes.constants';
import routerHelper from '../../../helpers/router.helper';
import invoiceConfirmServices from '../../../services/invoice-confirm.services';
import Loading from '../../UI/Loading';

class InvoiceConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: null,
      message: '',
    };
  }

  componentDidMount = async () => {
    this.getInvoice();
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getInvoice();
    }
  }

  getInvoice = async () => {
    const { history, match } = this.props;
    const companies_id = match.params.companies_id;
    const id = match.params.id;
    const confirm_token = match.params.confirm_token;

    if (companies_id, id, confirm_token) {
      const response = await invoiceConfirmServices.get({ companies_id, id, confirm_token });      
      if (response.success) {
        this.setState({ invoice: response.data })
      } else {
        history.push(routerHelper.rebuildRouter(ErrorRoutes.Notfound, match));
      }
    } else {
      history.push(routerHelper.rebuildRouter(ErrorRoutes.Notfound, match));
    }
  }

  onConfirm = async () => {
    const { match, t } = this.props;
    const { companies_id, id, confirm_token } = match.params;

    await Swal.fire({
      title: t('confirm_offer'),
      html: t('confirm_offer_message'),
      icon: 'question',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#03a9f4',
      cancelButtonText: t('cancel'),
      confirmButtonText: t('confirm'),
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        const response = await invoiceConfirmServices.confirm({ companies_id, id, confirm_token });
        if (response.success) {
          await this.getInvoice();
          await Swal.fire({
            title: t('confirm_offer'),
            html: response.message ? response.message : t('confirm_offer_success'),
            icon: 'success',
          });
        } else {
          await Swal.fire({
            title: t('confirm_offer'),
            html: response.message ? response.message : t('confirm_offer_failed'),
            icon: 'error',
          });
        }
      }
    });
  }

  render() {
    const { t, loading } = this.props;
    const { invoice } = this.state;
    
    const viewUrl = invoice?.invoice_document ? `${api.files.view}${invoice?.invoice_document}` : '';
    return (
      <div id="search-page" className="row">
        <div className="col s12">
          <div className="container">
            <div id="search-wrapper" className="search section">
              <Loading loading={loading} />
              <div className="row">
                <div className="col s12 m6 offset-m3">
                  <h5 className="text-center font-bold">{t('confirm_offer')}</h5>
                  <p className="m-0 font-weight-500 mt-2 hide-on-med-and-down text-ellipsis text-center mb-1">
                    {t('offer_confirm_term')}
                  </p>
                  <div style={viewUrl ? { height: '80vh' } : {}}>
                    {viewUrl ?
                      <iframe src={viewUrl}
                        title="Invoice preview" height="100%" width="100%"
                        style={{ border: 'none' }}>
                      </iframe>
                      : <p className="text-center">{t('no_invoice_document')}</p>
                    }
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {invoice?.confirm === 0 ?
                      <>
                        <button className="btn btn-primary btn-block mt-2" onClick={this.onConfirm}>{t('confirm')}</button>
                        {/* <button className="btn white blue-text btn-block mt-2 ml-2" onClick={this.confirm}>{t('cancel')}</button> */}
                      </>
                      :
                      <h5 className="lighten-5 green green-text pad-10">{t('offer_confirmed')}</h5>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="content-overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.customer.loading;
  const company = state.company.company;
  const autocompleteData = state.customer.autocompleteData;
  return {
    loading,
    company,
    autocompleteData,
  };
};
export default withRouter(connect(mapStateToProps)(withNamespaces('invoices')(InvoiceConfirm)));
