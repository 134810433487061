import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesPaymentPackagesServices from '../../../services/companies-payment-packages.services';

export default function* getPaymentCreditCheckSaga() {
  yield takeEvery(types.COMPANIES_PAYMENT_PACKAGES.GET_PAYMENT_CREDIT_CHECK, getPaymentCreditCheck);
}

export function* getPaymentCreditCheck(action) {
  try {
    const response = yield call(companiesPaymentPackagesServices.getCreditCheck, action.payload);
    if (response.success) {
      yield put(actions.getPaymentCreditCheckSuccess(response.data));
    } else {
      yield put(actions.getPaymentCreditCheckFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getPaymentCreditCheckFail(error));
  }
}
