
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { invoiceStatus, invoiceStatusColor, invoiceStatusNames } from '../../../constants/invoice-status.constants';
import { invoiceTypes, invoiceTypesNames } from '../../../constants/invoice-types.constants';
import { fetchListInvoices, setFilterInvoices } from '../../../store/actions';

import moment from 'moment-timezone';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { initDropdown } from '../../../common/custom.plugins';
import currencyHelper from '../../../helpers/currencyHelper';
import routerHelper from '../../../helpers/router.helper';
import timeHelper from '../../../helpers/timeHelper';
import RegisterPaymentModal from '../../invoices/invoicesDetail/modal/RegisterPaymentModal';
import ViewDocumentModal from '../../invoices/modal/ViewDocumentModal';
import Loading from '../../UI/Loading';
import Paginator from '../../UI/Pagination';
import TableSort from '../../UI/table/TableSort';

class InvoicesListTable extends Component {
  constructor(props) {
    super(props);
    this.DateShow = {
      Sent: 0,
      Paid: 1
    };
    this.state = {
      currentSort: -1,
      cursor: null,
      invoice: '',
      openViewModal: false,
      openModalRegisterPayment: false,
      invoiceDetail: null
    };
    const { t } = props;
    this._rowHeader = [
      {
        title: t('invoice_no'),
        sortBy: 'invoice_no'
      },
      {
        title: t('company_name'),
        sortBy: 'CompanyName',
      },
      {
        title: t('invoice_date'),
        sortBy: 'invoice_date',
      },
      {
        title: t('due_date'),
        sortBy: 'due_date',
        classes: 'center-align',
      },
      {
        title: t('status'),
        sortBy: 'f1_invoices.status',
      },
      {
        title: t('amount'),
        sortBy: 'amount',
        classes: 'right-align',
        noSort: true
      },
      {
        title: t('currency'),
        sortBy: 'currency_id',
        classes: 'right-align',
      },
      {
        title: '',
        classes: 'right-align',
        noSort: true
      }
    ];
    this.isTyping = null;
  }

  componentDidMount() {
    initDropdown();
    document.addEventListener('keyup', this.onKeyUp, false);
  }

  componentDidUpdate() {
    initDropdown();
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyUp, false);
    clearTimeout(this.isTyping);
  }

  rebuildRouter = router => {
    const { match } = this.props;
    if (match.params.company !== 'company') {
      return router.replace(':company', match.params.company);
    }
    return router;
  }

  changePage = async curPage => {
    const { match, filterInvoices, dispatch } = this.props;
    if (match && match.params.companies_id) {
      let params = Object.assign({}, filterInvoices);
      params.companies_id = match.params.companies_id;
      params.type = 'invoice';
      params.pageNumber = curPage;
      await dispatch(setFilterInvoices(params));
      await dispatch(fetchListInvoices(params));
    }
  }

  changePageSize = async (pageSizeNew) => {
    const { match, filterInvoices, dispatch } = this.props;
    if (match && match.params.companies_id) {
      let params = Object.assign({}, filterInvoices);
      params.companies_id = match.params.companies_id;
      params.type = 'invoice';
      params.pageSize = pageSizeNew;
      params.pageNumber = 1;
      
      await dispatch(setFilterInvoices(params));
      await dispatch(fetchListInvoices(params));
    }
  }

  clickSort = (field, index) => async () => {
    const { match, filterInvoices, dispatch } = this.props;
    const sorting = filterInvoices.sortType === 'asc' ? 'desc' : 'asc';
    if (match && match.params.companies_id) {
      let params = Object.assign({}, filterInvoices);
      params.companies_id = match.params.companies_id;
      params.type = 'invoice';
      params.sortBy = field;
      params.sortType = sorting;
      this.setState({
        currentSort: index
      });
      await dispatch(setFilterInvoices(params));
      await dispatch(fetchListInvoices(params));
    }
  }

  onDownload = (event, item) => {
    event.preventDefault();
    if (item.invoice_document) {
      const url = routerHelper.buildDownloadUrl(item.invoice_document);
      window.open(url);
    }
  }

  openRegisterPaymentModal = (event, item) => {
    event.preventDefault();

    this.setState({
      invoiceDetail: item,
      openModalRegisterPayment: true
    });
  }

  closeRegisterPaymentModal = () => {
    this.setState({ openModalRegisterPayment: false });
  }

  renderRegisterPaymentModal = () => {
    const { t, fetchData } = this.props;
    const { invoiceDetail } = this.state;
    return (
      <RegisterPaymentModal
        openModal={this.state.openModalRegisterPayment}
        onClose={this.closeRegisterPaymentModal}
        t={t}
        invoiceDetail={invoiceDetail}
        getData={fetchData}
      />
    );
  }

  renderTableHeader() {
    const { filterInvoices } = this.props;
    return (
      <thead>
        <tr>
          {this._rowHeader.map((item, index) => {
            return (
              <TableSort
                key={index}
                id={index + 1}
                title={item.title}
                clickSort={item.noSort
                  ? null
                  : this.clickSort(item.sortBy, index + 1)}
                currentSort={this.state.currentSort}
                sortBy={filterInvoices.sortBy}
                sortType={filterInvoices.sortType}
                classes={item.classes}
                styles={item.styles}
              />
            );
          })}
        </tr>
      </thead>
    );
  }

  renderRow = (item, index) => {
    const { t } = this.props;
    const {
      id,
      companies_id,
      invoice_no,
      companies_name,
      type,
      invoice_date,
      due_date,
      status,
      sent_number,
      currency_name,
    } = item;

    const amount = item?.amount ?? 0
    const amount_credit_note = item?.amount_credit_note ?? 0
    const amount_payment = item?.amount_payment ?? 0
    const payment_fee = item?.payment_fee ?? 0

    let outstanding = parseFloat(amount) + parseFloat(payment_fee) + parseFloat(amount_credit_note) - parseFloat(amount_payment);

    if (Number(status) === 6 || type === 'credit_note_invoice')
      outstanding = 0

    let locate = localStorage.getItem('lang');
    if (localStorage.getItem('lang') === 'vn')
      locate = 'vi';
    if (localStorage.getItem('lang') === 'no')
      locate = 'nb';

    const due_date_utc = due_date ? moment(due_date).utc() : '';
    const checkDueDate = moment().isBefore(due_date_utc);

    let show_due_date = '';
    if (status === invoiceStatus.Unpaid || status === invoiceStatus.Partially_paid) {
      show_due_date = due_date_utc ? (checkDueDate ? t('will_due') : t('due')) + ' ' + moment().locale(locate).to(due_date_utc) : '';
    }

    return (
      <tr key={index}>
        <td>
          <span style={{ display: 'flex', alignItems: 'center', lineHeight: 'initial' }}>
            {invoice_no}
            {Number(sent_number ?? 0) === 0 && <i className="tiny material-icons ml-1 red-text" title={t('not_sent')}>information</i>}
          </span>
          <p className="small-title">{t(invoiceTypesNames[type])}</p>
        </td>
        <td>{companies_name}</td>
        <td>
          <p>{invoice_date ? timeHelper.formatDate(invoice_date) : ''}</p>
          {
            show_due_date ?
              <p className={!checkDueDate ? 'small-title red-text' : 'small-title orange-text'}>
                <i className="tiny material-icons width-20px">information</i>
                {show_due_date}
              </p>
              : ''
          }
        </td>
        <td className="center-align">
          {due_date_utc ? timeHelper.formatDate(due_date_utc) : ''}
        </td>
        <td>
          <span className={invoiceStatusColor[status]}>
            {t(invoiceStatusNames[status])}
          </span>
        </td>
        <td className="right-align">
          <p>{amount !== 0 ? currencyHelper.formatMoney(amount) : 0}</p>
          {(Number(status) === invoiceStatus.Partially_paid || Number(status) === invoiceStatus.Paid || Number(status) === invoiceStatus.Over_paid || Number(status) === invoiceStatus.Partially_credited) && type !== invoiceTypes.CashInvoice && outstanding !== 0 ?
            <p>{t('rest') + ': ' + currencyHelper.formatMoney(outstanding)}</p>
            : null
          }
        </td>
        <td className="right-align">{currency_name}</td>
        <td className="right-align" width="5%">
          <Link to='#' className="mg-right-elm" component={''} onClick={() => this.openViewModal(item)}>
            <i className="material-icons">{'visibility'}</i>
          </Link>
        </td>
      </tr >
    );
  }

  renderTable() {
    const { invoices } = this.props;
    return (
      <table className="table invoice-data-table white border-radius-4 pt-1 dataTable no-footer dtr-column">
        {this.renderTableHeader()}
        <tbody>
          {invoices && invoices.rows.map((item, index) => this.renderRow(item, index))}
        </tbody>
      </table>
    );
  }

  openViewModal = async (invoice) => {
    this.setState({ openViewModal: true, invoice })
  }

  closeViewModal = () => {
    this.setState({ openViewModal: false, invoice: {} })
  }

  renderViewModal = () => {
    const { t } = this.props;
    const { invoice } = this.state;
    return (
      <ViewDocumentModal openModal={this.state.openViewModal} onClose={this.closeViewModal} t={t} invoice={invoice} />
    );
  }

  render() {
    const {
      filterInvoices,
      invoices,
      loading
    } = this.props;
    const { total } = invoices;
    const { pageNumber, pageSize } = filterInvoices;
    return (
      <div className="row">
        <div className="col s12 custom-scroll">
          <Loading loading={loading} />
          {this.renderTable()}
          <Paginator
            total={total}
            pageNumber={pageNumber}
            pageSize={pageSize}
            nextHandle={this.changePage}
            previousHandle={this.changePage}
            goStartHandle={this.changePage}
            goEndHandle={this.changePage}
            changePageSize={this.changePageSize}
          />
        </div>
        {this.renderViewModal()}
        {this.renderRegisterPaymentModal()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const loading = state.companiesSuspend.loading;
  const company = state.company.company;
  const filterInvoices = state.companiesSuspend.filterInvoices;
  const invoices = state.companiesSuspend.invoices;
  const companiesService = state.companiesServices.companiesService;
  return {
    loading,
    company,
    filterInvoices,
    invoices,
    companiesService,
  };
};

export default withRouter(connect(mapStateToProps)(withNamespaces('invoices')(InvoicesListTable)));
