import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  vatSetting: null,
};

const vatSettings = (state = initialState, action) => {
  switch (action.type) {
    case types.VAT_SETTINGS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // GET
    case types.VAT_SETTINGS.GET_VAT_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.VAT_SETTINGS.GET_VAT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        vatSetting: action.payload
      };
    case types.VAT_SETTINGS.GET_VAT_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default vatSettings;
