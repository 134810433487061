import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import salesmanSettingsServices from '../../../services/salesman-settings.services';

export default function* getSalesmanSettingsSaga() {
  yield takeEvery(types.SALESMAN_SETTINGS.GET_SALESMAN_SETTINGS_REQUEST, getSalesmanSettings);
}

export function* getSalesmanSettings(action) {
  try {
    const response = yield call(salesmanSettingsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getSalesmanSettingsSuccess(response.data));
    } else {
      yield put(actions.getSalesmanSettingsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getSalesmanSettingsFail(error));
  }
}
