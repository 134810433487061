import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.projects.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async id => {
    try {
      const url = api.projects.get.replace(':id', id);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  add: async params => {
    try {
      const url = api.projects.add;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.projects.update.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.projects.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getLastProjectNo: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.projects.getLastProjectNo}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
