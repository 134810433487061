export const REPEATING_INVOICE = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_REPEATING_INVOICE: 'SET_FILTER_REPEATING_INVOICE',
  RESET_FILTER_REPEATING_INVOICE: 'RESET_FILTER_REPEATING_INVOICE',

  FETCH_REPEATING_INVOICE_DATA_REQUEST: 'FETCH_REPEATING_INVOICE_DATA_REQUEST',
  FETCH_REPEATING_INVOICE_DATA_SUCCESS: 'FETCH_REPEATING_INVOICE_DATA_SUCCESS',
  FETCH_REPEATING_INVOICE_DATA_FAIL: 'FETCH_REPEATING_INVOICE_DATA_FAIL',

  GET_REPEATING_INVOICE_REQUEST: 'GET_REPEATING_INVOICE_REQUEST',
  GET_REPEATING_INVOICE_REQUEST_SUCCESS: 'GET_REPEATING_INVOICE_REQUEST_SUCCESS',
  GET_REPEATING_INVOICE_REQUEST_FAIL: 'GET_REPEATING_INVOICE_REQUEST_FAIL',

  SAVE_REPEATING_INVOICE_REQUEST: 'SAVE_REPEATING_INVOICE_REQUEST',
  SAVE_REPEATING_INVOICE_REQUEST_SUCCESS: 'SAVE_REPEATING_INVOICE_REQUEST_SUCCESS',
  SAVE_REPEATING_INVOICE_REQUEST_FAIL: 'SAVE_REPEATING_INVOICE_REQUEST_FAIL',

  DELETE_REPEATING_INVOICE_REQUEST: 'DELETE_REPEATING_INVOICE_REQUEST',
  DELETE_REPEATING_INVOICE_REQUEST_SUCCESS: 'DELETE_REPEATING_INVOICE_REQUEST_SUCCESS',
  DELETE_REPEATING_INVOICE_REQUEST_FAIL: 'DELETE_REPEATING_INVOICE_REQUEST_FAIL',

  SET_FILTER_CHART: 'SET_FILTER_CHART',
  RESET_FILTER_CHART: 'RESET_FILTER_CHART',

  GET_CHART_DATA_REQUEST: 'GET_CHART_DATA_REQUEST',
  GET_CHART_DATA_SUCCESS: 'GET_CHART_DATA_SUCCESS',
  GET_CHART_DATA_FAIL: 'GET_CHART_DATA_FAIL',
};


