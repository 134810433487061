import * as types from '../actionTypes';

const initialState = {
  loading: false,
  companiesPaymentPackage: {},
  paymentCreditCheck: {},
};

const companiesPaymentPackages = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANIES_PAYMENT_PACKAGES.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // GET ITEM
    case types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGE:
      return {
        ...state,
        loading: true
      };
    case types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesPaymentPackage: action.payload
      };
    case types.COMPANIES_PAYMENT_PACKAGES.GET_COMPANIES_PAYMENT_PACKAGE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case types.COMPANIES_PAYMENT_PACKAGES.GET_PAYMENT_CREDIT_CHECK:
      return {
        ...state,
        loading: true
      };
    case types.COMPANIES_PAYMENT_PACKAGES.GET_PAYMENT_CREDIT_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentCreditCheck: action.payload
      };
    case types.COMPANIES_PAYMENT_PACKAGES.GET_PAYMENT_CREDIT_CHECK_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default companiesPaymentPackages;
