import { Modal } from 'antd';
import React from 'react';
import Draggable from 'react-draggable';
import { withNamespaces } from 'react-i18next';
import Loading from '../Loading';
import './ModalDraggable.css';
class ModalDraggable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      bounds: { left: 0, top: 0, bottom: 0, right: 0 },
    };
    this.draggableRef = React.createRef();
  }

  onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = this.draggableRef?.current?.getBoundingClientRect();
    this.setState({
      bounds: {
        left: -targetRect?.left + uiData?.x,
        right: clientWidth - (targetRect?.right - uiData?.x),
        top: -targetRect?.top + uiData?.y,
        bottom: clientHeight - (targetRect?.bottom - uiData?.y),
      },
    });
  };

  render() {
    const {
      title,
      onOk,
      onCancel,
      open,
      content,
      width,
      bodyStyle,
      loading,
    } = this.props;
    const { bounds, disabled } = this.state;
    return (
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                this.setState({
                  disabled: false,
                });
              }
            }}
            onMouseOut={() => {
              this.setState({
                disabled: true,
              });
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            {title}
          </div>
        }
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        modalRender={modal => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => this.onStart(event, uiData)}
            defaultPosition={{ x: 0, y: 0 }}
          >
            <div ref={this.draggableRef}>{modal}</div>
          </Draggable>
        )}
        width={width}
        footer={null}
        bodyStyle={bodyStyle}
        centered
        maskClosable={false}
      >
        {loading && <Loading loading={loading} />}
        {content}
      </Modal>
    );
  }
}
// Modal.setAppElement('#root');

export default withNamespaces('system')(ModalDraggable);
