import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoicesServices from '../../../services/invoices.services';

export default function* getInvoiceOptionsSaga() {
  yield takeEvery(types.INVOICES.GET_INVOICE_OPTIONS, getInvoiceOptions);
}

export function* getInvoiceOptions(action) {
  try {
    const response = yield call(invoicesServices.paymentCredit, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceOptionsSuccess(response.data));
    } else {
      yield put(actions.getInvoiceOptionsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceOptionsFail(error));
  }
}
