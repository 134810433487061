import './App.css';

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { CompanySuspendUrl, CompanyUrl, ConfirmUrl, CustomerOverview, ErrorRoutes, MandateUrl, PaymentsUrl } from './constants/routes.constants';

import AddCompany from '../src/components/company/AddCompany';
import InvoicesList from './components/companies-suspend/invoices/InvoicesList';
import NewCompany from './components/company/NewCompany';
import CustomerOverviewList from './components/customer-overview/CustomerOverviewList';
import ErrorBoundary from './components/ErrorBoundary';
import InvoiceConfirm from './components/invoices/invoiceConfirm/InvoiceConfirm';
import Payments from './components/settings/serviceSettings/components/payments/Payments';
import Loading from './components/UI/LoadingInvoices';
import Error404 from './pages/Error404';
import Error500 from './pages/Error500';
import MandateGenerate from './components/mandate-generate/MandateGenerate';

const LayoutRoute = React.lazy(() => import('./pages/LayoutRoute'));
const PublicRoute = React.lazy(() => import('./pages/PublicRoute'))

function App() {
  return (
    <Router>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <Switch>
            <PublicRoute restricted={false} path={CompanyUrl.NewCompanyOrganization} exact component={NewCompany} />
            <PublicRoute restricted={false} path={CompanyUrl.NewCompany} exact component={NewCompany} />
            <PublicRoute restricted={false} path={CompanyUrl.AddCompany} exact component={AddCompany} />
            <PublicRoute restricted={false} path={ConfirmUrl.InvoiceConfirm} exact component={InvoiceConfirm} />
            <PublicRoute restricted={false} path={CompanySuspendUrl.InvoiceUnpaidList} exact component={InvoicesList} />
            <PublicRoute restricted={false} path={CustomerOverview.Invoices} exact component={CustomerOverviewList} />
            <PublicRoute restricted={false} path={PaymentsUrl.Payment} exact component={Payments} />
            <PublicRoute restricted={false} path={MandateUrl.MandateGenerate} exact component={MandateGenerate} />
            <PublicRoute restricted={false} path={ErrorRoutes.Notfound} exact component={Error404} />
            <PublicRoute restricted={false} path={ErrorRoutes.BadRequest} exact component={Error500} />
            <LayoutRoute />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
