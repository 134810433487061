import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getAdminCompaniesInvoicesStatusSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_ADMIN_COMPANIES_INVOICES_STATUS, getAdminCompaniesInvoicesStatus);
}

export function* getAdminCompaniesInvoicesStatus(action) {
  try {
    const response = yield call(adminReportServices.companiesInvoicesStatusList, action.payload);
    if (response.success) {
      yield put(actions.getAdminCompaniesInvoicesStatusSuccess(response.data));
    } else {
      yield put(actions.getAdminCompaniesInvoicesStatusFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getAdminCompaniesInvoicesStatusFail(error));
  }
}
