import './AddCompany.css';

import React, { Component } from 'react';
import { Field, Form, reduxForm } from "redux-form";
import {
  renderField,
  renderHiddenInputField,
  renderPhoneField
} from '../UI/reduxForm';

import phoneFormat from 'phoneformat.js';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import LoadingIcon from '../UI/LoadingIcon';
import Select from '../UI/reactSelect/ReactSelect';
import Validates from '../UI/reduxForm/validates/Validates';

const selectStyles = {
  control: base => ({
    ...base,
    minHeight: 25,
    height: '3.1rem',
    margin: '0',
    backgroundColor: '#ffffff',
    border: 'none',
    borderRight: '1px solid #9e9e9e !important',
    borderBottom: '1px solid #9e9e9e !important',
    borderRadius: 'none !important',
    boxShadow: 'none',
    textAlign: 'center',
    '&:hover': {
      outline: '0 !important',
    },
    '&:focus': {
      outline: '0 !important',
      boxShadow: 'none !important'
    }
  }),
  indicatorSeparator: base => ({
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: '0',
    height: '2.5rem',
    paddingLeft: '5px'
  }),
  placeholder: base => ({
    ...base,
    top: '49%',
  }),
  singleValue: base => ({
    ...base,
    top: '49%'
  }),
  menu: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
    zIndex: '9999',
  }),
  menuList: base => ({
    ...base,
    width: 'max-content',
    minWidth: '100%',
  }),
};

// OrganisationForm
// const optionsOF = [
//   { value: 'AAFY', label: 'AAFY' },
//   { value: 'ADOS', label: 'ADOS' },
//   { value: 'ANNA', label: 'ANNA' },
//   { value: 'ANS', label: 'ANS' },
//   { value: 'AS', label: 'AS' },
//   { value: 'ASA', label: 'ASA' },
//   { value: 'BA', label: 'BA' },
//   { value: 'BBL', label: 'BBL' },
//   { value: 'BEDR', label: 'BEDR' },
//   { value: 'BO', label: 'BO' },
//   { value: 'BRL', label: 'BRL' },
//   { value: 'DA', label: 'DA' },
//   { value: 'ENK', label: 'ENK' },
//   { value: 'EOFG', label: 'EOFG' },
//   { value: 'ESEK', label: 'ESEK' },
//   { value: 'FKF', label: 'FKF' },
//   { value: 'FLI', label: 'FLI' },
//   { value: 'FYLK', label: 'FYLK' },
//   { value: 'GFS', label: 'GFS' },
//   { value: 'IKJP', label: 'IKJP' },
//   { value: 'IKS', label: 'IKS' },
//   { value: 'KBO', label: 'KBO' },
//   { value: 'KF', label: 'KF' },
//   { value: 'KIRK', label: 'KIRK' },
//   { value: 'KOMM', label: 'KOMM' },
//   { value: 'KS', label: 'KS' },
//   { value: 'KTRF', label: 'KTRF' },
//   { value: 'NUF', label: 'NUF' },
//   { value: 'OPMV', label: 'OPMV' },
//   { value: 'ORGL', label: 'ORGL' },
//   { value: 'PERS', label: 'PERS' },
//   { value: 'PK', label: 'PK' },
//   { value: 'PRE', label: 'PRE' },
//   { value: 'SA', label: 'SA' },
//   { value: 'SÆR', label: 'SÆR' },
//   { value: 'SAM', label: 'SAM' },
//   { value: 'SE', label: 'SE' },
//   { value: 'SF', label: 'SF' },
//   { value: 'SPA', label: 'SPA' },
//   { value: 'STAT', label: 'STAT' },
//   { value: 'STI', label: 'STI' },
//   { value: 'TVAM', label: 'TVAM' },
//   { value: 'UTLA', label: 'UTLA' },
//   { value: 'VPFO', label: 'VPFO' },
// ];


const styleSelect = {
  control: base => ({
    ...base,
    minHeight: 25,
    margin: '0 0 20px',
    backgroundColor: '#ffffff',
    border: 'none',
    borderBottom: '1px solid #9e9e9e',
    borderRadius: 'unset',
    boxShadow: 'none',
    '&:hover': {
      outline: '0 !important',
      border: 'none',
      borderBottom: '2px solid #03a9f4',
    },
    '&:focus': {
      outline: '0 !important',
    }

  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  valueContainer: base => ({
    ...base,
    padding: '0',
    height: '40px'
  })
};
class NewCompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OrganisationForm: '',
      countryPhoneCode: 'NO',
    };
  }

  componentDidMount() {
  }

  selectOrganizationForm = (evt) => {
    const { dispatch, change } = this.props;
    this.setState({ OrganisationForm: evt.value }, () => dispatch(change('OrganisationForm', evt.value)));
  }

  selectCountry = (evt) => {
    const { dispatch, change } = this.props;
    this.setState({ OfficeCountryCode: evt.value }, () => dispatch(change('OfficeCountryCode', evt.value)));
  }

  selectVATRegister = (evt) => {
    const { change } = this.props;
    change('VATRegister', evt.value);
  }

  setCountryPhoneCode = (countryPhoneCode) => {
    const { dispatch, change } = this.props;
    this.setState({ countryPhoneCode }, () => dispatch(change('PhoneCode', countryPhoneCode)));
  }

  validatePhoneNumber = value => {
    const { t } = this.props;
    return value && !phoneFormat.isValidNumber(value, this.state.countryPhoneCode)
      ? t('form:validation.phone')
      : undefined
  }

  render() {
    const { handleSubmit, submitting, t, initialValues, countries } = this.props;
    const optionsOF = [
      { value: 'AS', label: 'AS' },
      { value: 'ANS', label: 'ANS' },
      { value: 'DA', label: 'DA' },
      { value: 'ENK', label: 'ENK' },
      { value: 'PRIVATE', label: 'PRIVATE' },
    ];

    const lang = localStorage.getItem('lang') || 'en';

    const countriesOptions = [];
    if (countries) {
      countries.map((row, index) => {
        countriesOptions.push({ value: row.alpha_code, label: row[`name_${lang}`] });
      })
    }

    const ofSelected = this.state.OrganisationForm ? this.state.OrganisationForm : (initialValues?.OrganisationForm ? initialValues.OrganisationForm : '');
    const countrySelected = this.state.OfficeCountryCode ? this.state.OfficeCountryCode : (initialValues?.OfficeCountryCode ? initialValues.OfficeCountryCode : '');
    const disabled = initialValues?.disabled ? initialValues.disabled : false;
    const Phone = initialValues?.Phone ? initialValues.Phone : '';

    return (
      <Form
        onSubmit={handleSubmit}
        className="form-view-edit"
      >
        <div className="row">
          <div className='col s12 m6'>
            <Field
              id="CompanyName"
              name='CompanyName'
              label={t('company')}
              type="text"
              component={renderField}
              validate={[Validates.required]}
              disabled={disabled}
            />
          </div>

          <div className='col s12 m6'>
            <Field
              id="OrganisationNumber"
              name='OrganisationNumber'
              label={t('organization_number')}
              type="text"
              component={renderField}
              disabled={disabled}
            />
          </div>
        </div>

        <div className="row">
          <div className='col s12 m2 input-field'>
            <label className="active" htmlFor="OrganisationForm">{t('organization_form')}</label>
            <Select
              selectedLabel={ofSelected}
              groupOptions={optionsOF}
              handleSelect={this.selectOrganizationForm}
              styles={styleSelect}
              disabled={disabled}
            />
            <Field
              id='OrganisationForm'
              name='OrganisationForm'
              component={renderHiddenInputField}
              validate={Validates.required}
            />
          </div>

          <div className='col s12 m2 input-field'>
            <label className="active" htmlFor="OfficeCountryCode">{t('countries_id')}</label>
            <Select
              selectedLabel={countrySelected}
              groupOptions={countriesOptions}
              handleSelect={this.selectCountry}
              styles={styleSelect}
              disabled={disabled}
            />
            <Field
              id='OfficeCountryCode'
              name='OfficeCountryCode'
              component={renderHiddenInputField}
              validate={Validates.required}
            />
          </div>
        </div>

        <div className="row">
          <div className='col s12 m6'>
            <div className='row'>
              <div className="col s12 m12 icon-text">
                <span>{t('office_address')}</span>
              </div>
              <div className='col s12 m6'>
                <Field
                  id="OfficeAddress1"
                  name='OfficeAddress1'
                  label={t('office_address1')}
                  type="text"
                  component={renderField}
                  disabled={disabled}
                />
              </div>
              <div className='col s12 m6'>
                <Field
                  id="OfficeAddress2"
                  name='OfficeAddress2'
                  label={t('office_address2')}
                  type="text"
                  component={renderField}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>

          <div className='col s12 m6'>
            <div className='row'>
              <div className=" col s12 m12 icon-text">
                <span>{t('post_address')}</span>
              </div>
              <div className='col s12 m6'>
                <Field
                  id="Address1"
                  name='Address1'
                  label={t('address1')}
                  type="text"
                  component={renderField}
                  disabled={disabled}
                />
              </div>
              <div className='col s12 m6'>
                <Field
                  id="Address2"
                  name='amoun2'
                  label={t('address2')}
                  type="text"
                  component={renderField}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className='col s12 m6'>
            <div className='row'>
              <div className='col s12 m4'>
                <Field
                  id='OfficeZipCode'
                  name='OfficeZipCode'
                  label={t('zip_code')}
                  component={renderField}
                  disabled={disabled}
                />
              </div>
              <div className='col s12 m4'>
                <Field
                  id='OfficeDistrict'
                  name='OfficeDistrict'
                  label={t('district')}
                  component={renderField}
                  disabled={disabled}
                />
              </div>
              <div className='col s12 m4'>
                <Field
                  id='OfficeCity'
                  name='OfficeCity'
                  label={t('city')}
                  component={renderField}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>

          <div className='col s12 m6'>
            <div className='row'>
              <div className='col s12 m4'>
                <Field
                  id='ZipCode'
                  name='ZipCode'
                  label={t('zip_code')}
                  component={renderField}
                  disabled={disabled}
                />
              </div>
              <div className='col s12 m4'>
                <Field
                  id='District'
                  name='District'
                  label={t('district')}
                  component={renderField}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className='col s12 m6'>
            <Field
              id='Website'
              name='Website'
              label={t('website')}
              type="text"
              component={renderField}
            />
          </div>
        </div>

        <div className="row">
          <div className='col s12 m6'>
            <Field
              id='Email'
              name='Email'
              label={t('email')}
              type="text"
              component={renderField}
              validate={[Validates.required, Validates.email]}
            />
          </div>

          <div className='col s12 m6'>
            <Field
              id="Phone"
              name="Phone"
              type="text"
              component={renderPhoneField}
              label={t('phone_number')}
              data={countries}
              defaultValue={Phone}
              setCountryCode={this.setCountryPhoneCode}
              validate={[Validates.required, this.validatePhoneNumber]}
              selectStyles={selectStyles}
              isBorder={false}
              isBorderRadius={false}
              fieldClass="input-phone-number-custom"
            />
          </div>
        </div>

        <div className='row'>
          <div className='col s12 text-center'>
            <button
              type='submit'
              className="btn"
              disabled={submitting}
            >
              {!submitting && t('register_new_company')}
              <LoadingIcon loading={submitting} />
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const FormNewCompany = reduxForm({
  form: 'formNewCompany',
  keepDirtyOnReinitialize: true,
  enableReinitialize: true,
  updateUnregisteredFields: true
})(NewCompanyForm);

const mapStateToProps = state => {
  return {
    countries: state.options.countries,
  };
};

export default connect(mapStateToProps)(withNamespaces(['company', 'form'])(FormNewCompany));
