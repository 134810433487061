import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  get: async params => {
    try {
      const url = api.salesMans.get.replace(':id', params.Id);
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  list: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.salesMans.list}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  create: async params => {
    try {
      const url = api.salesMans.create;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.salesMans.update.replace(':id', params.Id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.salesMans.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  deleteByUsersId: async (companies_id, users_id) => {
    try {
      const url = api.salesMans.deleteByUsersId.replace(':companies_id', companies_id).replace(':users_id', users_id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
