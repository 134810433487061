import * as types from '../actionTypes';

const initialState = {
  loading: false,
  loadingKidOcr: false,
  companiesService: null,
  companiesServices: [],
  payments: {
    rows: [],
    total: 0
  },
  kidOcr: null,
  kidOcrs: {
    rows: [],
    total: 0
  },
  kraviaServices: null,

};

const companiesServices = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANIES_SERVICES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case types.COMPANIES_SERVICES.SET_LOADING_OCR:
      return {
        ...state,
        loadingKidOcr: action.payload,
      };

    case types.COMPANIES_SERVICES.GET_COMPANIES_SERVICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.COMPANIES_SERVICES.GET_COMPANIES_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesService: action.payload
      };
    case types.COMPANIES_SERVICES.GET_COMPANIES_SERVICE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case types.COMPANIES_SERVICES.GET_PAYMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.COMPANIES_SERVICES.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        payments: action.payload
      };
    case types.COMPANIES_SERVICES.GET_PAYMENTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case types.COMPANIES_SERVICES.GET_KID_OCR:
      return {
        ...state,
        loadingKidOcr: true,
      };
    case types.COMPANIES_SERVICES.GET_KID_OCR_SUCCESS:
      return {
        ...state,
        loadingKidOcr: false,
        kidOcr: action.payload
      };
    case types.COMPANIES_SERVICES.GET_KID_OCR_FAIL:
      return {
        ...state,
        loadingKidOcr: false,
      };

    case types.COMPANIES_SERVICES.GET_KID_OCRS:
      return {
        ...state,
        loadingKidOcr: true,
      };
    case types.COMPANIES_SERVICES.GET_KID_OCRS_SUCCESS:
      return {
        ...state,
        loadingKidOcr: false,
        kidOcrs: action.payload
      };
    case types.COMPANIES_SERVICES.GET_KID_OCRS_FAIL:
      return {
        ...state,
        loadingKidOcr: false,
      };

    case types.COMPANIES_SERVICES.GET_KRAVIA_SERVICES:
      return {
        ...state,
        loading: true,
      };
    case types.COMPANIES_SERVICES.GET_KRAVIA_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        kraviaServices: action.payload
      };
    case types.COMPANIES_SERVICES.GET_KRAVIA_SERVICES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case types.COMPANIES_SERVICES.GET_COMPANIES_SERVICES:
      return {
        ...state,
        loading: true,
      };
    case types.COMPANIES_SERVICES.GET_COMPANIES_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        companiesServices: action.payload
      };
    case types.COMPANIES_SERVICES.GET_COMPANIES_SERVICES_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
export default companiesServices;
