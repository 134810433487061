import * as types from '../actionTypes';

export const setFilterOffers = payload => ({
  type: types.OFFERS.SET_FILTER_OFFERS,
  payload
});
export const resetFilterOffers = payload => ({
  type: types.OFFERS.RESET_FILTER_OFFERS,
  payload
});

// LIST
export const getOffers = payload => {
  return {
    type: types.OFFERS.GET_OFFERS_REQUEST,
    payload
  };
};
export const getOffersSuccess = payload => ({
  type: types.OFFERS.GET_OFFERS_SUCCESS,
  payload
});
export const getOffersFail = payload => ({
  type: types.OFFERS.GET_OFFERS_FAIL,
  payload
});