import { call, put, takeEvery } from 'redux-saga/effects';
import salesManServices from '../../../services/sales-man.services';
import * as actions from '../../actions';
import * as types from '../../actionTypes';

export default function* getSalesMansSaga() {
  yield takeEvery(types.SALES_MAN.GET_SALES_MANS, getSalesMans);
}

export function* getSalesMans(action) {
  try {
    const response = yield call(salesManServices.list, action.payload);    
    if (response.success) {
      yield put(actions.getSalesMansSuccess(response.data));
    } else {
      yield put(actions.getSalesMansFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getSalesMansFail(error));
  }
}
