import * as types from '../actionTypes';

const resetFilter = {
  keyword: '',
  companies_id: 0,
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  invoiceDrafts: {
    total: 0,
    rows: []
  },
  invoicesDraft: null,
};

const invoiceDrafts = (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICE_DRAFTS.SET_FILTER_INVOICES_DRAFT:
      return {
        ...state,
        filter: action.payload
      };

    case types.INVOICE_DRAFTS.RESET_FILTER_INVOICES_DRAFT:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.INVOICE_DRAFTS.GET_INVOICE_DRAFTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DRAFTS.GET_INVOICE_DRAFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDrafts: action.payload
      };
    case types.INVOICE_DRAFTS.GET_INVOICE_DRAFTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    // DELETE
    case types.INVOICE_DRAFTS.DELETE_INVOICES_DRAFT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DRAFTS.DELETE_INVOICES_DRAFT_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case types.INVOICE_DRAFTS.DELETE_INVOICES_DRAFT_REQUEST_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceDrafts;
