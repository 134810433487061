import * as types from '../actionTypes';

const resetFilter = {
  keyword: '',
  type: '',
  status: '',
  companies_id: 0,
  sales_man_id: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  offers: {
    total: 0,
    rows: []
  },
};

const orders = (state = initialState, action) => {
  switch (action.type) {
    case types.OFFERS.SET_FILTER_OFFERS:
      return {
        ...state,
        filter: action.payload
      };
    case types.OFFERS.RESET_FILTER_OFFERS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.OFFERS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // LIST
    case types.OFFERS.GET_OFFERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.OFFERS.GET_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        offers: action.payload
      };
    case types.OFFERS.GET_OFFERS_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default orders;
