export const CUSTOMER_DISCOUNTS = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_CUSTOMER_DISCOUNTS: 'SET_FILTER_CUSTOMER_DISCOUNTS',
  RESET_FILTER_CUSTOMER_DISCOUNTS: 'RESET_FILTER_CUSTOMER_DISCOUNTS',

  GET_CUSTOMER_DISCOUNTS: 'GET_CUSTOMER_DISCOUNTS',
  GET_CUSTOMER_DISCOUNTS_SUCCESS: 'GET_CUSTOMER_DISCOUNTS_SUCCESS',
  GET_CUSTOMER_DISCOUNTS_FAIL: 'GET_CUSTOMER_DISCOUNTS_FAIL',
};
