export const CUSTOMER_ORDERS = {
  SET_LOADING: 'SET_LOADING',

  SET_FILTER_CUSTOMER_ORDERS: 'SET_FILTER_CUSTOMER_ORDERS',
  RESET_FILTER_CUSTOMER_ORDERS: 'RESET_FILTER_CUSTOMER_ORDERS',

  GET_CUSTOMER_ORDERS: 'GET_CUSTOMER_ORDERS',
  GET_CUSTOMER_ORDERS_SUCCESS: 'GET_CUSTOMER_ORDERS_SUCCESS',
  GET_CUSTOMER_ORDERS_FAIL: 'GET_CUSTOMER_ORDERS_FAIL',
};
