export const CONTRACTS = {
  SET_FILTER_CONTRACTS: 'SET_FILTER_CONTRACTS',
  RESET_FILTER_CONTRACTS: 'RESET_FILTER_CONTRACTS',

  GET_CONTRACTS: 'GET_CONTRACTS',
  GET_CONTRACTS_SUCCESS: 'GET_CONTRACTS_SUCCESS',
  GET_CONTRACTS_FAIL: 'GET_CONTRACTS_FAIL',
};


