import * as types from '../actionTypes';

const resetFilter = {
  keyword: '',
  date_option: 2,
  date_from: '',
  date_to: '',
  status: '',
  sortBy: 'payment_date',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  kraviaTransactions: {
    total: 0,
    rows: []
  },
  kraviaTransactionsNotMatch: {
    total: 0,
    rows: []
  },
  kraviaTransaction: {}
};

const kraviaTransactions = (state = initialState, action) => {
  switch (action.type) {
    case types.KRAVIA_TRANSACTIONS.SET_FILTER_KRAVIA_TRANSACTIONS:
      return {
        ...state,
        filter: action.payload
      };

    case types.KRAVIA_TRANSACTIONS.RESET_FILTER_KRAVIA_TRANSACTIONS:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    // LIST
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS:
      return {
        ...state,
        loading: true
      };
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        kraviaTransactions: action.payload
      };
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false
      };

    // LIST NOT MATCH
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_NOT_MATCH:
      return {
        ...state,
        loading: true
      };
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_NOT_MATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        kraviaTransactionsNotMatch: action.payload
      };
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS_NOT_MATCH_FAIL:
      return {
        ...state,
        loading: false
      };

    // GET
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTION:
      return {
        ...state,
        loading: true
      };
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        kraviaTransaction: action.payload
      };
    case types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTION_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default kraviaTransactions;
