import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import repeatingInvoicesServices from '../../../services/repeating-invoices.services';

export default function* getRepeatingInvoiceSaga() {
  yield takeEvery(types.REPEATING_INVOICE.GET_REPEATING_INVOICE_REQUEST, getRepeatingInvoice);
}

export function* getRepeatingInvoice(action) {
  try {
    const response = yield call(repeatingInvoicesServices.get, action.payload);
    if (response.success) {
      yield put(actions.getRepeatingInvoiceSuccess(response.data));
    } else {
      yield put(actions.getRepeatingInvoiceFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getRepeatingInvoiceFail(error));
  }
}
