import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getAdminCreditCheckedSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_ADMIN_CREDIT_CHECKED, getAdminCreditChecked);
}

export function* getAdminCreditChecked(action) {
  try {
    const response = yield call(adminReportServices.creditChecked, action.payload);
    if (response.success) {
      yield put(actions.getAdminCreditCheckedSuccess(response.data));
    } else {
      yield put(actions.getAdminCreditCheckedFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getAdminCreditCheckedFail(error));
  }
}
