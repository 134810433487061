import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceTypeSettingsServices from '../../../services/invoice-type-settings.services';

export default function* getInvoiceTypeSettingSaga() {
  yield takeEvery(types.INVOICE_TYPE_SETTINGS.GET_INVOICE_TYPE_SETTINGS_REQUEST, getInvoiceTypeSetting);
}

export function* getInvoiceTypeSetting(action) {
  try {
    const response = yield call(invoiceTypeSettingsServices.get, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceTypeSettingSuccess(response.data));
    } else {
      yield put(actions.getInvoiceTypeSettingFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceTypeSettingFail(error));
  }
}
