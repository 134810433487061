import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import productsServices from '../../../services/products.services';

export default function* deleteProductsSaga() {
  yield takeEvery(types.PRODUCTS.DELETE_PRODUCTS_REQUEST, deleteProducts);
}

export function* deleteProducts(action) {
  try {
    const response = yield call(productsServices.delete, action.payload);
    if (response.success) {
      yield put(actions.deleteProductsSuccess(response.data));
    } else {
      yield put(actions.deleteProductsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.deleteProductsFail(error));
  }
}