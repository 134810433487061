export const IMPORT_LOG = {
  SET_FILTER_IMPORT_LOG: 'SET_FILTER_IMPORT_LOG',
  RESET_FILTER_IMPORT_LOG: 'RESET_FILTER_IMPORT_LOG',

  GET_IMPORT_LOGS: 'GET_IMPORT_LOGS',
  GET_IMPORT_LOGS_SUCCESS: 'GET_IMPORT_LOGS_SUCCESS',
  GET_IMPORT_LOGS_FAIL: 'GET_IMPORT_LOGS_FAIL',
};


