import * as actionTypes from '../actionTypes';

export const getForwardSuccessData = payload => {
  return {
    type: actionTypes.EMAIL_FORWARD.GET_FORWARD_SUCCESS_DATA,
    payload
  };
};
export const setForwardSuccessData = payload => {
  return {
    type: actionTypes.EMAIL_FORWARD.SET_FORWARD_SUCCESS_DATA,
    payload
  };
};
