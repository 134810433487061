import React, { Component } from 'react';
import './MandateGenerate.css';

import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mandateServices from '../../services/mandate.services';

class MandateGenerate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  componentDidMount = async () => {
    this.init();
  }

  componentDidUpdate = async (prevProps) => {
    const { match } = this.props;
    if (prevProps.match !== match) {
      this.init();
    }
  }

  init = async () => {
    const { t, match } = this.props;
    const token_access = match.params.token_access;
    if (token_access) {
      const response = await mandateServices.creditorCampaign({ token_access })
      if (response.success && response.data.short_url) {
        window.location.href = response.data.short_url
      } else {
        this.setState({ message: t('request_failed') })
      }
    } else {
      this.setState({ message: 'Forbidden' })
    }
  }

  rebuildRouter = router => {
    const { match } = this.props;
    if (match.params.company !== 'company') {
      return router.replace(':company', match.params.company);
    }
    return router;
  }

  render() {
    const { t } = this.props;
    const { message } = this.state;
    return (
      <div className="row">
        <div className="col s12">
          <div className="container">
            <div className="section">

              <div className="row">
                <div className="col s12">
                  <div className="errorMessage">
                    {message && message}
                  </div>
                </div>
              </div>

            </div>
            <div className="content-overlay" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const filter = state.customerOverview.filter;
  const company = state.company.company;

  return {
    filter,
    company,
  };
};
export default withRouter(connect(mapStateToProps)(withNamespaces('invoices')(MandateGenerate)));
