import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.invoices.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async params => {
    try {
      const { id, companies_id, ...query } = params
      let url = api.invoices.item.replace(':companies_id', params.companies_id).replace(':id', params.id);
      if (query) {
        url = `${api.invoices.item.replace(':companies_id', params.companies_id).replace(':id', params.id)}?${httpHelper.serialize(query)}`;
      }
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  create: async params => {
    try {
      const url = api.invoices.create;
      const data = await BaseServices.postDataTokenFormV2(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  update: async params => {
    try {
      const url = api.invoices.update.replace(':id', params.id);
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.invoices.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  preview: async params => {
    try {
      const url = api.invoices.preview;
      const response = await BaseServices.postDataTokenFormV2(url, params);
      if (response.status !== 200) {
        return await response.json();
      } else {
        return await response.blob();
      }
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  sendInvoice: async params => {
    try {
      const url = api.invoices.sendInvoice;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  lastInvoiceNo: async params => {
    try {
      const url = api.invoices.lastInvoiceNo;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  sendEhf: async params => {
    try {
      const url = api.invoices.sendEhf;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  confirm: async params => {
    try {
      const url = api.invoices.confirmInvoice;
      const data = await BaseServices.postData({ url, data: params });
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getInvoiceMatching: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = queryParams ? `${api.invoices.getInvoiceMatching}?${queryParams}` : api.invoices.getInvoiceMatching;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getXmlDocument: async params => {
    try {
      const url = `${api.invoices.getXmlDocument.replace(':xml_path', params.xml_path)}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      return await data.text();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  accountingDetail: async params => {
    try {
      const url = api.invoices.accountingDetail;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  reCreateInvoice: async params => {
    try {
      const url = api.invoices.reCreateInvoice;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  invoiceDocument: async params => {
    try {
      const url = api.invoices.invoiceDocument;
      const data = await BaseServices.postData({ url, data: params });
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  uploadInvoice: async params => {
    try {
      const url = api.invoices.uploadInvoice;
      const data = await BaseServices.postDataTokenForm(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  paymentCredit: async params => {
    try {
      const url = api.invoices.paymentCredit;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  registerPaymentCredit: async params => {
    try {
      const url = api.invoices.registerPaymentCredit;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
