import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerOrdersServices from '../../../services/customer-orders.services';

export default function* getCustomerOrdersSaga() {
  yield takeEvery(types.CUSTOMER_ORDERS.GET_CUSTOMER_ORDERS, getCustomerOrders);
}

export function* getCustomerOrders(action) {
  try {
    const response = yield call(customerOrdersServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCustomerOrdersSuccess(response.data));
    } else {
      yield put(actions.getCustomerOrdersFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerOrdersFail(error));
  }
}
