import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceDistributionServices from '../../../services/invoice-distribution.services';

export default function* getInvoiceDistributionsSaga() {
  yield takeEvery(types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTIONS_REQUEST, getInvoiceDistributions);
}

export function* getInvoiceDistributions(action) {
  try {
    const response = yield call(invoiceDistributionServices.list, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceDistributionsSuccess(response.data));
    } else {
      yield put(actions.getInvoiceDistributionsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceDistributionsFail(error));
  }
}
