import creditCheckServices from '../../../../services/v2/credit-check.services';
import * as actions from '../../../actions';
import * as types from '../../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

export default function* creditCheckSearchSaga() {
  yield takeEvery(types.CREDIT_CHECK.CREDIT_CHECK_SEARCH, creditCheckSearch);
}

export function* creditCheckSearch(action) {
  try {
    const response = yield call(creditCheckServices.search, action.payload);
    if (!response.status) {
      yield put(actions.creditCheckSearchSuccess(response.data));
    } else {
      yield put(actions.creditCheckSearchFail(response?.message));
    }
  } catch (error) {
    yield put(actions.creditCheckSearchFail(error));
  }
}
