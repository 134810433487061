import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesUsersServices from '../../../services/companies-users.services';

export default function* getCompaniesUsersSaga() {
  yield takeEvery(types.COMPANIES_USERS.GET_COMPANIES_USERS_REQUEST, getCompaniesUsers);
}

export function* getCompaniesUsers(action) {
  try {
    const response = yield call(companiesUsersServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCompaniesUsersSuccess(response.data));
    } else {
      yield put(actions.getCompaniesUsersFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCompaniesUsersFail(error));
  }
}
