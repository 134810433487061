import './PreviewModal.css';


import React from 'react';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import invoiceNoteServices from '../../../services/invoiceNote.services';
import invoicesServices from '../../../services/invoices.services';
import kraviaInvoiceDocumentsServices from '../../../services/kravia-invoice-documents.services';
import repeatingInvoicesServices from '../../../services/repeating-invoices.services';
import ModalDraggable from '../../UI/modal-draggable/ModalDraggable';

class ViewDocumentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urlView: '',
      loading: true,
    };
  }

  componentDidMount = async () => {
    const { openModal, invoice } = this.props;
    openModal && invoice && await this.init();
  }

  componentDidUpdate = async (prevProps) => {
    const { openModal, invoice } = this.props;
    if (prevProps.invoice !== invoice && openModal === true) {
      await this.init();
    }
  }

  init = async () => {
    const { t, invoice, accounting, subscription, viewNote, kraviaDocument } = this.props;
    this.setState({ loading: true, urlView: '' });
    let response = {}
    if (accounting) {
      response = await invoicesServices.accountingDetail({ id: invoice?.id, companies_id: invoice?.companies_id });
    } else if (subscription) {
      response = await repeatingInvoicesServices.preview(invoice.subscription_id);
    } else if (viewNote) {
      response = await invoiceNoteServices.previewFile({ id: invoice?.id, companies_id: invoice?.companies_id });
    } else if (kraviaDocument) {
      response = await kraviaInvoiceDocumentsServices.preview({ id: invoice?.id, companies_id: invoice?.companies_id });
    } else {
      response = await invoicesServices.invoiceDocument({ id: invoice?.id, companies_id: invoice?.companies_id });
    }
    if (response.success === false) {
      Swal.fire({
        title: t('error'),
        text: t('invoice_document_not_found'),
        icon: 'error',
      });
    } else {
      if (subscription) {
        this.setState({ urlView: URL.createObjectURL(response), openViewModal: true })
      } else {
        const { invoice_document_url, invoice_accounting_document_url, invoice_note_file_url, kravia_document_url } = response.data;
        const urlView = accounting ? invoice_accounting_document_url : viewNote ? invoice_note_file_url : kraviaDocument ? kravia_document_url : invoice_document_url;
        this.setState({ urlView, openViewModal: true })
      }
    }
    this.setState({ loading: false });
  }

  hideLoading = () => {
    this.setState({ loading: false });
  }

  render() {
    const { t, openModal, onClose } = this.props;
    const { loading, urlView } = this.state;
    return (
      <ModalDraggable
        loading={loading}
        title={t('invoice')}
        open={openModal}
        onOk={onClose}
        onCancel={onClose}
        width={'850px'}
        bodyStyle={{ height: '90vh', padding: 0 }}
        content={urlView && (
          <iframe src={urlView}
            title="Invoice preview"
            height="100%"
            width="100%"
            onLoad={this.hideLoading}
            style={{ border: 'none' }}>
          </iframe>
        )}
      />
    );
  }

}

const mapStateToProps = state => {
  return {};
};

export default withRouter(connect(mapStateToProps)(withNamespaces('invoices')(ViewDocumentModal)));
