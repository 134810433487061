import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import kraviaInvoicesServices from '../../../services/kravia-invoices.services';

export default function* getKraviaInvoiceSaga() {
  yield takeEvery(types.KRAVIA_INVOICES.GET_KRAVIA_INVOICE, getKraviaInvoice);
}

export function* getKraviaInvoice(action) {
  try {
    const response = yield call(kraviaInvoicesServices.get, action.payload);
    if (response.success) {
      yield put(actions.getKraviaInvoiceSuccess(response.data));
    } else {
      yield put(actions.getKraviaInvoiceFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getKraviaInvoiceFail(error));
  }
}
