import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import adminReportServices from '../../../services/admin-report.services';

export default function* getCreditStatisticsSaga() {
  yield takeEvery(types.ADMIN_REPORT.GET_CREDIT_STATISTICS, getCreditStatistics);
}

export function* getCreditStatistics(action) {
  try {
    const response = yield call(adminReportServices.creditStatistics, action.payload);
    if (response.success) {
      yield put(actions.getCreditStatisticsSuccess(response.data));
    } else {
      yield put(actions.getCreditStatisticsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCreditStatisticsFail(error));
  }
}
