import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import bankAccountServices from '../../../services/bankAccount.services';

export default function* getBankAccountCompanySaga() {
  yield takeEvery(types.BANK_ACCOUNT.GET_BANK_ACCOUNT_COMPANY_REQUEST, getBankAccountCompany);
}

export function* getBankAccountCompany(action) {
  try {
    const response = yield call(bankAccountServices.getBankByCompany, action.payload);
    if (response.success) {
      yield put(actions.getBankAccountCompanySuccess(response.data ? response.data[0] : {}));
    } else {
      yield put(actions.getBankAccountCompanyFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getBankAccountCompanyFail(error));
  }
}
