import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import repeatingInvoicesServices from '../../../services/repeating-invoices.services';

export default function* getRepeatingInvoicesSaga() {
  yield takeEvery(types.REPEATING_INVOICE.FETCH_REPEATING_INVOICE_DATA_REQUEST, getRepeatingInvoices);
}

export function* getRepeatingInvoices(action) {
  try {
    const response = yield call(repeatingInvoicesServices.list, action.payload);
    if (response.success) {
      yield put(actions.getRepeatingInvoicesSuccess(response.data));
    } else {
      yield put(actions.getRepeatingInvoicesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getRepeatingInvoicesFail(error));
  }
}
