import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import InvoiceSettingServices from '../../../services/invoiceSettings.services';

export default function* getInvoiceSettingSaga() {
  yield takeEvery(types.INVOICE_SETTINGS.GET_INVOICE_SETTINGS_REQUEST, getInvoiceSetting);
}

export function* getInvoiceSetting(action) {
  try {
    const response = yield call(InvoiceSettingServices.get, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceSettingSuccess(response.data));
    } else {
      yield put(actions.getInvoiceSettingFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceSettingFail(error));
  }
}
