import { APIError } from '../constants/errorCode/system';
import BaseServices from './base.services';
import { api } from '../constants/api.constant';
import httpHelper from '../helpers/httpHelper';

export default {
  get: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.getCompaniesPaymentPackages.get.replace(':companies_id', params.companies_id)}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  buy: async params => {
    try {
      const url = api.getCompaniesPaymentPackages.buy;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  creditCheck: async params => {
    try {
      const url = api.getCompaniesPaymentPackages.creditCheck;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  getCreditCheck: async params => {
    try {
      const query = httpHelper.serialize(params);
      const url = `${api.getCompaniesPaymentPackages.get.replace(':companies_id', params.companies_id)}?${query}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  changeStatus: async params => {
    try {
      const url = api.getCompaniesPaymentPackages.update;
      const data = await BaseServices.putDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
