import * as types from '../actionTypes';

const initialState = {
  countries: null,
  currency: null,
  invoiceLanguage: null,
  customer: null,
  products: null,
  contactPerson: null,
  users: null,
  projects: null,
  salesman: null,
  vatCodeSettings: null,
  paymentPackages: null,
};

const options = (state = initialState, action) => {
  switch (action.type) {
    // LIST
    case types.OPTIONS.FETCH_OPTIONS_DATA_REQUEST:
      return {
        ...state
      };
    case types.OPTIONS.FETCH_OPTIONS_DATA_SUCCESS:
      return {
        ...state,
        countries: action.payload.countries,
        currency: action.payload.currency,
        invoiceLanguage: action.payload.invoiceLanguage,
        customer: action.payload.customer,
        products: action.payload.products,
        contactPerson: action.payload.contactPerson,
        users: action.payload.users,
        projects: action.payload.projects,
        salesman: action.payload.salesman,
        vatCodeSettings: action.payload.vatCodeSettings,
        paymentPackages: action.payload.paymentPackages,
      };
    case types.OPTIONS.FETCH_OPTIONS_DATA_FAIL:
      return {
        ...state
      };
    default:
      return state;
  }
};
export default options;
