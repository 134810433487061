import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: {
    invoiceNote: false,
    create: false,
  },
  filter,
  invoiceNotes: {
    total: 0,
    rows: []
  }
};

const invoiceNote = (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICE_NOTE.SET_FILTER_INVOICE_NOTE:
      return {
        ...state,
        filter: action.payload
      };

    // LIST
    case types.INVOICE_NOTE.FETCH_INVOICE_NOTE_DATA_REQUEST:
      return {
        ...state,
        loading: {
          invoiceNote: true
        }
      };
    case types.INVOICE_NOTE.FETCH_INVOICE_NOTE_DATA_SUCCESS:
      return {
        ...state,
        loading: {
          invoiceNote: false
        },
        invoiceNotes: action.payload
      };
    case types.INVOICE_NOTE.FETCH_INVOICE_NOTE_DATA_FAIL:
      return {
        ...state,
        loading: {
          invoiceNote: false
        }
      };

    // ADD
    case types.INVOICE_NOTE.SAVE_INVOICE_NOTE_REQUEST:
      return {
        ...state,
        loading: {
          create: true
        }
      };
    case types.INVOICE_NOTE.SAVE_INVOICE_NOTE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: {
          create: false
        }
      };
    case types.INVOICE_NOTE.SAVE_INVOICE_NOTE_REQUEST_FAIL:
      return {
        ...state,
        loading: {
          create: false
        }
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceNote;
