import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import customerDiscountsServices from '../../../services/customer-discounts.services';

export default function* getCustomerDiscountsSaga() {
  yield takeEvery(types.CUSTOMER_DISCOUNTS.GET_CUSTOMER_DISCOUNTS, getCustomerDiscounts);
}

export function* getCustomerDiscounts(action) {
  try {
    const response = yield call(customerDiscountsServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCustomerDiscountsSuccess(response.data));
    } else {
      yield put(actions.getCustomerDiscountsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerDiscountsFail(error));
  }
}
