import * as types from '../actionTypes';

// LIST
export const fetchInvoiceBroadcasts = payload => {
  return {
    type: types.INVOICE_BROADCASTS.FETCH_INVOICE_BROADCASTS_DATA_REQUEST,
    payload
  };
};
export const fetchInvoiceBroadcastsSuccess = payload => ({
  type: types.INVOICE_BROADCASTS.FETCH_INVOICE_BROADCASTS_DATA_SUCCESS,
  payload
});
export const fetchInvoiceBroadcastsFail = payload => ({
  type: types.INVOICE_BROADCASTS.FETCH_INVOICE_BROADCASTS_DATA_FAIL,
  payload
});
