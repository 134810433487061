import * as types from '../actionTypes';

export default {
  setBreadcrumb: data => ({ type: types.SYSTEM.Breadcrumb, payload: data })
}

export * from './accountants.actions';
export * from './admin-report.actions';
export * from './avtalegiro.actions';
export * from './bankAccount.actions';
export * from './companies-invoice-services-except.actions';
export * from './companies-payment-packages.actions';
export * from './companies-services.actions';
export * from './companies-suspend.actions';
export * from './companies-users.actions';
export * from './company.actions';
export * from './credit-checking.actions';
export * from './customer-contact.actions';
export * from './customer-discounts.actions';
export * from './customer-invoices.actions';
export * from './customer-notes.actions';
export * from './customer-orders.actions';
export * from './customer-overview.actions';
export * from './customer.actions';
export * from './email-forward.actions';
export * from './import-log.actions';
export * from './imports.actions';
export * from './invoice-distribution-receivers.actions';
export * from './invoice-distribution-run.actions';
export * from './invoice-distribution.actions';
export * from './invoice-drafts.actions';
export * from './invoice-type-settings.actions';
export * from './invoiceBroadcasts.actions';
export * from './invoiceHistory.actions';
export * from './invoiceNote.actions';
export * from './invoiceSettings.actions';
export * from './invoices.actions';
export * from './kravia-invoices.actions';
export * from './kravia-transactions.actions';
export * from './ocr-transactions.actions';
export * from './offers.actions';
export * from './options.actions';
export * from './orders.actions';
export * from './products.actions';
export * from './projects.actions';
export * from './reminder-settings.actions';
export * from './reminderInvoice.actions';
export * from './repeating-invoice.actions';
export * from './report.actions';
export * from './sales-man.actions';
export * from './salesman-settings.actions';
export * from './subscription-settings.actions';
export * from './user-invoice-services.actions';
export * from './users-permission.actions';
export * from './vat-code-settings.actions';
export * from './vat-settings.actions';
export * from './contracts.actions';
export * from './v2/credit-check.actions';

