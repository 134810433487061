import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import kraviaTransactionsServices from '../../../services/kravia-transactions.services';

export default function* getKraviaTransactionsSaga() {
  yield takeEvery(types.KRAVIA_TRANSACTIONS.GET_KRAVIA_TRANSACTIONS, getKraviaTransactions);
}

export function* getKraviaTransactions(action) {
  try {
    const response = yield call(kraviaTransactionsServices.list, action.payload);
    if (response.success) {
      yield put(actions.getKraviaTransactionsSuccess(response.data));
    } else {
      yield put(actions.getKraviaTransactionsFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getKraviaTransactionsFail(error));
  }
}
