import * as types from '../actionTypes';

// GET
export const getInvoiceTypeSetting = payload => {
  return {
    type: types.INVOICE_TYPE_SETTINGS.GET_INVOICE_TYPE_SETTINGS_REQUEST,
    payload
  };
};
export const getInvoiceTypeSettingSuccess = payload => ({
  type: types.INVOICE_TYPE_SETTINGS.GET_INVOICE_TYPE_SETTINGS_SUCCESS,
  payload
});
export const getInvoiceTypeSettingFail = payload => ({
  type: types.INVOICE_TYPE_SETTINGS.GET_INVOICE_TYPE_SETTINGS_FAIL,
  payload
});
