export const PROJECTS = {
  SET_FILTER_PROJECTS: 'SET_FILTER_PROJECTS',
  RESET_FILTER_PROJECTS: 'RESET_FILTER_PROJECTS',

  FETCH_PROJECTS_DATA_REQUEST: 'FETCH_PROJECTS_DATA_REQUEST',
  FETCH_PROJECTS_DATA_SUCCESS: 'FETCH_PROJECTS_DATA_SUCCESS',
  FETCH_PROJECTS_DATA_FAIL: 'FETCH_PROJECTS_DATA_FAIL',

  GET_LAST_PROJECTS_NO: 'GET_LAST_PROJECTS_NO',
  GET_LAST_PROJECTS_NO_SUCCESS: 'GET_LAST_PROJECTS_NO_SUCCESS',
  GET_LAST_PROJECTS_NO_FAIL: 'GET_LAST_PROJECTS_NO_FAIL',
};