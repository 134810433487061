import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ReportServices from '../../../services/report.services';

export default function* getDiscountReportSaga() {
  yield takeEvery(types.REPORT.GET_DISCOUNT_REPORT_REQUEST, getDiscountReport);
}

export function* getDiscountReport(action) {
  try {
    const response = yield call(ReportServices.discountReport, action.payload);
    if (response.success) {
      yield put(actions.getDiscountReportSuccess(response.data));
    } else {
      yield put(actions.getDiscountReportFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getDiscountReportFail(error));
  }
}
