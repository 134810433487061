import * as actions from '../../actions';
import * as types from '../../actionTypes';

import {call, put, takeEvery} from 'redux-saga/effects';

import customerServices from '../../../services/customer.services';

export default function* getCustomerDetailSaga() {
  yield takeEvery(types.CUSTOMER.GET_CUSTOMER_DETAIL, getCustomerDetail);
}

export function* getCustomerDetail(action) {
  try {
    const response = yield call(customerServices.get, action.payload);
    if (response.success) {
      yield put(actions.getCustomerDetailSuccess(response.data));
    }else{
      yield put(actions.getCustomerDetailFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCustomerDetailFail(error));
  }
}
