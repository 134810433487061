import './Loading.css';

import React from 'react';

class Loading extends React.PureComponent {
  render() {
    return (
      <div className={this.props.loading ? 'sweet-loading' : 'd-none'}>
        <div
          className="preloader-wrapper small active"
          style={this.props.size !== null && this.props.size !== undefined
            ? { width: this.props.size + 'px', height: this.props.size + 'px' }
            : {}}
        >
          <div className="spinner-layer">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Loading;