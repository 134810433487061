export const COMPANIES_USERS = {
  SET_FILTER_COMPANIES_USERS: 'SET_FILTER_COMPANIES_USERS',

  SET_USER_SELECTED : 'SET_USER_SELECTED',

  GET_COMPANIES_USERS_REQUEST: 'GET_COMPANIES_USERS_REQUEST',
  GET_COMPANIES_USERS_SUCCESS: 'GET_COMPANIES_USERS_SUCCESS',
  GET_COMPANIES_USERS_FAIL: 'GET_COMPANIES_USERS_FAIL',

  GET_ORYTON_USERS_REQUEST: 'GET_ORYTON_USERS_REQUEST',
  GET_ORYTON_USERS_SUCCESS: 'GET_ORYTON_USERS_SUCCESS',
  GET_ORYTON_USERS_FAIL: 'GET_ORYTON_USERS_FAIL',
};


