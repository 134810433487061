import * as types from '../actionTypes';

const resetFilterInvoices = {
  companies_id: 0,
  keyword: '',
  organization_number: '',
  customer_name: '',
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filterInvoices = { ...resetFilterInvoices };

const initialState = {
  loading: false,
  filterInvoices,
  invoices: {
    total: 0,
    rows: []
  },
};

const companiesSuspend = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANIES_SUSPEND.SET_FILTER_COMPANIES_SUSPEND_INVOICES:
      return {
        ...state,
        filter: action.payload
      };

    case types.COMPANIES_SUSPEND.RESET_FILTER_COMPANIES_SUSPEND_INVOICES:
      return {
        ...state,
        filter: { ...resetFilter }
      };

    case types.COMPANIES_SUSPEND.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    // invoices
    case types.COMPANIES_SUSPEND.GET_COMPANIES_SUSPEND_INVOICES:
      return {
        ...state,
        loading: true
      };
    case types.COMPANIES_SUSPEND.GET_COMPANIES_SUSPEND_INVOICES_SUCCESS:
      return {
        ...state,
        loading: false,
        invoices: action.payload
      };
    case types.COMPANIES_SUSPEND.GET_COMPANIES_SUSPEND_INVOICES_FAIL:
      return {
        ...state,
        loading: false,
      };

    //DEFAULT
    default:
      return state;
  }
};
export default companiesSuspend;
