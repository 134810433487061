import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceDistributionReceiversServices from '../../../services/invoice-distribution-receivers.services';

export default function* getInvoiceDistributionReceiversSaga() {
  yield takeEvery(types.INVOICE_DISTRIBUTION_RECEIVERS.GET_INVOICE_DISTRIBUTION_RECEIVERS_REQUEST, getInvoiceDistributionReceivers);
}

export function* getInvoiceDistributionReceivers(action) {
  try {
    const response = yield call(invoiceDistributionReceiversServices.list, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceDistributionReceiversSuccess(response.data));
    } else {
      yield put(actions.getInvoiceDistributionReceiversFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceDistributionReceiversFail(error));
  }
}
