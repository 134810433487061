import React from 'react';
import { connect } from 'react-redux';
import { CardCVCElement, CardExpiryElement, CardNumberElement, injectStripe } from 'react-stripe-elements';
import { webConfig } from '../../../../../configs/system.configs';
import { PaymentsUrl } from '../../../../../constants/routes.constants';
import i18n from '../../../../../i18n';
import paymentsServices from '../../../../../services/payments.services';
import * as types from '../../../../../store/actionTypes';
import { replaceParam } from '../../../../../utils/Utils';

const t = i18n.getFixedT();
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#9e2146'
      }
    }
  };
};

class InjectedCard extends React.Component {
  constructor(props) {
    super(props);
    this._onSubmit = this._onSubmit.bind(this);
  }

  state = {
    _errorForm: ''
  }

  _handleIsError = (data) => {
    if (data.error) {
      this.setState({
        _errorForm: data.error.message
      });
      // this.props.dispatch(SystemAction.Loading(false));
      return true;
    }

    return false;
  }

  _onSubmit = async (e) => {
    e.preventDefault();
    const { dispatch, setSuccess } = this.props;
    await dispatch({ type: types.PAYMENTS.SET_LOADING, payload: true });

    if (this.props.stripe) {
      const { data } = this.props;

      const token = await this.props.stripe.createToken();
      if (this._handleIsError(token)) {
        await dispatch({ type: types.PAYMENTS.SET_LOADING, payload: false });
        return true;
      }
      const cardId = token.token.card.id;
      const tokenId = token.token.id;
      const returnUrl = webConfig.webUrl + replaceParam(PaymentsUrl.PaymentThankYou, {
        ':uuid': data.uuid
      });
      const dataForm = {
        uuid: data.uuid,
        tokenId,
        cardId,
        returnUrl
      };

      let response = await paymentsServices.buy(dataForm);
      if (response.success) {
        response = response.data;
        setSuccess(true);
      } else {
        this.setState({
          _errorForm: response.message === 'subscription_already_paid' ? t('msgPaid') : response.message
        });
      }
    }

    await dispatch({ type: types.PAYMENTS.SET_LOADING, payload: false });
    return true;
  }

  render() {
    const { t } = this.props;
    return (
      <form onSubmit={this._onSubmit}>

        <div className="row">
          <div className="col s12">
            <label>
              {t('payments:cardNumber')}
              <CardNumberElement
                {...createOptions()}
              />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col s6">
            <label>
              {t('payments:expirationDate')}
              <CardExpiryElement
                {...createOptions()}
              />
            </label>
          </div>
          <div className="col s6">
            <label>
              {t('payments:cvc')}
              <CardCVCElement
                {...createOptions()}
              />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="col s12">
            <span className="helper-text red-text">{t(this.state._errorForm)}</span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col s12">
            <button type="submit" className="btn">
              {t('payments:btn_pay')}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default connect()(injectStripe(InjectedCard));
