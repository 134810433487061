import * as types from '../actionTypes';

// GET
export const getVatCodeSettings = payload => {
  return {
    type: types.VAT_CODE_SETTINGS.GET_VAT_CODE_SETTINGS_REQUEST,
    payload
  };
};
export const getVatCodeSettingsSuccess = payload => ({
  type: types.VAT_CODE_SETTINGS.GET_VAT_CODE_SETTINGS_SUCCESS,
  payload
});
export const getVatCodeSettingsFail = payload => ({
  type: types.VAT_CODE_SETTINGS.GET_VAT_CODE_SETTINGS_FAIL,
  payload
});
