import * as types from '../actionTypes';

export const setFilterOrders = payload => ({
  type: types.ORDERS.SET_FILTER_ORDERS,
  payload
});
export const resetFilterOrders = payload => ({
  type: types.ORDERS.RESET_FILTER_ORDERS,
  payload
});

// LIST
export const getOrders = payload => {
  return {
    type: types.ORDERS.GET_ORDERS_REQUEST,
    payload
  };
};
export const getOrdersSuccess = payload => ({
  type: types.ORDERS.GET_ORDERS_SUCCESS,
  payload
});
export const getOrdersFail = payload => ({
  type: types.ORDERS.GET_ORDERS_FAIL,
  payload
});