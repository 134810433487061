import * as actions from '../../../actions';
import * as types from '../../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import creditCheckServices from '../../../../services/v2/credit-check.services';

export default function* getCreditChecksSaga() {
  yield takeEvery(types.CREDIT_CHECK.GET_CREDIT_CHECKS, getCreditChecks);
}

export function* getCreditChecks(action) {
  try {
    const response = yield call(creditCheckServices.list, action.payload);
    if (!response.status) {
      yield put(actions.getCreditChecksSuccess(response.data));
    } else {
      yield put(actions.getCreditChecksFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCreditChecksFail(error));
  }
}
