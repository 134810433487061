import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const initialState = {
  loading: false,
  filter,
  invoiceDistributionReceivers: {
    total: 0,
    rows: []
  }
};

const invoiceDistributionReceivers = (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICE_DISTRIBUTION_RECEIVERS.SET_FILTER_INVOICE_DISTRIBUTION_RECEIVERS:
      return {
        ...state,
        filter: action.payload
      };

    // LIST
    case types.INVOICE_DISTRIBUTION_RECEIVERS.GET_INVOICE_DISTRIBUTION_RECEIVERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DISTRIBUTION_RECEIVERS.GET_INVOICE_DISTRIBUTION_RECEIVERS_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDistributionReceivers: action.payload
      };
    case types.INVOICE_DISTRIBUTION_RECEIVERS.GET_INVOICE_DISTRIBUTION_RECEIVERS_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceDistributionReceivers;
