export const OFFERS = {
  SET_FILTER_OFFERS: 'SET_FILTER_OFFERS',
  RESET_FILTER_OFFERS: 'RESET_FILTER_OFFERS',

  SET_LOADING: 'SET_LOADING',

  GET_OFFERS_REQUEST: 'GET_OFFERS_REQUEST',
  GET_OFFERS_SUCCESS: 'GET_OFFERS_SUCCESS',
  GET_OFFERS_FAIL: 'GET_OFFERS_FAIL',  
};


