import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import companiesInvoiceServicesExceptServices from '../../../services/companies-invoice-services-except.services';

export default function* getCompaniesInvoiceServicesExceptSaga() {
  yield takeEvery(types.COMPANIES_INVOICE_SERVICES_EXCEPT.GET_COMPANIES_INVOICE_SERVICES_EXCEPT, getCompaniesInvoiceServicesExcept);
}

export function* getCompaniesInvoiceServicesExcept(action) {
  try {
    const response = yield call(companiesInvoiceServicesExceptServices.list, action.payload);
    if (response.success) {
      yield put(actions.getCompaniesInvoiceServicesExceptSuccess(response.data));
    } else {
      yield put(actions.getCompaniesInvoiceServicesExceptFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getCompaniesInvoiceServicesExceptFail(error));
  }
}
