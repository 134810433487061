import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ocrTransactionsServices from '../../../services/ocr-transactions.services';

export default function* getOcrTransactionsNotMatchSaga() {
  yield takeEvery(types.OCR_TRANSACTIONS.GET_OCR_TRANSACTIONS_NOT_MATCH, getOcrTransactionsNotMatch);
}

export function* getOcrTransactionsNotMatch(action) {
  try {
    const response = yield call(ocrTransactionsServices.listNotMatch, action.payload);
    if (response.success) {
      yield put(actions.getOcrTransactionsNotMatchSuccess(response.data));
    } else {
      yield put(actions.getOcrTransactionsNotMatchFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getOcrTransactionsNotMatchFail(error));
  }
}
