import * as types from '../actionTypes';

export const setFilterCompaniesUsers = payload => ({
  type: types.COMPANIES_USERS.SET_FILTER_COMPANIES_USERS,
  payload
});

export const setUserSelected = payload => ({
  type: types.COMPANIES_USERS.SET_USER_SELECTED,
  payload
});

// LIST
export const getCompaniesUsers = payload => {
  return {
    type: types.COMPANIES_USERS.GET_COMPANIES_USERS_REQUEST,
    payload
  };
};
export const getCompaniesUsersSuccess = payload => ({
  type: types.COMPANIES_USERS.GET_COMPANIES_USERS_SUCCESS,
  payload
});
export const getCompaniesUsersFail = payload => ({
  type: types.COMPANIES_USERS.GET_COMPANIES_USERS_FAIL,
  payload
});

// ORYTON USERS
export const getOrytonUsers = payload => {
  return {
    type: types.COMPANIES_USERS.GET_ORYTON_USERS_REQUEST,
    payload
  };
};
export const getOrytonUsersSuccess = payload => ({
  type: types.COMPANIES_USERS.GET_ORYTON_USERS_SUCCESS,
  payload
});
export const getOrytonUsersFail = payload => ({
  type: types.COMPANIES_USERS.GET_ORYTON_USERS_FAIL,
  payload
});