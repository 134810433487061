import { api } from '../constants/api.constant';
import { APIError } from '../constants/errorCode/system';
import httpHelper from '../helpers/httpHelper';
import BaseServices from './base.services';

export default {
  list: async params => {
    try {
      const queryParams = httpHelper.serialize(params);
      const url = `${api.invoiceNote.list}?${queryParams}`;
      const data = await BaseServices.getDataToken(url, params.companies_id);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  get: async id => {
    try {
      const url = api.invoiceNote.item.replace(':id', id);
      const data = await BaseServices.getDataToken(url);
      const result = await data.json();
      return result;
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  delete: async id => {
    try {
      const url = api.invoiceNote.delete.replace(':id', id);
      const data = await BaseServices.deleteDataToken(url);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  saveNote: async params => {
    try {
      const url = api.invoiceNote.saveNote;
      const data = await BaseServices.postDataTokenFormV2(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
  previewFile: async params => {
    try {
      const url = api.invoiceNote.previewFile;
      const data = await BaseServices.postDataToken(url, params);
      return await data.json();
    } catch (error) {
      return { success: false, code: APIError.ConnectFail };
    }
  },
};
