import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import accountantsServices from '../../../services/accountants.services';

export default function* getAccountCodesSaga() {
  yield takeEvery(types.ACCOUNTANTS.GET_ACCOUNT_CODES, getAccountCodes);
}

export function* getAccountCodes(action) {
  try {
    const response = yield call(accountantsServices.accountCodes, action.payload);
    if (response.name === 'success') {
      yield put(actions.getAccountCodesSuccess(response.data));
    } else {
      yield put(actions.getAccountCodesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getAccountCodesFail(error));
  }
}
