import { call, put, takeEvery } from 'redux-saga/effects';
import companiesServicesServices from '../../../services/companies-services.services';
import * as actions from '../../actions';
import * as types from '../../actionTypes';


export default function* getKidOcrSaga() {
  yield takeEvery(types.COMPANIES_SERVICES.GET_KID_OCR, getKidOcr);
}

export function* getKidOcr(action) {
  try {
    const response = yield call(companiesServicesServices.getKidOcr, action.payload);    
    if (response.success) {
      yield put(actions.getKidOcrSuccess(response.data));
    } else {
      yield put(actions.getKidOcrFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getKidOcrFail(error));
  }
}
