import * as types from '../actionTypes';

const resetFilter = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filter = { ...resetFilter };

const resetFilterDistributionSends = {
  sortBy: 'id',
  sortType: 'desc',
  pageNumber: 1,
  pageSize: 15
};

const filterDistributionSends = { ...resetFilterDistributionSends };

const initialState = {
  loading: false,
  filter,
  filterDistributionSends,
  invoiceDistributions: {
    total: 0,
    rows: []
  },
  invoiceDistribution: null,
  distributionSends: {
    total: 0,
    rows: []
  },
};

const invoiceDistributions = (state = initialState, action) => {
  switch (action.type) {
    case types.INVOICE_DISTRIBUTION.SET_FILTER_INVOICE_DISTRIBUTION:
      return {
        ...state,
        filter: action.payload
      };

    // LIST
    case types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTIONS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDistributions: action.payload
      };
    case types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTIONS_FAIL:
      return {
        ...state,
        loading: false
      };

    // GET
    case types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTION:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        loading: false,
        invoiceDistribution: action.payload
      };
    case types.INVOICE_DISTRIBUTION.GET_INVOICE_DISTRIBUTION_FAIL:
      return {
        ...state,
        loading: false
      };

    //SET FILTER
    case types.INVOICE_DISTRIBUTION.SET_FILTER_DISTRIBUTION_SENDS:
      return {
        ...state,
        filterDistributionSends: action.payload
      };

    //RESET FILTER
    case types.INVOICE_DISTRIBUTION.RESET_FILTER_DISTRIBUTION_SENDS:
      return {
        ...state,
        filterDistributionSends: { ...resetFilterDistributionSends }
      };

    // DISTRIBUTION SEND
    case types.INVOICE_DISTRIBUTION.GET_DISTRIBUTION_SENDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.INVOICE_DISTRIBUTION.GET_DISTRIBUTION_SENDS_SUCCESS:
      return {
        ...state,
        loading: false,
        distributionSends: action.payload
      };
    case types.INVOICE_DISTRIBUTION.GET_DISTRIBUTION_SENDS_FAIL:
      return {
        ...state,
        loading: false
      };

    //DEFAULT
    default:
      return state;
  }
};
export default invoiceDistributions;
