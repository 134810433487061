import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import ReportServices from '../../../services/report.services'

export default function* getInvoiceReportSaga() {
  yield takeEvery(types.REPORT.GET_INVOICE_REPORT_REQUEST, getInvoiceReport);
}

export function* getInvoiceReport(action) {
  try {
    const response = yield call(ReportServices.invoiceReport, action.payload);
    if (response.success) {
      yield put(actions.getInvoiceReportSuccess(response.data));
    }else{
      yield put(actions.getInvoiceReportFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getInvoiceReportFail(error));
  }
}
