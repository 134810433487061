export const IMPORTS = {
  SET_FILTER_CUSTOMER: 'SET_FILTER_CUSTOMER',
  RESET_FILTER_CUSTOMER: 'RESET_FILTER_CUSTOMER',

  SET_FILTER_PRODUCT: 'SET_FILTER_PRODUCT',
  RESET_FILTER_PRODUCT: 'RESET_FILTER_PRODUCT',

  SET_CUSTOMER_DATA_IMPORT: 'SET_CUSTOMER_DATA_IMPORT',
  SET_PRODUCT_DATA_IMPORT: 'SET_PRODUCT_DATA_IMPORT',
};


