import * as types from '../../actionTypes';

export const setFilter = payload => ({
  type: types.CREDIT_CHECK.SET_FILTER,
  payload
});

export const resetFilter = payload => ({
  type: types.CREDIT_CHECK.RESET_FILTER,
  payload
});

// GET
export const creditCheckSearch = payload => {
  return {
    type: types.CREDIT_CHECK.CREDIT_CHECK_SEARCH,
    payload
  };
};
export const creditCheckSearchSuccess = payload => ({
  type: types.CREDIT_CHECK.CREDIT_CHECK_SEARCH_SUCCESS,
  payload
});
export const creditCheckSearchFail = payload => ({
  type: types.CREDIT_CHECK.CREDIT_CHECK_SEARCH_FAIL,
  payload
});

// LIST
export const getCreditChecks = payload => {
  return {
    type: types.CREDIT_CHECK.GET_CREDIT_CHECKS,
    payload
  };
};
export const getCreditChecksSuccess = payload => ({
  type: types.CREDIT_CHECK.GET_CREDIT_CHECKS_SUCCESS,
  payload
});
export const getCreditChecksFail = payload => ({
  type: types.CREDIT_CHECK.GET_CREDIT_CHECKS_FAIL,
  payload
});