import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import invoiceServices from '../../../services/invoices.services';

export default function* createInvoiceRequestSagas() {
  yield takeEvery(types.INVOICES.CREATE_INVOICE_REQUEST, createInvoiceRequest);
}

export function* createInvoiceRequest(action) {
  try {
    const response = yield call(invoiceServices.create, action.payload);
    if (response.success) {
      yield put(actions.createInvoiceRequestSuccess(response));
    } else {
      yield put(actions.createInvoiceRequestFail(response?.message));
    }
  } catch (error) {
    yield put(actions.createInvoiceRequestFail(error));
  }
}