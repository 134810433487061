import * as actions from '../../actions';
import * as types from '../../actionTypes';

import { call, put, takeEvery } from 'redux-saga/effects';

import userInvoiceServicesServices from '../../../services/user-invoice-services.services';

export default function* getUserInvoiceServicesSaga() {
  yield takeEvery(types.USER_INVOICE_SERVICES.GET_USER_INVOICE_SERVICES, getUserInvoiceServices);
}

export function* getUserInvoiceServices(action) {
  try {
    const response = yield call(userInvoiceServicesServices.list, action.payload);
    if (response.success) {
      yield put(actions.getUserInvoiceServicesSuccess(response.data));
    } else {
      yield put(actions.getUserInvoiceServicesFail(response?.message));
    }
  } catch (error) {
    yield put(actions.getUserInvoiceServicesFail(error));
  }
}
